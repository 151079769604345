import React, { memo } from 'react';
import { Container, Image, Titulo, Line1, LogoContainer } from './stylebannerbellavista'; 
import fondo from './bellavistafoto.jpg';
import logo from './BELLAVISTA.png';

const BannerAcademy = () => {
  return (
    <Container>
      <Image src={fondo} alt="imagen fondo" />
      <Titulo>
        <Line1>BELLAVISTA HUELVA CLUB
</Line1>
        <LogoContainer>
          <img src={logo} alt="Logo" style={{ width: '180px', height: '180px' }} />
        </LogoContainer>
      </Titulo>
    </Container>
  );
};

export default memo(BannerAcademy);