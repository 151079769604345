import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto} from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faCalendar} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>EVENTOS PADEL BHC </Titulo>




          <Lista>
            <ElementoLista>  <strong>  -        Torneo Grupo Adarsa-Mercedes Benz:
          </strong>
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>  <strong> - Torneos federados
          </strong>
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>  <strong>  -        Quedadas:   </strong>a lo largo del año hemos realizado numerosas quedadas de padel con oferta gastronómica incluida, donde jugadores aficionados y sus familias han podido disfrutar en un entorno inigualable.

        
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>  <strong>  -        Torneo Madres, Padres e Hij@s
          </strong>
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>  <strong> -        Clínicas
          </strong>
            </ElementoLista>
          </Lista>






        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default Actividades
