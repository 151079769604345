import React, { memo } from 'react'
import { Container, Fondo, Titulo, NSA, LINEA, Line3, Line4, ContainerLogo } from './styles'
import fondo from './1080 red.jpg'
import nsa from './NSA.png'
import linea from './LINEA.png'
import TextCircle from '../../components/TextCircle/Textcircle'

const BannerAcademy = () => {
  return (
    <Container>

      <Fondo src={fondo} alt="imagen fondo" />

      <ContainerLogo>
        <NSA src={nsa} alt="NET SPORT ACADEMY" />
        <LINEA src={linea} alt="ACADEMY" />
        <Line3>¡PASSION FOR THE RACKET!</Line3>
        
      <TextCircle />
      </ContainerLogo>


      <Titulo>
        <Line4>
          Net Sport Academy se destaca en la gestión deportiva con un enfoque
          centrado en la satisfacción del cliente. Especializados en escuelas de
          tenis y pádel en diversas instalaciones, nuestro equipo ofrece un
          servicio completo y de calidad basado en la profesionalidad,
          compromiso y especialización. La metodología simple y clara es clave
          para nuestro éxito.
        </Line4>
      </Titulo>

    </Container>
  )
}

export default memo(BannerAcademy)
