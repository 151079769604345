import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import FxView from '../../../components/FxView/fxview'
import styled, { keyframes } from 'styled-components';
import { Titulo, Descripcion } from '../styletexto';
import GlobalCollageImage from './GlobalCollage.jpg';
import { NavLink } from 'react-router-dom';

// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const ContainerTexto = styled.div`
  max-width: 36%;
  text-align: left;
  margin: 20px 0;
  margin: auto; 

  @media (max-width: 768px) { /* Ajusta el punto de corte según necesites */
  max-width: 86%;
  }
`;


const ContainerGeneral = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  margin: auto;
  max-width: 100%;
  padding: 20px;
  min-height: 800px;
  background: linear-gradient(to right, #B22234 30%, #FFFFFF 30%, #FFFFFF 70%, #3C3B6E 70%);
  background-size: 100%;
  color: #333; /* Ajusta el color del texto según necesites */

  /* Media query para dispositivos móviles */
  @media (max-width: 768px) { /* Ajusta el punto de corte según necesites */
    background: linear-gradient(to right, #B22234 5%, #FFFFFF 5%, #FFFFFF 95%, #3C3B6E 95%);
    padding: 10px;
  }
`;


const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 
`;

const LogoImage = styled.img`
  width: 150px;
  height: auto;
`;


// Nuevo componente de enlace a becas
const BecasEnlaceContainer = styled.div`

  text-align: center;
`;


const BecasEnlaceBoton = styled.a`
    font-family: 'Lumier';
  background-color: #a0f000cf; 
  color: #000000;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom:40px;
  
  &:hover {
    background-color: #000000cf;
    color: #ffffff;
  
  }

  @media (max-width: 768px) { /* Ajusta el punto de corte según necesites */
  font-size: 12px;
  }

`;


const ImagenCentradaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; // Ajusta el margen según necesites

  @media (max-width: 768px) { /* Ajusta el punto de corte según necesites */

  }
`;

const StyledImage = styled.img`
  max-width: 40%;
  height: auto;

  @media (max-width: 768px) {
    min-width: 90%;
  }
`;




const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <ContainerGeneral>
        <LogoContainer>
          <LogoImage src={`${process.env.PUBLIC_URL}/globalcollage.png`} alt="Global Collage Logo" />
        </LogoContainer>
        <ContainerTexto>
          <Titulo>GLOBAL COLLEGE USA</Titulo>
          <Descripcion>
            <strong>NOTA: </strong> Para poder acceder a los acuerdos de colaboración y así poder obtener los beneficios del programa NSTA & GLOBAL COLLEGE póngase en contacto con el Director de <strong> Net Sport Academy  </strong>a través de:
          </Descripcion>
          <Descripcion>

            <li>Telefono de contacto: <strong> (+34) 617 39 71 51</strong></li>
            <li>E-mail: <strong> netsport@netsportacademy.com</strong></li>

          </Descripcion>
        </ContainerTexto>


        <ImagenCentradaContainer>
          <StyledImage src={GlobalCollageImage} alt="Global Collage" />
        </ImagenCentradaContainer>



        <BecasEnlaceContainer>
          <BecasEnlaceBoton as={NavLink} to="/videoedicion">
            Realización de Vídeos Profesionales
          </BecasEnlaceBoton>
        </BecasEnlaceContainer>

        <BecasEnlaceContainer>
          <BecasEnlaceBoton href="https://www.globalcollegeusa.com/es/becas-de-tenis-en-estados-unidos/" target="_blank">
            Información Global College USA
          </BecasEnlaceBoton>
        </BecasEnlaceContainer>


      


      </ContainerGeneral>
      <Footer />
    </div>
  )
}

export default EscuelaDeFormacion;