import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NewsTicker = styled.div`
  z-index: 400!important;
  width: 100%;
  background-color: #a0f000;
  color: black;
  font-size: 15px; // Tamaño inicial para escritorio
  line-height: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  animation: fadeIn 1s ease-out forwards, pulse 1.5s infinite ease-in-out;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    opacity: 1;
    animation: pulseMobile 1.5s infinite ease-in-out;
    font-size: 11px; // Tamaño inicial para móviles
    line-height: 20px;
   
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @keyframes pulse {
    0%, 100% { font-size: 100%; } // Comienza y termina en el tamaño normal
    50% { font-size: 105%; } // Aumenta solo un 5% en su punto más alto
  }

  @keyframes pulseMobile {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}
`;

const NewsLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-family: 'GarnetLight';
  width: calc(100% - 0px); // Reduce el ancho disponible para evitar desbordamientos
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: calc(100% - 10px); // Reduce más el ancho en móviles
  }
`;

const Noticias = () => {
  return (
    <NewsTicker>
      <NewsLink to="/noticias">Últimas Inscripciones Ya Disponibles ¡Aquí!</NewsLink>  
    </NewsTicker>
  );
};

export default Noticias;
