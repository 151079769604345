import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import styled from 'styled-components';
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Descripcion, Titulo, TituloChico, ContainerTexto, Lista, ElementoLista, OpcionesPlus } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';

const BanderaIcono = styled.img`
  width: 30px; 
  height: auto;
  margin-right: 5px; 
  margin-left: 5px; 
`;
const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faChalkboardTeacher} /> {/* Usar el nuevo icono aquí */}

      <ContainerGeneral>
        <ContainerTexto>
          <Titulo>CLASES PARTICULARES</Titulo>
          <Descripcion>
            <strong>Net Sport Tennis Academy (NSTA)</strong> tiene como objetivo iniciar y potenciar a los niños/as y adultos/as de cualquier edad y nivel a través de clases particulares específicas. El programa específico de clases particulares será definido para cada jugador/a, de esta manera los objetivos a alcanzar quedarán marcados desde la primera clase, estas se dividen en varias etapas, y se adaptarán a las habilidades y/o edades del alumno/a.
          </Descripcion>

          <TituloChico><strong>Detalles de las Clases:</strong></TituloChico>
          <Descripcion>
            <Lista>
              <ElementoLista>Entrenador/a del Staff Técnico de NSTA</ElementoLista>
              <ElementoLista>Niveles: Mini Tenis, Iniciación, Intermedio, Perfeccionamiento y Competición</ElementoLista>
              <ElementoLista>Duración: 1h, 1’5h y 2 horas</ElementoLista>
              <ElementoLista>Superficie: Tierra o Green Set</ElementoLista>
              <ElementoLista>Idiomas: 
                <BanderaIcono src="/español.png" alt="Español" />
                <BanderaIcono src="/ingles.png" alt="Inglés" />
                <BanderaIcono src="/italiano.png" alt="Italiano" />
              </ElementoLista>
            </Lista>
          </Descripcion>

          <TituloChico><strong>Opciones Plus:</strong></TituloChico>
          <Descripcion>
            <OpcionesPlus >
              <ElementoLista >Video análisis</ElementoLista >
              <ElementoLista >Sparring</ElementoLista >
              <ElementoLista >Entrenador Sparring y Entrenador/a + Sparring (Competición)</ElementoLista >
            </OpcionesPlus >
          </Descripcion>
        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  )
}

export default EscuelaDeFormacion;
