import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const NombreyBoton = styled.div`
  bottom: 0%;
  left: 0;
  width: 100%;
  background: linear-gradient(145deg, #0f1a20, #16303a);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  /* border-top: 2px solid #2b3e50; // Línea divisoria superior */
`;


export const NombreUsuario = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: inline-flex; // Cambiado de 'block' a 'inline-flex'
  align-items: center; // Asegura que los elementos estén alineados verticalmente
  justify-content: flex-start; // Alinea los elementos al inicio del contenedor
  color: #e5e5e5;
  font-size: 3.8vh;
  font-family: 'RNSMiles';

  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  @media (max-width: 768px) {
    font-size: 3vh;
  }
`;

export const Titulo = styled.h1`

  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  font-size: 4vw;
  z-index: 3;

`;

export const Divider = styled.hr`
  width: 80%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, transparent, #d5d5d5, transparent);

`;


export const UserIcon = styled(FontAwesomeIcon)`
  animation: ${fadeIn} 2s ease-in-out;
  color: #d5d5d5;
  font-size: 5vh;
  margin:10px;
`;




export const LogoutButton = styled.button`
margin-top: 8px;
  animation: ${fadeIn} 2s ease-in-out;
  background-color: rgba(255, 90, 90, 0.342);
  color: #fff;
  border: none;
  padding: 3px 8px;
  font-size: 1.8vh;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 1.2vh;
  }



  
  &:hover {
    background-color: rgba(224, 65, 65, 0.7);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  svg {

  }
  

`;


export const LoaderStyle = styled.div`
  margin-right: 10px; // Ajusta este valor según necesites
`;