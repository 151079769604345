import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto} from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';   

const IntensivoDeNavidad = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faEllipsisH}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo> OTROS INTENSIVOS </Titulo>

      
        


          <Lista>


<ElementoLista> 

Net Sport Tennis Academy realiza a lo largo de la temporada otros intensivos de competición. Para obtener mayor información sobre las fechas y los detalles de cada uno de ellos se recomienda consultar el calendario completo de eventos. 

</ElementoLista>


<ElementoLista><strong> Intensivos que se realizan a lo largo de la temporada: </strong>  <br></br><br></br></ElementoLista>

<ElementoLista><strong> - Intensivo de Semana Santa </strong> </ElementoLista>
<ElementoLista><strong> - Intensivo de Verano</strong>  </ElementoLista>
<ElementoLista><strong> - Intensivo Navidad </strong>  </ElementoLista>
<ElementoLista><strong> - Intensivo Preparatorio  - Fin de Semana</strong> </ElementoLista>

<ElementoLista>¡Espero que encuentres un intensivo que se adapte a tus necesidades y te ayude a alcanzar tus metas en el tenis de competición! </ElementoLista> <br></br>






<ElementoLista> 
Anunciaremos nuestro próximo curso intensivo de tenis (2 meses antes de su inicio). Toda la información detallada sobre el programa, inscripciones, horarios y más será publicada en nuestra página web.
 </ElementoLista>

<ElementoLista> 

<strong> <br></br>¡MANTENTE ATENTO! </strong> </ElementoLista>
       









</Lista>


        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default IntensivoDeNavidad
