import React, { useEffect, useRef, memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  background: linear-gradient(145deg, #0f1a20, #16303a); 
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const Titulo = styled.h1`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  font-size: 6vw;
  line-height: 0.8;
  z-index: 3;


`;
const Line1 = styled.span`
  animation: ${slideInFromLeft} 1.8s cubic-bezier(0.7, -0.5, 0.3, 1.2);
  display: block;
  font-size: 5vw;
  
  @media (max-width: 768px) {
    font-size: 9vw;
  }
`;



const Line4 = styled.span`
  animation: ${fadeIn} 3s ease-in-out;
  display: block;
  color: #f6f6f6;
  font-size: 22px;
  font-family: 'RNSMiles';
  text-align: center;
  margin: 1em 5%;
  margin-bottom: 60px;
  line-height: 1;

  @media (max-width: 580px) {
    font-size: 4vw;
  }
`;


const MensajeExclusivo = styled.div`
  padding: 10px;
  font-size: 15px;
  text-align: center;
  line-height: 1;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;
 

  @media (max-width: 768px) {
    width: 300px;
    font-size: 13px;
  }
`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; 
  background-color: #a0f000 !important; 
  color: #000000 !important; 
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.2em 1em;
  border-radius: 10px; 
  transition: all ease 0.125s;
  font-size: 13px;
  display: block; 
  color: inherit; 
  text-decoration: none; 
  width: auto;

  &:hover {
    background-color: #000000 !important; 
    color: #ffffff !important; 
  }
`;



const BannerAcademy = () => {
  const containerRef = useRef(null);


  
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const currentContainer = containerRef.current; 
    if (currentContainer !== null) {
      currentContainer.appendChild(canvas);
      const ctx = canvas.getContext('2d');
  
      canvas.width = currentContainer.offsetWidth;
      canvas.height = currentContainer.offsetHeight;

    let isMobile = canvas.width < 768; 
    let particles = [];
    let particleCount = isMobile ? 30 : 50;
    let particleMaxVelocity = isMobile ? 0.4 : 0.5; 
    let particleDistance = isMobile ? 0 : 0; 
    let particleColor = '#a0f000';
    let lineWidth = isMobile ? 0.1 : 0.2; 

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.velocity = {
          x: (Math.random() - 0.5) * particleMaxVelocity,
          y: (Math.random() - 0.5) * particleMaxVelocity
        };
        this.radius = isMobile ? Math.random() * 1 + 0.5 : Math.random() * 1.5 + 0.5; // Partículas más pequeñas en móvil
      }




      update() {
        this.x += this.velocity.x;
        this.y += this.velocity.y;

        // Reverse the velocity if the particle hits the edge of the canvas
        if (this.x <= 0 || this.x >= canvas.width) this.velocity.x *= -1;
        if (this.y <= 0 || this.y >= canvas.height) this.velocity.y *= -1;
      }

      draw() {
        ctx.beginPath();
        ctx.fillStyle = particleColor;
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function initParticles() {
      particles = [];
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    }

    function connectParticles() {
      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          let distance = Math.sqrt((particles[i].x - particles[j].x) ** 2 + (particles[i].y - particles[j].y) ** 2);
          if (distance < particleDistance) {
            ctx.strokeStyle = particleColor;
            ctx.lineWidth = lineWidth; // Usa lineWidth ajustado
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.update();
        particle.draw();
      });

      connectParticles();
    }

    initParticles();
    animate();

    return () => {
      // Usamos la variable `currentContainer` en lugar de `containerRef.current`
      if (currentContainer && canvas.parentNode === currentContainer) {
        currentContainer.removeChild(canvas);
      }
    };
  }
}, []);

return (
  <Container ref={containerRef}>
    <Titulo>
      <Line1>NET SPORT EVENTS</Line1>
   
      <Line4>
      ¡Explora la emoción de nuestros eventos! Descubre competiciones de alto nivel en nuestros torneos federados y vibra con la intensidad y diversión de nuestra variada gama de eventos. Mantente informado sobre nuestro activo calendario. ¡Bienvenido al emocionante mundo de Net Sport Events!
      </Line4>

      <MensajeExclusivo style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <p>¿Quieres organizar un evento en tu Club o Instalación? Contacta con nosotros!</p>
      <BotonContacto to="/contacto">Contacto</BotonContacto>
    </MensajeExclusivo>

    </Titulo>
  
  </Container>
);
};

export default memo(BannerAcademy);