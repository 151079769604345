import React from 'react';
import styled from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Descripcion, Titulo, TituloSubrayado, TituloChico, ContainerTexto, TituloGrande } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'
import Carrusel from '../../../components/RioGrande/CarruselEscuelaDeFormacion/carrusel'



const StyledImage = styled.img`
  width: 100%; // Asegura que la imagen sea responsive
  max-width: 900px; // Establece un máximo para evitar que la imagen sea demasiado grande
  margin: 0 auto; // Centra la imagen horizontalmente
  display: block; // Evita que la imagen sea inline
`;



const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
    

      <FxImagenBackGround
        src={`${process.env.PUBLIC_URL}/pista.png`}
        alt="Logo"
        width="70"
      />

      <ContainerGeneral>
        <ContainerTexto>



          <TituloGrande>ESCUELA BASE NSTA</TituloGrande>





          <StyledImage src={`${process.env.PUBLIC_URL}/PIRAMIDE.png`} alt="Pirámide" />





          <Descripcion>
            <strong>La Escuela Base Net Sport Tennis Academy (NSTA) </strong>
            Tiene como objetivo introducir a los niños/as en el mundo del tenis a través de clases divertidas, dinámicas y con una ratio de 4-6 alumnos/as por pista y entrenador/a. El programa específico de clases está definido y expuesto de comienzo a fin de temporada, por lo que el alumno/a puede saber que programa de entrenamiento tiene cada día de clases, así como el objetivo, esto se divide en varias etapas, adaptadas a las habilidades y/o edades de los niños/as.
          

            <Carrusel />

            <br></br><br></br><strong>A continuación, se desglosa un pequeño resumen de las diversas áreas formativas (NSTA:) </strong>
          </Descripcion>




          <TituloSubrayado>
            Mini Tenis <TituloChico>(de 3 a 5 años)</TituloChico>
          </TituloSubrayado>


          <Descripcion>
            <strong>Es nuestra primera etapa, donde se inicia el contacto con el tenis a través de sesiones divertidas y motivantes, utilizando una gran diversidad de material adaptado y propuestas basadas en el juego.  </strong>

          </Descripcion>

          <Descripcion>
            <ul>
              <li>Clases de 1 hora (1 o 2 días a la semana).</li>
              <li>Pista Mini Tenis de Césped Artificial.</li>
              <li>Pelota "Punto Roja".</li>
              <li>Desarrollo de habilidades motrices básicas (lanzar, recibir, golpear, balance estático y dinámico…).</li>
              <li>Trabajo de familiarización con los elementos de juego (pelota, raqueta, espacios).</li>
              <li> Introducción a la técnica básica de golpeo y del juego de pies.</li>
              <li> Consignas fáciles y simples.</li>
              <li> Potenciacion de la creatividad y la imaginación.</li>
              <li> Juegos y actividades para fomentar la diversión y la motivación.</li>
              <li> Incorporar el trabajo de valores a través de juegos.</li>

            </ul>
          </Descripcion>



          <TituloSubrayado>
            Desarrollo 
          </TituloSubrayado>


          <Descripcion>
            <strong>Comprende las etapas formativas de pre-iniciación, iniciación y consolidación. Entrenamos en pistas y materiales adaptados en función de la progresión de los alumnos/as. A lo largo de esta fase los jugadores/as asimilarán y pulirán su técnica de golpeo, a la vez que desarrollan nociones tácticas básicas (dirección de los golpes, posicionamiento en pista, conocimiento de las zonas básicas de la pista) siempre a través de clases motivantes, dinámicas y con un claro enfoque desde los valores inherentes al tenis.
            </strong>

          </Descripcion>


          <Titulo>
            Pre iniciación 

          </Titulo>


          <Descripcion>
            <ul>
              <li>Clases de 1 hora (1 o 2 días a la semana).</li>

              <li>Pelota punto naranja
                .</li>

              <li>Pista grande con adaptaciones
                .</li>
              <li>Mejora de habilidades motrices básicas
                .</li>
              <li>Asimilación de conceptos técnicos
                .</li>
              <li>Ciclo básico de la movilidad (posición de preparados, Split-step, desplazamiento y recuperación)
                .</li>
              <li>Iniciación al peloteo sin la participación del entrenador
                .</li>
              <li> Competición con reglas adaptadas
                .</li>
              <li> Incorporación de habilidades psicológicas básicas (control emocional, actitud positiva, compromiso, concentración).</li>


            </ul>


          </Descripcion>






          <Titulo>
            Iniciación 

          </Titulo>


          <Descripcion>
            <ul>
              <li>Clases de 1 hora (2 o 3 días a la semana).</li>

              <li>Pelota punto naranja y/o verde.</li>

              <li>Pista grande con adaptaciones.</li>
              <li>Mejora de habilidades motrices básicas.</li>


              <li>Desarrollo general de la técnica.</li>


              <li>Introducción a las 5 situaciones de juego (servicio/resto/fondo/fondo-red/red-red).</li>



              <li>Introducción a las zonas de la pista zona roja (control-defensa), amarilla (ataque) y verde (definición).</li>



              <li>Toma de decisiones a través del juego.</li>


              <li>Creatividad en la resolución de problemas.</li>


              <li> Conocimiento de las reglas básicas del tenis.</li>

              <li> Estimulación de habilidades psicológicas básicas (control emocional, actitud positiva, compromiso, concentración).</li>

            </ul>


          </Descripcion>









          <Titulo>
            Consolidación 

          </Titulo>


          <Descripcion>
            <ul>
              <li>Clases de 1 hora o 1,5 horas (2 o 3 días a la semana)</li>

              <li>Pelota punto verde.</li>

              <li>Pista grande.</li>

              <li>Ampliación del repertorio técnico general.</li>

              <li>Introducción del trabajo biomecánico.</li>

              <li>Desarrollo del juego de pies basico.</li>

              <li>Conocimiento y practica de las 5 situaciones de juego (servicio, resto, fondo, fondo-red, red-red)</li>

              <li>Conocimiento de las zonas de la pista zona roja (control-defensa), amarilla (ataque) y verde (definición)</li>

              <li>Introducción a los golpes con efectos.</li>

              <li>Dominio de las reglas básicas del tenis.</li>

              <li>Pautas básicas para competir.</li>

              <li>Desarrollo de habilidades psicológicas básadas (control emocional, actitud positiva, compromiso, concentración).</li>

              <li>Valores como pilares de trabajo.</li>




            </ul>


          </Descripcion>









          <TituloSubrayado>
            ESPECIALIZACIÓN  
          </TituloSubrayado>

          <Descripcion>
            <strong>La fase de especialización es la antesala a la competición, a lo largo de la misma buscamos ampliar y consolidar los componentes técnicos, tácticos, físicos y mentales con el objetivo de lograr una formación solida de base que les permita desarrollar su juego con éxito en sus primeras experiencias competitivas o bien con una intención meramente recreativa para aquellos que así lo deseen.
              <br></br> <br></br>Esta área comprende las etapas de perfeccionamiento y precompetición, las cuales se desglosan de manera sucinta a continuación:   </strong>

          </Descripcion>



          <Titulo>
            Perfeccionamiento 

          </Titulo>



          <Descripcion>
            <ul>
              <li>Clases de 1,5 o 2 horas (3 días a la semana)</li>

              <li>Estabilización del repertorio técnico general.</li>


              <li>Especialización del juego de pies.</li>

              <li>Ampliación del trabajo biomecánico general.</li>

              <li>Ampliación del trabajo táctico en 4 zonas de la pista (defensa, control, ataque y definición)</li>

              <li>Perfeccionamiento de golpes con efecto.</li>

              <li>Dominio de las fases de juego.</li>

              <li>Exploración de patrones de juego simples.</li>

              <li>Introducción a los golpes angulados.</li>

              <li>Consistencia y concentración como pilares de trabajo.</li>

              <li>Entrenamiento mental enfocado a sus primeras competiciones federadas</li>

            </ul>


          </Descripcion>








          <Titulo>
            Precompetición
          </Titulo>



          <Descripcion>
            <ul>
              <li>Clases de 1,5 o 2 horas (3, 4 o 5 días a la semana)</li>

              <li>Dominio del repertorio técnico general.</li>

              <li>Diferenciación del juego de pies en zonas de la pista.</li>

              <li>Especialización del trabajo biomecánico general.</li>

              <li>Asimilación del trabajo táctico en 4 zonas de la pista (defensa, control, ataque y definición)</li>

              <li>Énfasis en la toma de decisiones.</li>

              <li>Aumento progresivo de los golpes con efectos y la combinación de trayectorias.</li>

              <li>Dominio de las fases de juego.</li>

              <li>Elaboración de un patrón de juego adaptado a las necesidades del jugador.</li>

              <li>Introducción a reglamentación de competición.</li>

              <li>Situaciones de partido y trabajo de rutinas.</li>



              <li>Diferenciación en base a las especificidades del tenis femenino con respecto al tenis masculino</li>
              <li>Cargas de trabajo acordes al desarrollo madurativo</li>
              <li>Valores del deportista de competición</li>
              <li>Desarrollo del entrenamiento mental enfocado a competición.</li>





            </ul>


          </Descripcion>





        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDeFormacion
