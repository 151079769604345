import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto, ContainerImagenes, Imagen } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faCoffee} /> {/* Usar el nuevo icono aquí */}



      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>KIOSKO - BAR AIRBUS RACKET
 </Titulo>


          <Lista>
            <ElementoLista>
            El Kiosko-Bar situado junto a las pistas de tenis y padel de nuestro Club, se encuentra abierto de lunes a viernes en horario de tarde y sábados y domingos de 09,30 a 15,00 horas. 
            </ElementoLista>
          </Lista>

         

          <Lista>
            <ElementoLista>
            También informaros que para todos los eventos que se organizan en el Club, el Kiosko-Bar estará abierto a tiempo completo con su carta habitual, además dichos días se abre la barbacoa y arroces en el restaurante Club.

            </ElementoLista>
          </Lista>



          <ContainerImagenes>
            <Imagen src={require('./kioskobar.jpg')} alt="kiosko bar" />
          </ContainerImagenes>






        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default Actividades
