import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import { Link } from 'react-router-dom';
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { Lista, ContainerGeneral, Titulo, ElementoLista, ContainerTexto,  } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faSun } from '@fortawesome/free-solid-svg-icons';

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;


const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre imágenes */
`;

const Imagen = styled.img`
  width: 90%; /* Ajusta este valor según necesites */
  max-width: 900px; /* Ajusta este valor para controlar el máximo ancho de las imágenes */
  height: auto; /* Para mantener la proporción de las imágenes */
  margin: 10px;
`;

const Boton = styled.a`
  font-size: 15px;
  width: 90%;
  font-family: 'Kobern'; /* Fuente para el botón */
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000; // Color inicial
  color: #000000; // Color del texto inicial
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite; // Aplicar la animación aquí

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;








//PDF!!!!!!


const Container = styled.div`
  margin: 0px auto;
  margin-top: 50px;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
   
    border-radius: 5px;
  
  }
`;



const NSAPlusTitulo = styled.h2`
 font-family: 'GarnetLight';

  font-size: 25px; 
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfIframe = styled.iframe`
  width: 100%;
  height: 800px; // Altura predeterminada para dispositivos de escritorio
  border: none;

  @media (max-width: 768px) { // Para pantallas menores de 768px (tablets y móviles)
    height: 600px; // Altura más adecuada para dispositivos móviles
  }

`;


const MensajeExclusivo = styled.div`

 margin: 1px auto;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;
position: relative;
  text-align: center;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;
  margin-top: 0px;


`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000 !important; /* Color de fondo del botón */
  color: #000000 !important; 
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;

  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  transition: all ease 0.125s;
  margin-top: 8px;
  text-align: center;
  display: block; /* Para que ocupe su propio espacio */
  color: inherit; /* Color del texto */
  text-decoration: none; /* Sin subrayado */

  &:hover {
    background-color: #000000 !important; /* Cambiar el color de fondo al pasar el mouse */
    color: #ffffff !important; /* Cambiar el color del texto al pasar el mouse */
  }
`;





const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


const IntensivoDeNavidad = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faSun} /> {/* Usar el nuevo icono aquí */}



      <ContainerGeneral>
        <ContainerTexto>





          <Titulo>INTENSIVO DE VERANO</Titulo>

        



            <Lista>
      

              <ContainerImagenes>
            <Imagen src={require('./cartelintensivo.jpg')} alt="cartelintensivo 1" />
          </ContainerImagenes>




          <ButtonContainer>
       <Boton href="https://docs.google.com/forms/d/e/1FAIpQLSc0d-5mb4JEJBZriG-NrIiuRFoKO6F0VuODxV0KgL3A6SuO-Q/viewform" target="_blank" rel="noopener noreferrer">
             INSCRIPCIÓN
            </Boton>
          </ButtonContainer> 

   </Lista>
    


   <Lista>
            <ElementoLista> Este programa busca equilibrar la mejora técnica, táctica, física y mental, preparando a los jugadores para situaciones de competición intensa. Ajustando la intensidad y calendario de competición según las necesidades del jugador/a.</ElementoLista>
          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./intensivos1.jpg')} alt="Intensivos 1" />
            <MensajeExclusivo>
              <p>Acceso Exclusivo para Socios de la Academia</p>
              <p>¿Interesado en La planificación de Intensivo? Únete a nuestra familia de <br></br>NET SPORT ACADEMY. ¡Contacta con el equipo para resolver cualquier duda!</p>
              <BotonContacto to="/contacto">CONTACTO</BotonContacto>
            </MensajeExclusivo>
        
          </ContainerImagenes>

        




          <Container>
        <ContainerTexto>
          <NSAPlusTitulo>INFORMACIÓN INTENSIVO DE TENIS BELLAVISTA HUELVA CLUB
</NSAPlusTitulo>
        </ContainerTexto>
        {
          isIOS() ? (
            <a href="/PDF/informacionintensivobellavista.pdf" target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </a>
          ) : (
            <PdfIframe src="/PDF/informacionintensivobellavista.pdf" title="Información Intensivo NSTA"></PdfIframe>
          )
        }
      </Container>






        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default IntensivoDeNavidad
