import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 150px;
}
`;


export const Titulo = styled.h1`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  z-index: 3;
  @media (max-width: 768px) {
   
}

`;


export const Line1 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #000000;
  font-size: 35px;
  margin: 1em 3%;
  line-height: 1;
  @media (max-width: 768px) {
    font-size: 27px;
    margin: 1em 3%;
  }
`;
export const Line2 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #323232;
  font-size: 28px;
  font-family: 'RNSMiles';
  text-align: center;
  margin: 1em 3%;
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 17px;
  
  }
`;