import styled, { keyframes } from 'styled-components';

import React from 'react'
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import BannerNSAPlus from '../../components/SociosNSAPlus/BannerNSAPlus/bannernsaplus'
import PerfilNSAPlus from '../../components/SociosNSAPlus/PerfilNSAPlus/perfilnsaplus'
import GeneralNSAPLUS from '../../components/SociosNSAPlus/GeneralNSAPLUS/contenido'
import Footer from '../../components/Footer/footer'


// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const ContainerGeneral = styled.div`

  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;



const NSAPLUS = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />

      <ContainerGeneral>


      <BannerNSAPlus />
      <PerfilNSAPlus />
      <GeneralNSAPLUS />



      </ContainerGeneral>
      <Footer />
      <></>
    </div>
  )
}

export default NSAPLUS
