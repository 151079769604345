import React from 'react';
import Menu from '../../components/Menu/Navbar';
import { ContainerTexto, ContainerGeneral } from '../../views/RioGrande/styletexto';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import LogoRFET from './rfet.png';
import LogoFatenis from './fatenis.png';
import LogoITF from './itftennis.png'; // Asegúrate de tener esta imagen
import LogoTennisEurope from './tenniseurope.jpg'; // Asegúrate de tener esta imagen
import LogoATP from './atptour.png'; // Asegúrate de tener esta imagen
import LogoPadelFederacion from './padelfederacion.jpg'; // Asegúrate de tener esta imagen
import LogoFAP from './fap.png'; // Asegúrate de tener esta imagen
import LogoSNP from './snp.png'; // Asegúrate de tener esta imagen
import LogoPremierPadel from './premierpadel.png'; // Asegúrate de tener esta imagen
import LogoWPT from './wpt.jpg'; // Asegúrate de tener esta imagen

import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const TituloPrincipal = styled.h1`
  height: 150px; // Altura ajustada o puedes usar min-height para más flexibilidad
  display: flex;
  align-items: center; // Centra contenido verticalmente
  justify-content: center; // Centra contenido horizontalmente
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  position: relative; // Esto asegura que el uso de top y left funcione correctamente
  z-index: 3;
  margin-bottom: -30px;

  @media (max-width: 768px) {
    height: 100px;
  }
`;

const Line1 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;

  display: block;
  color: #000000;
  font-size: 32px;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;


const EnlaceLogo = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  text-decoration: none;
`;

const ImagenLogo = styled.img`
  width: 150px; // Tamaño por defecto para móviles
  height: auto;
  margin-top: 50px;

  @media (min-width: 768px) {
    width: 200px; // Tamaño para tabletas y arriba
  }

  @media (min-width: 1024px) {
    width: 300px; // Tamaño para escritorios y arriba
  }
`;

const BotonEnlace = styled.button`
  font-family: 'GarnetLight';
  background-color: #a0f000cf;
  color: #000000;
  border: black solid 1px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 9px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: white;
    background-color: #000000;
    transform: scale(1.05);
  }

`;



// Este componente ahora se basa en BotonEnlace en lugar de styled.span
const EstiloEnlace = styled(BotonEnlace)`
  display: inline-block;
  margin-top: 20px;
  padding: 2px 5px; 
  border-radius: 5px;
  font-weight: bold;
  // Resto de los estilos si necesitas ajustar algo específico además de lo heredado de BotonEnlace
`;





const obtenerDominio = (url) => {
  const dominio = new URL(url).hostname.replace('www.', '');
  return `www.${dominio}`;
};

const logos = [
  { src: LogoRFET, alt: "RFET", href: "https://www.rfet.es/" },
  { src: LogoFatenis, alt: "FATENIS", href: "https://fatenis.com/tenisandalucia/" },
  { src: LogoITF, alt: "ITF", href: "https://www.itftennis.com/en/" },
  { src: LogoTennisEurope, alt: "Tennis Europe", href: "https://www.tenniseurope.org/" },
  { src: LogoATP, alt: "ATP Tour", href: "https://www.atptour.com/es" },
  { src: LogoPadelFederacion, alt: "Federación Padel", href: "https://www.padelfederacion.es/Home" },
  { src: LogoFAP, alt: "FAP", href: "https://www.fap.es/" },
  { src: LogoSNP, alt: "Series Nacionales de Padel", href: "https://www.seriesnacionalesdepadel.com/" },
  { src: LogoPremierPadel, alt: "Premier Padel", href: "https://premierpadel.com/" },
  { src: LogoWPT, alt: "World Padel Tour", href: "https://worldpadeltour.com/" }
];

const Team = () => {
  return (
    <div>
      <Menu />
      <Header />
      
      <ContainerGeneral>

        <TituloPrincipal>
          <Line1>Enlaces de Interés</Line1>
        </TituloPrincipal>

        <ContainerTexto>
          {logos.map(({ src, alt, href }) => (
            <EnlaceLogo key={alt} href={href}>
              <ImagenLogo src={src} alt={alt} />
              <EstiloEnlace>{obtenerDominio(href)}</EstiloEnlace>
            </EnlaceLogo>
          ))}
        </ContainerTexto>
      </ContainerGeneral>
      
      <Logo />
      <Footer />
    </div>
  );
};

export default Team;