import styled, { keyframes } from 'styled-components';


import React from 'react'
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'

import Cards from '../../components/RioGrande/SeleccionRioGrande/cards'
import BannerRioGrande from '../../components/RioGrande/BannerRioGrande/bannerriogrande'
import TextoRioGrande from '../../components/RioGrande/BodyRioGrande/bodyriogrande'
import Carrusel from '../../components/RioGrande/Carrusel/carrusel'
import FooterRioGrande from '../../components/RioGrande/FooterRioGrande/footerriogrande'
import PatrocinadoresRioGrande from '../../components/RioGrande/PatrocinadoresRioGrande/patrocinadores'

import Footer from '../../components/Footer/footer'


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ContainerGeneral = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  // Aplicar la animación de fade-in aquí

  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;


const RioGrande = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />

      <ContainerGeneral>



        <BannerRioGrande />
        <TextoRioGrande />
        <Carrusel />

        
        <Cards />
        <FooterRioGrande />
        <PatrocinadoresRioGrande />



      </ContainerGeneral>

      <Footer />

      <></>
    </div>
  )
}

export default RioGrande
