import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import styles from './stylescontactoform.module.css';

const Contacto = () => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lnol3oz', 'template_b0wjp4i', form.current, 'gSrabvbb9CQdpKCkL')
      .then((result) => {
        console.log(result.text);
        setIsSent(true);
        setTimeout(() => {
          setIsSent(false);
          form.current.reset();
        }, 3000);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <div className={styles['contact-form-container']}>
      <div className={styles['contact-form']}>
        <h2>Mándanos un mensaje</h2>
        <p>Rellena este formulario y nos pondremos en contacto contigo en la mayor brevedad posible.</p>

        <form ref={form} onSubmit={sendEmail}>
          <div className={styles['form-group']}>
            <input type="text" id="nombre" name="user_name" required placeholder="Nombre" />
          </div>
          <div className={styles['form-group']}>
            <input type="email" id="email" name="user_email" required placeholder="Email" />
          </div>
          <div className={styles['form-group']}>
            <textarea id="mensaje" name="message" required placeholder="Mensaje"></textarea>
          </div>
          <button className={styles['buttonformulario']}  type="submit">Enviar</button>
        </form>

        {isSent && (
          <div className={styles['success-message']}>
            <p>¡El mensaje se ha enviado correctamente!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacto;