import React from 'react'
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import Footer from '../../components/Footer/footer'
import BannerVideoEdicion from '../../components/BannerVideoEdicion/bannervideoedicion'
import VideoEdicion from '../../components/VideoBecas/videobecas'



const Team = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />


      <BannerVideoEdicion />
      <VideoEdicion />
    

      <Footer />
      <></>
    </div>
  )
}

export default Team
