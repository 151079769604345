import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { storage } from '../../firebase/firebase.config';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const Container = styled.div`
  max-width: 70%;
  text-align: left;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  animation-fill-mode: forwards;
  margin: 0 auto 0px auto;
  padding: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 50px;


  @media (max-width: 768px) {
    max-width: 94%;
  }
`;


const ImageContainer = styled.div`

padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  right: -5px;
  color: #ff0000;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  user-select: none;

  &:hover {
    background-color: #ff000045;
  }
`;



const DownloadButton = styled.button`
  margin: 15px;
  padding: 5px 13px;
  border: none;
  background-color: #a0f000;
  color: #000000;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
  font-size: 15px;

  &:hover {
    background-color: #c0ff41;
  }

  ${props => props.disabled && css`
    background-color: #ccc;
    cursor: not-allowed;
  `}
`;

const Message = styled.p`
  color: #28a745;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
`;

const UploaderContainer = styled.div`
  max-width: 100%;
  text-align: center;
  margin: 25px auto;
  padding: 10px;
  font-size: 13px;

`;

const TitleInput = styled.input`
  margin: 10px 0;
  padding: 5px;
  width: 80%;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;


const Title = styled.h3`
  font-size: 20px;  /* Tamaño del título en pantallas más grandes */
  margin-bottom: 0px;
  text-align: left;  /* Alineación en pantallas grandes */

  @media (max-width: 768px) {
    font-size: 16px;  /* Tamaño más pequeño para móviles */
    text-align: center;  /* Centrar el título en dispositivos móviles */
  }
`;



const Firestore = () => {
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [titles, setTitles] = useState({});
  const [currentTitle, setCurrentTitle] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchImages();
    checkUserRole();
  }, []);

  const checkUserRole = () => {
    const userRole = localStorage.getItem('rolUsuario');
    if (userRole === 'admin') {
      setIsAdmin(true);
    }
  };

  const sanitizeFileName = (name) => {
    return name.replace(/[/\\?%*:|"<>]/g, '-'); // Reemplaza caracteres inválidos por guiones
  };

  const fetchImages = () => {
    const imagesRef = ref(storage, 'riogrande/');
    listAll(imagesRef)
      .then((res) => {
        const promises = res.items.map((itemRef) =>
          getDownloadURL(itemRef).then((url) => {
            return { url, name: itemRef.name };
          })
        );
        return Promise.all(promises);
      })
      .then((files) => {
        setImages(files);
        const titlesFromStorage = {};
        files.forEach((file) => {
          titlesFromStorage[file.url] = file.name.split('.')[0];
        });
        setTitles(titlesFromStorage);
      })
      .catch((error) => {
        console.log(error);
        setError('Error al cargar imágenes.');
      });
  };

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setFile(newFile);
      setMessage('');
      setError('');
    }
  };

  const handleTitleChange = (event) => {
    setCurrentTitle(event.target.value);
  };

  const uploadFile = () => {
    if (!file) {
      setError('Por favor, seleccione un archivo primero.');
      return;
    }
    if (!currentTitle) {
      setError('Por favor, ingrese un título para el archivo.');
      return;
    }
    setLoading(true);
    const sanitizedTitle = sanitizeFileName(currentTitle);
    const fileName = `${sanitizedTitle}.${file.name.split('.').pop()}`;
    const fileRef = ref(storage, `riogrande/${fileName}`);
    uploadBytes(fileRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((url) => {
        setImages((prevImages) => [...prevImages, { url, name: fileName }]);
        setTitles((prevTitles) => ({ ...prevTitles, [url]: sanitizedTitle }));
        setMessage('Archivo subido con éxito.');
        setCurrentTitle('');
      })
      .catch((error) => {
        setError('Error al subir el archivo.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteImage = (url) => {
    const fileName = url.split('/riogrande%2F')[1].split('?')[0];
    const fileRef = ref(storage, `riogrande/${decodeURIComponent(fileName)}`);
    deleteObject(fileRef)
      .then(() => {
        setImages(images.filter((image) => image.url !== url));
        const updatedTitles = { ...titles };
        delete updatedTitles[url];
        setTitles(updatedTitles);
        setMessage('Imagen eliminada con éxito.');
      })
      .catch((error) => {
        console.error(error);
        setError('Error al eliminar la imagen.');
      });
  };

  return (
    <>
      <Container>
        {images.map(({ url, name }, index) => {
          const extension = url.split('.').pop().split('?')[0].toLowerCase();

          return (
            <ImageContainer key={index}>
              <Title>{titles[url]}</Title>
              {extension.match(/(jpg|jpeg|png|gif)$/i) ? (
                <StyledImage src={url} alt="Uploaded" />
              ) : extension === 'pdf' ? (
                <DownloadButton as="a" href={url} download target="_blank" rel="noopener noreferrer">
                  Descargar PDF
                </DownloadButton>
              ) : extension.match(/(mp4|mov)$/i) ? (
                <video src={url} controls style={{ width: '100%' }}></video>
              ) : (
                <p>Archivo no soportado para visualización</p>
              )}
              {isAdmin && (
                <DeleteButton onClick={() => deleteImage(url)}>
                  <FontAwesomeIcon icon={faTimes} />
                </DeleteButton>
              )}
            </ImageContainer>
          );
        })}
      </Container>

      {isAdmin && (
        <UploaderContainer>
          <input type="file" onChange={handleFileChange} disabled={loading} />
          <TitleInput
            type="text"
            placeholder="Ingrese un título para el archivo"
            value={currentTitle}
            onChange={handleTitleChange}
            disabled={loading}
          />
          <DownloadButton onClick={uploadFile} disabled={loading}>
            {loading ? 'Subiendo...' : 'Subir Archivo'}
          </DownloadButton>
          {message && <Message>{message}</Message>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </UploaderContainer>
      )}
    </>
  );
};

export default Firestore;