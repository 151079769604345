import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0px auto;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 25px;
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  max-width: 50%;
  height: auto;
`;

const DownloadLink = styled.a`
  color: #000000;
  background-color: #a0f000;
  border-color: #007bff;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  margin: 40px;
`;

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const ServiciosAcompañamientos = () => {
  return (
    <div>
      <Container>
        <ContainerTexto>
          <NSAPlusTitulo>SERVICIOS DE COACH A TORNEOS</NSAPlusTitulo>
        </ContainerTexto>
        {
          isIOS() ? (
            <PdfLink href="/PDF/coachriogrande.pdf" target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </PdfLink>
          ) : (
            <iframe
              style={{width: '100%', height: '800px', border: 'none'}}
              src="/PDF/coachriogrande.pdf"
              title="SERVICIOS DE COACH A TORNEOS"
            ></iframe>
          )
        }


        
<ImageContainer>
          <NSAPlusTitulo>AUTORIZACIÓN (menores de edad)</NSAPlusTitulo>
          <Image src="/PDF/AUTORIZACION.jpg" alt="Autorización para menores" />
          <DownloadLink href="/PDF/AUTORIZACION.jpg" download>
            Descargar Autorización
          </DownloadLink>
        </ImageContainer>



      </Container>
    </div>
  );
};

export default ServiciosAcompañamientos;