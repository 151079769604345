import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'




import {} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faChalkboardTeacher,
  faTrophy,
  faFileAlt,
  faSun,
  faEllipsisH,
  faFlag,
  faUserFriends,  
  faCalendar,
  faList,
  faUser,
  faDumbbell,
  faBrain,
  faWrench,
  faMedal,
  faChalkboard,
  faChartBar,
  faPlayCircle,
  faBuilding,


} from '@fortawesome/free-solid-svg-icons'


const ContenedorPrincipal = styled.div`
  margin-bottom: 50px;
`
const TituloCabeza = styled.h3`
  font-family: 'GarnetLight';
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 10%,
    rgb(242, 242, 242) 45%,
    transparent 95%,
    transparent 100%
  );
`
const ContenedorApartado = styled.div`
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px;
`
const Cards = styled.figure`
  background-color: ${(props) => props.color};
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 325px;
  box-sizing: border-box;
  margin: 5px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 10px 10px rgb(173, 173, 173);
  background-color: #a0f000cf;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 45%;
    max-height: 200px;
  }
`
const Imagen = styled.img`
  margin: 0;
  width: 100%;
  height: 200px;
  will-change: transform;

  @media (max-width: 768px) {
    height: 110px;
  }
`
const Capa = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;


  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > svg {
    margin-bottom: 10px;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const Titulo = styled.h3`
  color: #000000;
  font-weight: 400;
  font-family: 'GarnetLight';
  margin-left: 4px;
  margin-right: 4px;

  font-size: 20px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
const LogoImage = styled.img`
  cursor: pointer;
  width: 70px;  
  height: auto;
`;



const TituloYBoton = styled.div`
  display: flex;
  flex-direction: column; /* Los elementos se alinean en columna por defecto */
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
`;

const BotonInscripcion = styled.button`
  font-family: 'GarnetLight';
  margin: 5px; /* Ajusta los márgenes para que se vean bien cuando se apilen */
  background-color: #a0f000cf;
  color: #000000;
  border: black solid 1px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;

  &:hover {
    color: white;
    background-color: #000000; /* Un tono más oscuro para el efecto hover */
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 12px;
    margin: 5px ;
  }
`;

const BotonesContenedor = styled.div`
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */
  flex-wrap: wrap; /* Permite que los botones se envuelvan si no hay suficiente espacio */


  @media (max-width: 768px) {
    flex-direction: row; /* Asegura que los botones estén en horizontal en pantallas pequeñas */
  }
`;


const BotonCalendario = styled(BotonInscripcion)`
  background-color: #c39bd3; // Morado claro
  &:hover {
    background-color: #000000; // Cambia a negro al pasar el ratón por encima
    color: #FFFFFF; // Y el texto a blanco
  }
`;

const Card = () => {
  return (
    <>
      <ContenedorPrincipal>




      <TituloYBoton>
          <TituloCabeza>ESCUELA DE TENIS</TituloCabeza>
          <BotonesContenedor>
          <BotonInscripcion onClick={() => window.location.href='/clubs/riogrande/informacioneinscripcionrg'}>
          Información e inscripción Temporada 24 - 25
    </BotonInscripcion>
            <BotonCalendario as={Link} to="/clubs/riogrande/calendarioriogrande">
            Calendario Temporada 24 - 25
        </BotonCalendario>
          </BotonesContenedor>
        </TituloYBoton>



        <ContenedorApartado>



          <Cards color="#3498db">
            <Link to="/clubs/riogrande/escueladeformación">
              <Imagen />
              <Capa>
              <img src={`${process.env.PUBLIC_URL}/pista.png`} alt="Logo" width="70" />
                <Titulo>escuela de formación</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#3498db">
            <Link to="/clubs/riogrande/escueladeadultos">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faUsers} size="2x" color="white" />
                <Titulo>escuela de adultos</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#3498db">
            <Link to="/clubs/riogrande/clasesparticulares">
              <Imagen />
              <Capa>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  size="2x"
                  color="white"
                />
                <Titulo>clases particulares</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#3498db">
            <Link to="/clubs/riogrande/planificaciondeentrenamiento">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faChalkboard} size="2x" color="white" />
                <Titulo>
                  planifación de entrenamientos NSTA escuela de formación
                </Titulo>
              </Capa>
            </Link>
          </Cards>

        </ContenedorApartado>

        <TituloCabeza>ACADEMIA DE COMPETICIÓN</TituloCabeza>

        <ContenedorApartado>

          <Cards color="#2ecc71">
            <Link to="/clubs/riogrande/academianstadecompeticion">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faMedal} size="2x" color="white" />
                <Titulo>academia nsta competición </Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#3498db">
            <Link to="/clubs/riogrande/planificaciondeentrenamientocompeticion">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faTrophy} size="2x" color="white" />
                <Titulo>planifación de entrenamientos NSTA COMPETICIÓN</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#2ecc71">
            <Link to="/clubs/riogrande/programanstatour">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faFileAlt} size="2x" color="white" />
                <Titulo>programa nsta tour Expediciones a Torneos </Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#2ecc71">
            <Link to="/clubs/riogrande/serviciosdecoachatorneos">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faUserFriends} size="2x" color="white" />
                <Titulo>Servicios de coach a torneos</Titulo>
              </Capa>
            </Link>
          </Cards>


        </ContenedorApartado>
        <TituloCabeza>INTENSIVOS TENIS</TituloCabeza>

        <ContenedorApartado>

          <Cards color="#e74c3c">
            <Link to="/clubs/riogrande/intensivosdeverano">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faSun} size="2x" color="white" />
                <Titulo>intensivo de tenis <br></br>verano </Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards color="#e74c3c">
            <Link to="/clubs/riogrande/otrosintensivos">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faEllipsisH} size="2x" color="white" />
                <Titulo>otros intensivos</Titulo>
              </Capa>
            </Link>
          </Cards>
        </ContenedorApartado>

        <TituloCabeza>EVENTOS</TituloCabeza>
        <ContenedorApartado>

          <Cards color="#f39c12">
            <Link to="/clubs/riogrande/circuitointernonsta">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faFlag} size="2x" color="white" />
                <Titulo>CIRCUITO INTERNO NSTA</Titulo>
              </Capa>
            </Link>
          </Cards>

        </ContenedorApartado>



        <TituloCabeza>OTROS SERVICIOS</TituloCabeza>
        <ContenedorApartado>

          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/calendariodecompeticion">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faCalendar} size="2x" color="white" />
                <Titulo>calendario de competición </Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/planificaciondetodoslosniveles">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faList} size="2x" color="white" />
                <Titulo>
                  planificación de todos los niveles de escuela de formación

                </Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/planificacionindividualizada">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faUser} size="2x" color="white" />
                <Titulo>planificación individualizada</Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/estadisticasyvideoanalisis">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faChartBar} size="2x" color="white" />
                <Titulo>Estadisticas y video analisis </Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/videotutoriales">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faPlayCircle} size="2x" color="white" />
                <Titulo>Video tutoriales NSTA </Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/preparacionfisica">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faDumbbell} size="2x" color="white" />
                <Titulo>Preparación fisica</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/mentalcoach">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faBrain} size="2x" color="white" />
                <Titulo>mental coach</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards color="#9b59b6">
            <Link to="/clubs/riogrande/serviciosdeencordados">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faWrench} size="2x" color="white" />
                <Titulo>servicios de encordados de raquetas</Titulo>
              </Capa>
            </Link>
          </Cards>


          <Cards color="#9b59b6">
            <Link to="/clubs/globalcollage">
              <Imagen />
              <Capa>
                <LogoImage src={`${process.env.PUBLIC_URL}/globalcollage.png`} alt="Global Collage Logo" />
                <Titulo>BECAS USA</Titulo>
              </Capa>
            </Link>
          </Cards>


        </ContenedorApartado>





        <TituloCabeza>CONTACTO E INSTALACIONES CLUB </TituloCabeza>
        <ContenedorApartado>

          <Cards >
            <Link to="/clubs/riogrande/instalaciones">
              <Imagen />
              <Capa>
                <FontAwesomeIcon icon={faBuilding} size="2x" color="white" />
                <Titulo>
                  INSTALACIONES CLUB
                </Titulo>
              </Capa>
            </Link>
          </Cards>



        </ContenedorApartado>






      </ContenedorPrincipal>
    </>
  )
}

export default Card
