import React, { memo } from 'react'
import { Container, Titulo, Line1, Line2 } from './bannernoticiasstyle'



const BannerTeam = () => {
  return (
    <Container>
      <Titulo>
        <Line1>Noticias</Line1>
        <Line2>
        Infórmate sobre las últimas noticias y eventos importantes de nuestra academia. Consulta esta sección para no perderte nada importante.
        </Line2>
      </Titulo>
    </Container>
  )
}

export default memo(BannerTeam)
