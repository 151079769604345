import React, { useContext, createContext, useState, useEffect } from 'react';
import { setDoc, doc } from "firebase/firestore";
import { auth, firestore } from "../firebase/firebase.config";
import {
  sendPasswordResetEmail,
  confirmPasswordReset,
  createUserWithEmailAndPassword, // Crear un nuevo usuario con correo electrónico y contraseña
  signInWithEmailAndPassword, // Iniciar sesión con un usuario existente utilizando correo electrónico y contraseña
  fetchSignInMethodsForEmail, // Obtener los métodos de inicio de sesión disponibles para un correo electrónico dado
  GoogleAuthProvider, // Proveedor de autenticación de Google
  signInWithPopup,
  onAuthStateChanged, // Iniciar sesión mediante un cuadro emergente (popup) de autenticación, comúnmente con proveedores como Google
  signOut, // Cerrar sesión de un usuario autenticado
  // Observar cambios en el estado de autenticación
} from "firebase/auth";

/* Creando un objeto de contexto. */
export const authContext = createContext();






export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) {
    console.log("error creando el contexto de autenticación");
  }
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // Usuario ha iniciado sesión
        setUser(currentUser);
        // Aquí podrías opcionalmente establecer el rol del usuario en localStorage si es necesario
      } else {
        // No hay usuario autenticado
        setUser(null);
        // Limpia el rol y el nombre de usuario del localStorage solo si no estamos autenticados
        localStorage.removeItem('rolUsuario');
        localStorage.removeItem('userName');
      }
    });




    // Limpieza al desmontar
    return () => unsubscribe();
  }, []);


  const register = async (email, password, displayName) => {
    try {
      // 1. Registrar al usuario en Firebase Authentication
      const response = await createUserWithEmailAndPassword(auth, email, password);
      const user = response.user;

      // 2. Guardar el nombre del usuario en Firestore
      await setDoc(doc(firestore, "users", user.uid), {
        displayName,
        email,
      });

      // 3. Loguear al usuario con el nombre actualizado
      setUser(user);

      console.log(response);
    } catch (error) {
      console.error('Error registering:', error.code, error.message);
      throw error; // Re-throw the error to handle it in the calling code
    }
  };
// Función para enviar el correo de restablecimiento de contraseña
const sendResetPasswordEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    // Manejo exitoso: correo enviado
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

// Función para confirmar el restablecimiento de la contraseña
const resetPassword = async (oobCode, newPassword) => {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword);
    // Manejo exitoso: contraseña reseteada
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

  

  const login = async (email, password) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error logging in:', error.code, error.message);
      throw error; // Re-throw the error to handle it in the calling code
    }
  };

  const loginWithGoogle = async () => {
    const responseGoogle = new GoogleAuthProvider();
    return await signInWithPopup(auth, responseGoogle);
  };

  const logout = async () => {
    const response = await signOut(auth);
    console.log(response);
  };

  // Verificar si el correo electrónico ya está registrado
  const isEmailRegistered = async (email) => {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0;
  };

  return (
    <authContext.Provider
      value={{
        register,
        login,
        sendResetPasswordEmail,
        resetPassword,
        loginWithGoogle,
        logout,
        user,
        isEmailRegistered,
      }}
    >
      {children}
    </authContext.Provider>
  );
}