import React from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import FxView from '../../components/FxView/fxview';

const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 60vh; /* Asegura que el contenido ocupa suficiente altura */
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #333;
  margin: 20px 0;
`;

const Description = styled.p`
  font-size: 1.2em;
  color: #666;
  max-width: 600px; /* Asegura que el texto no sea demasiado ancho en pantallas grandes */
`;

const NotFoundPage = () => {
  return (
    <>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <StyledNotFoundPage>
        <Title>404 - Página No Encontrada</Title>
        <Description>Lo sentimos, la página que buscas no existe en nuestra aplicación.</Description>
      </StyledNotFoundPage>

      <Footer />
    </>
  );
};

export default NotFoundPage;
