import styled, { keyframes } from 'styled-components';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  margin-top: 100px;
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 268px; 
  }
`;

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Image = styled.img`

  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%) contrast(110%) saturate(100%);
  animation: ${zoomIn} 4s cubic-bezier(0.42, 0, 0.58, 1) forwards; /* Adjust the duration and timing function as needed */
`;


export const Titulo = styled.h1`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  font-size: 4vw; /* Utilizamos vw en lugar de px */
  z-index: 3;
  white-space: nowrap; 

  @media (max-width: 768px) {
    font-size: 7vw; /* Ajusta el tamaño para dispositivos más pequeños */
  }
`;


export const Line1 = styled.span`
  font-family: 'GarnetLight';
  animation: ${slideInFromLeft} 1.8s cubic-bezier(0.7, -0.5, 0.3, 1.2);
  display: block;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 99%;
    height: 5px;
    background: linear-gradient(to right, transparent, #a0f000); /* Gradient from transparent to solid */
    bottom: -2px;
    right: 0;
  }
`;


export const LogoContainer = styled.div`
  animation: ${fadeIn} 2s ease-in-out forwards;
  opacity: 0; /* Asegúrate de que el logo comience con opacidad 0 y se desvanezca durante la animación */



`;
