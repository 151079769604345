import React from 'react';
import styled from 'styled-components';
import logo from './LoloPineda.png';

const StyledLogo = styled.div`
  z-index: 100;
  border-radius: 8px; 
  
`;

const StyledImage = styled.img`
  width: 90%; 
  height: auto;
  border-radius: 8px; 
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  background-color:  rgba(164, 164, 164, 0.1);  */
  padding: 40px;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(222, 222, 222, 0.5));  */

  filter: brightness(1) contrast(1.2) saturate(0.9); 
`;

const LogoLolo = () => {
  return (
    <StyledLogo>
      <StyledImage src={logo} alt="Lolo Pineda" />
    </StyledLogo>
  );
}

export default LogoLolo;