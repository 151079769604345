import React from 'react';
import Menu from '../../../components/Menu/Navbar';
import Header from '../../../components/Header/header';
import Logo from '../../../components/Logo/logo';
import styled from 'styled-components';
import Footer from '../../../components/Footer/footer';
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview';
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 8px 12px; /* Reduje el padding para hacer el botón más compacto */
  background-color: #a0f000; /* Color amarillo-verde para destacar */
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s; /* Añadí una transición al botón */

  &:hover {
    background-color: #88d638; /* Cambié el color de hover para dar más contraste */
    transform: scale(1.05); /* Añadí un ligero efecto de zoom al hacer hover */
  }
`;

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCalendarAlt} /> {/* Usar el nuevo icono aquí */}

      <ContainerGeneral>
        <ContainerTexto>
          <Titulo>RESERVA DE PISTAS </Titulo> {/* Texto mejorado */}
          <Lista>
            <ElementoLista>
              <strong>Instalaciones:</strong> 5 pistas de pádel (2 de cristal panorámicas, 1 de cristal central, 2 de muro) y 2 pistas de pickleball.
            </ElementoLista>
            <ElementoLista>
              <strong>Reserva:</strong> Puedes reservar pistas de pádel y pickleball utilizando el siguiente enlace.
            </ElementoLista>
          </Lista>

          <Container>
            <StyledLink href="https://playtomic.io/tenant/701453e5-a68b-4e5f-9038-a4137c80e868?utm_source=app_ios&utm_medium=share" target="alquiler de pistas">
              Reservar Pista
            </StyledLink>
          </Container>

          <ElementoLista>
            También puedes reservar llamando al <strong>625 305 902 - Eva Pineda</strong>.
          </ElementoLista>
        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  );
};

export default Actividades;