import React, { useState, useEffect } from 'react';
import styled from 'styled-components'; 

export const GreenLine = styled.div.attrs(props => ({
  style: {
    transform: props.transform,
    opacity: props.$hide ? '0' : '0.8'
  },
}))`
  position: fixed;
  bottom: 410px;
  left: -380px; 
  width: 650px;
  height: 200px;
  background-color: #a0f000;
  transform-origin: bottom left;
  transition: transform 1s ease, opacity 0.9s 0.1s ease;
  z-index: -2;
  will-change: transform, opacity;

  @media (max-width: 768px) {
    bottom: 420px;
    left: -450px; 
  }
`;

export const Linegrey = styled.div.attrs(props => ({
  style: {
    transform: props.transform,
    opacity: props.$hide ? '0' : '0.3'
  },
}))`
  position: fixed;
  top: 510px;
  right: -110px;
  width: 550px;
  height: 500px;
  background-image: linear-gradient(to right, #182329, #2e3d49);
  transform-origin: top right; 
  transition: transform 1s ease, opacity 0.9s 0.1s ease;
  z-index: -1;
  will-change: transform, opacity;

  @media (max-width: 768px) {
    top: 510px; 
    right: -200px;
    transform-origin: top right;
  }
`;




const FxView = () => {
  const [scrollPosition, setScrollPosition] = useState(0);


  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };




  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  const lineTransformY = -scrollPosition; 
  const lineTransform = `rotate(55deg) translateX(${lineTransformY}px)`;
  const greyLineTransform = `rotate(145deg) translateY(${lineTransformY}px)`;
  const hideLine = scrollPosition > 100;
  return (
    <div>
    <GreenLine $hide={hideLine} transform={lineTransform} />
      <Linegrey $hide={hideLine} transform={greyLineTransform} />
    </div>
  );
};

export default FxView;