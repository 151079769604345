import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto} from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';   

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faBirthdayCake}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>CUMPLEAÑOS </Titulo>

     
          <Lista>
            <ElementoLista>

           <strong> En Bellavista Huelva Club</strong>, brindamos servicios de cumpleaños deportivos, consulta a nuestros profesionales y te darán información detallada al respecto.
  </ElementoLista> 
          </Lista>


          <Lista>
            <ElementoLista>

           <strong>Incluye:</strong><br></br> 
           
           - <strong>Deportes:</strong> tenis, padel, squash, pickleball<br></br> 

           - Comida y bebida<br></br> 
           - Juegos <br></br> 
           - Música


  </ElementoLista> 
          </Lista>


        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
