import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';



const Lineas = styled.div`
  position: relative;
  display: flex; // Añadido para hacer el contenedor un flex container
  justify-content: center; // Centra el contenido verticalmente
  align-items: center; // Centra el contenido horizontalmente
  width: 100%;
  height: 155px;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #002b00 0%,
    #004d00 25%, 
    #009900 50%, 
    #004d00 75%, 
    #002b00 100%
  );
  background-size: 500% 100%;
  animation: GradientAnimation 15s linear infinite;

  @keyframes GradientAnimation {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -400% 0;
    }
  }

  @media (max-width: 768px) {
    height: 102px;
  }


`;

const ContainerCentral = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background: linear-gradient(145deg, #0f1a20, #16303a);
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    height: 100px;
  }
`;

 const Titulo = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  font-size: 4vw;
  z-index: 3;

  @media (max-width: 768px) {
    top: 50%;
    font-size: 7vw;
  }
`;


const ContenedorGeneral = styled.div`
`;



const BannerAcademy = () => {
  const containerRef = useRef(null);


  
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const currentContainer = containerRef.current; // Copia la referencia actual a una variable
    if (currentContainer !== null) {
      currentContainer.appendChild(canvas);
      const ctx = canvas.getContext('2d');
  
      canvas.width = currentContainer.offsetWidth;
      canvas.height = currentContainer.offsetHeight;

    let isMobile = canvas.width < 768; // Define si es móvil basado en el ancho del canvas
    let particles = [];
    let particleCount = isMobile ? 30 : 50; // Menos partículas en móvil
    let particleMaxVelocity = isMobile ? 0.4 : 0.5; // Velocidad más baja en móvil
    let particleDistance = isMobile ? 0 : 0; // Conexiones más cortas en móvil
    let particleColor = '#a0f000';
    let lineWidth = isMobile ? 0.1 : 0.2; // Líneas más finas en móvil

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.velocity = {
          x: (Math.random() - 0.5) * particleMaxVelocity,
          y: (Math.random() - 0.5) * particleMaxVelocity
        };
        this.radius = isMobile ? Math.random() * 1 + 0.5 : Math.random() * 1.5 + 0.5; // Partículas más pequeñas en móvil
      }




      update() {
        this.x += this.velocity.x;
        this.y += this.velocity.y;

        // Reverse the velocity if the particle hits the edge of the canvas
        if (this.x <= 0 || this.x >= canvas.width) this.velocity.x *= -1;
        if (this.y <= 0 || this.y >= canvas.height) this.velocity.y *= -1;
      }

      draw() {
        ctx.beginPath();
        ctx.fillStyle = particleColor;
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function initParticles() {
      particles = [];
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    }

    function connectParticles() {
      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          let distance = Math.sqrt((particles[i].x - particles[j].x) ** 2 + (particles[i].y - particles[j].y) ** 2);
          if (distance < particleDistance) {
            ctx.strokeStyle = particleColor;
            ctx.lineWidth = lineWidth; // Usa lineWidth ajustado
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.update();
        particle.draw();
      });

      connectParticles();
    }

    initParticles();
    animate();

    return () => {
      // Usamos la variable `currentContainer` en lugar de `containerRef.current`
      if (currentContainer && canvas.parentNode === currentContainer) {
        currentContainer.removeChild(canvas);
      }
    };
  }
}, []);


  return (
    <ContenedorGeneral>
      <Lineas>
        <ContainerCentral ref={containerRef}>
          <Titulo>SOCIOS NSA PLUS</Titulo>
        </ContainerCentral>
      </Lineas>
    </ContenedorGeneral>
  );
};

export default memo(BannerAcademy);