import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const LogoContainer = styled.header`
  top: ${props => props.$scrolled ? '24px' : '34px'};
  transition: top 0.5s; 
  
  position: fixed;
  z-index: 30;
  pointer-events: none; 
  will-change: top;

  @media (max-width: 1330px) {
    height: auto; 
    left: 50%; 
    transform: translateX(-50%); 
  }

  @media (max-width: 600px) {
   
    left: 40%; 
    transform: translateX(-50%);
  }
`;


const Imagen = styled.img`
  width: ${props => props.$scrolled ? '290px' : '300px'};
  transition: width 0.5s ease; /* Transición suave en el cambio de ancho */

  height: auto;
  filter: saturate(95%) brightness(125%);
  pointer-events: auto;

  &:hover {
    width: ${props => props.$scrolled ? '300px' : '305px'}; 
    transition: width 0.5s ease; 
  }

  @media (max-width: 1330px) {
    width: ${props => props.$scrolled ? '290px' : '300px'};
    transition: width 0.5s ease;

    &:hover {
      width: ${props => props.$scrolled ? '300px' : '305px'};
      transition: width 0.5s ease;
    }
  }
`;




const Logo = () => {
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  return (
    <Link to="/">
      <LogoContainer className="logo" $scrolled={scrolled}>
        <Imagen src="/NSA.png" alt="Net Sport Academy Logo" $scrolled={scrolled}/>
      </LogoContainer>
    </Link>
  );
};

export default Logo;