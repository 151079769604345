import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  top: ${props => props.$scrolled ? '31px' : '41px'};
  z-index: 3000 !important;
  width: ${props => props.$scrolled ? '37px' : '40px'};
  height: ${props =>  props.$scrolled ? '38px' : '40px'}; 
  right:${props =>  props.$scrolled ? '33px' : '33px'} ; 
  position: fixed;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  transition: top 0.5s, width 0.5s, height 0.5s, right 0.5s, transform 0.3s ease;

  &:hover {
    transform: scale(1.1); /* Aumenta el tamaño en un 10% */
    /* Asegúrate de que la transición incluya transform para una experiencia suave */
    transition: top 0.5s, width 0.5s, height 0.5s, right 0.5s, transform 0.3s ease;
  }



  div {
    z-index: 5002;
    width: '37px';
    height: 0.12rem;
    background-color: ${({ open }) => (open ? '#a0f000' : '#ffffff')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    box-shadow: ${({ open }) => (open ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.8)')}; 

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  &:hover {
    div {
      z-index: 2000;
   
    
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  z-index: 80;
  transition: opacity 0.2s ease, visibility 0.1s ease;

  ${({ open }) => open && `
    background: rgba(0, 0, 0, 0.5);
  `}
`;

const Burger = () => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const burgerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const closeMenu = (e) => {
      if (open && burgerRef.current && !burgerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('click', closeMenu);
    }

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [open]);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <StyledBurger ref={burgerRef} open={open} onClick={toggleMenu} $scrolled={scrolled}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Overlay open={open} onClick={toggleMenu} />
      <RightNav open={open} />
    </>
  );
};

export default Burger;
