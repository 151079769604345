import React from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import ContactoInfo from '../../components/ContactoInfo/contactoinfo';
import ContactoForm from '../../components/ContactoForm/contactoform';
import Footer from '../../components/Footer/footer';

// Animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Estilos globales
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0; // Inicia la animación con opacidad 0
`;

const Contacto = () => {
  return (
    <>
      <GlobalStyle />
      <PageLayout>
        <Menu />
        <Header />
        <Logo />
        <Content>
          <ContactoInfo />
          <ContactoForm />
        </Content>
        <Footer />
      </PageLayout>
    </>
  );
}

export default Contacto;
