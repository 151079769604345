import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Descripcion, Titulo, TituloChico, ContainerTexto, Lista,ElementoLista } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'
import Carrusel from '../../../components/RioGrande/CarruselAdultos/carrusel'

import { faUsers } from '@fortawesome/free-solid-svg-icons';   

const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faUsers}/> {/* Usar el nuevo icono aquí */}

      <ContainerGeneral>
      <ContainerTexto>


      <Titulo>ESCUELA DE ADULTOS  </Titulo>

   
        
        <strong> ADULTOS </strong> <TituloChico>(+18 años)</TituloChico>  <br></br>

        <TituloChico><strong>Tenis X Etapas: </strong></TituloChico>

          <Descripcion>
            <ul>
              <li>Introducción al tenis.</li>
              <li>Fundamentos técnicos.</li>
              <li>Desarrollo de habilidades.</li>
              <li>Tácticas de juego.</li>
              <li>Competición.</li>

      
     
            </ul>
          </Descripcion>

          <Carrusel />
        
          <Lista>
            <ElementoLista>- <strong>Opciones de clases:</strong> 1 hora (2 días a la semana) o 1’5 horas (2 días a la semana). No obstante si el alumno/a lo desea se puede plantear un entrenamiento personalizado mas específico.
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>- Superficie de tierra y/o Green Set.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>- Trabajo biomecánico y conceptos generales de la técnica.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>- Estimular el desarrollo de hábitos saludables y desconexión de la vida cotidiana.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>- Desarrollo de patrones de juegos adaptados al jugador.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>- Depurar el juego a nivel global.
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>- Depurar la técnica general.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>- Juego, diversión y psicomotricidad.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>- Eventos internos deportivos y sociales.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>- Competiciones federadas individuales y por equipos.
            </ElementoLista>
          </Lista>














        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDeFormacion
