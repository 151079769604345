import React, { useEffect, useState } from 'react';
import './estiloscircle.css';

const TextCircle = () => {
  const [radius, setRadius] = useState(250);

  const updateRadius = () => {
    const newRadius = Math.min(window.innerWidth, window.innerHeight) / 3;
    setRadius(newRadius);
  };

  useEffect(() => {
    updateRadius();
    window.addEventListener('resize', updateRadius);

    return () => {
      window.removeEventListener('resize', updateRadius);
    };
  }, []);

  const textContent = "NET SPORT ACADEMY-";
  const characters = textContent.split("");

  return (
    <div className="circle" style={{ width: radius * 2, height: radius * 2 }}>
      <div className="logotext"></div>
      <div className="textcircle">
        <p>
          {characters.map((char, i) => (
            <span
              key={i}
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: `translate(-50%, -50%) rotate(${(360 / characters.length) * i - 90}deg) translate(${radius}px) rotate(90deg)`,
              }}
            >
              {char}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};

export default TextCircle;