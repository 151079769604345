import React, { memo } from 'react'
import {
  Container,
  ContainerTexto,
  Titulo,
  Descripcion,
} from './textobellavistastyles'

const BannerAcademy = () => {
  return (
    <Container>
      <ContainerTexto>
        <Titulo>BELLAVISTA HUELVA CLUB
</Titulo>
        <Descripcion>
          ¡Bienvenido a  <strong>Bellavista Huelva Club</strong>, el lugar donde la pasión por el tenis y el pádel se unen en perfecta armonía! Sumérgete en nuestra vibrante comunidad y disfruta de una Escuela de Tenis y Pádel que atiende a todos, desde iniciación hasta competición, con programas adaptados a tus necesidades. Vive la emoción de eventos inolvidables, participa en emocionantes torneos y experimenta la intensidad de la competición en ambas disciplinas. Más allá de las pistas, te ofrecemos servicios personalizados y una experiencia integral para que disfrutes al máximo de tu deporte favorito. Únete a nosotros en Club Bellavista, donde el tenis y el pádel se encuentran para brindarte la mejor experiencia deportiva. ¡Te esperamos con entusiasmo!
        </Descripcion>
      </ContainerTexto>
    </Container>
  )
}

export default memo(BannerAcademy)
