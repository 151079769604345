import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto} from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';   

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faPlayCircle}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>VIDEO TUTORIALES </Titulo>

     
          <Lista>
            <ElementoLista>
¡Gran noticia para nuestros Socios Plus! Estamos emocionados de anunciar que muy pronto tendrán acceso exclusivo a una colección de video tutoriales de alta calidad. Estos recursos están diseñados para sumergir a los estudiantes en un mundo de conocimiento sobre tenis, cubriendo una amplia gama de aspectos técnico-tácticos.  </ElementoLista>  Nuestra biblioteca está pensada para fomentar una mejora continua en jugadores de todos los niveles, ofreciendo una oportunidad única para aquellos apasionados por el tenis que desean destacar y elevar su juego a nuevas alturas. Prepárate para experimentar una transformación en tu desempeño en la cancha con el acceso exclusivo a nuestros recursos para Socios Plus. ¡No pierdas la oportunidad de convertirte en un jugador de tenis más competente y seguro!


           
          </Lista>


        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
