/* eslint-disable no-unused-vars */

import React from 'react'
import styled, { keyframes } from 'styled-components'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, TituloGrande, ContainerTexto } from '../../RioGrande/styletexto'
import FxView from '../../../components/FxView/fxview'





import PDFEscuelaDePadel from '../../../components/Palomares/PDFAcademiaDePadel/academiadepadel'





const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const Imagen = styled.img`
  width: 90%;
  max-width: 900px;
  height: auto;
  margin: 10px;
`

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`

const Boton = styled.a`
  font-size: 15px;
  width: 90%;
  font-family: 'Kobern';
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const EscuelaDePadel = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

  

      <ContainerGeneral>
        <ContainerTexto>
          <TituloGrande>
            Información e inscripción Temporada 24 - 25{' '}
          </TituloGrande>

          <ContainerImagenes>
            <Imagen
              src={require('./Escuela Palomares - Temporada 2024 - 2025.jpg')}
              alt="Escuela Palomares - Temporada 2024 - 2025"
            />
          </ContainerImagenes>

         <ButtonContainer>
            <Boton
              href="https://docs.google.com/forms/d/e/1FAIpQLSdPBQctKzA3CoD_tzelpBWfoSXefcpDPlZyvKLzhujLA8MjPA/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSCRIPCIÓN
            </Boton>
          </ButtonContainer>

          <PDFEscuelaDePadel/>  

        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDePadel





