import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import { Link } from 'react-router-dom';
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { Lista, ContainerGeneral, Titulo, TituloChico, ElementoLista, ContainerTexto } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faSun } from '@fortawesome/free-solid-svg-icons';



const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre imágenes */
`;

const Imagen = styled.img`
  width: 90%; /* Ajusta este valor según necesites */
  max-width: 900px; /* Ajusta este valor para controlar el máximo ancho de las imágenes */
  height: auto; /* Para mantener la proporción de las imágenes */
  margin: 10px;
`;



const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;



const Boton = styled.a`
  font-size: 15px;
  width: 90%;
  font-family: 'Kobern'; /* Fuente para el botón */
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000; // Color inicial
  color: #000000; // Color del texto inicial
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite; // Aplicar la animación aquí

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;


const MensajeExclusivo = styled.div`
 margin: 40px auto;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;
position: relative;
  text-align: center;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;


`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000 !important; /* Color de fondo del botón */
  color: #000000 !important; 
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;
  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  transition: all ease 0.125s;
  margin-top: 8px;
  text-align: center;
  display: block; /* Para que ocupe su propio espacio */
  color: inherit; /* Color del texto */
  text-decoration: none; /* Sin subrayado */

  &:hover {
    background-color: #000000 !important; /* Cambiar el color de fondo al pasar el mouse */
    color: #ffffff !important; /* Cambiar el color del texto al pasar el mouse */
  }
`;



const IntensivoDeNavidad = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faSun} /> {/* Usar el nuevo icono aquí */}



      <ContainerGeneral>
        <ContainerTexto>





          <Titulo>INTENSIVO DE VERANO</Titulo>

       


          <ContainerImagenes>
            <Imagen src={require('./cartelintensivo.jpg')} alt="cartelintensivo 1" />
          </ContainerImagenes>



       
       <ButtonContainer>
       <Boton href="https://docs.google.com/forms/d/e/1FAIpQLScIGyN_gs1i7WJ2euJp3EFogFsECgoomUqPJ1r5OA60sQX7Ng/viewform" target="_blank" rel="noopener noreferrer">
       INSCRIPCIÓN
            </Boton>
          </ButtonContainer> 

        

      



 





  <Lista>
            <ElementoLista> Este programa busca equilibrar la mejora técnica, táctica, física y mental, preparando a los jugadores para situaciones de competición intensa. Ajustando la intensidad y calendario de competición según las necesidades del jugador/a.</ElementoLista>
          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./intensivos1.jpg')} alt="Intensivos 1" />
            <MensajeExclusivo>
              <p>Acceso Exclusivo para Socios de la Academia</p>
              <p>¿Interesado en La planificación de Intensivo? Únete a nuestra familia de <br></br>NET SPORT ACADEMY. ¡Contacta con el equipo para resolver cualquier duda!</p>
              <BotonContacto to="/contacto">CONTACTO</BotonContacto>
            </MensajeExclusivo>
            <Imagen src={require('./intensivos2.jpg')} alt="Intensivos 2" />
          </ContainerImagenes>

        






          <Titulo>INTENSIVO DE VERANO:
            INSCRIPCIÓN INTENSIVO DE TENIS NSTA: CLUB RÍO GRANDE
          </Titulo>


          <Lista>
            <ElementoLista> Querida familia Net Sport Tennis Academy &amp; Río Grande
              Abrimos el plazo de inscripción para el Intensivo de Tenis NSTA/Río Grande 2024
              Entre el 24 de junio y el 2 de agosto. Estamos ilusionados en poder realizar una
              temporada más el programa intensivo de entrenamientos específicos en jugadores/as
              de categorías inferiores (8 a 17 años) y adultos de todas las edades y niveles para que
              sean los grandes protagonistas del programa tenístico NSTA.
              No queremos dejar a nadie sin su plaza y por ello os informamos de que el periodo de
              reservas ya está abierto para todos/as,
            </ElementoLista>
          </Lista>



          <Titulo>FORMAS DE INSCRIPCIÓN Y PAGO
          </Titulo>


          <TituloChico><strong>  1. Solo SOCIOS y ALUMNOS ESCUELA. </strong></TituloChico>



          <Lista>
            <ElementoLista> El socio y alumno/a tiene asegurada su
              plaza inscribiéndose antes del 10 de junio enviando su formulario de inscripción
              relleno al completo que encontrarás en la pestaña Club Río Grande – Intensivos de
              Tenis 2024 a través de nuestra web www.netsportacademy.com . Aquellos
              que deseen el pago en efectivo y así lo pongan en el formulario de inscripción
              podrán realizarlo en la oficina de tenis (junto a la pista 14) los días 18, 19 y 20 de
              junio, de 20,00 a 21,30 horas, en caso de no ser abonado en efectivo en dichos días,
              se hará el cargo con el resto de alumnos/as que han seleccionado el pago por
              domiciliación bancaria (adjuntar en el formulario sus datos de domiciliación
              bancaria).
            </ElementoLista>
          </Lista>

          <TituloChico><strong> 2. SOCIOS y NO SOCIOS. </strong></TituloChico>



          <Lista>
            <ElementoLista> A través de domiciliación bancaria al número de cuenta
              y conceptos abajo indicado. El cargo de la cuota íntegro se realizará en los
              primeros 10 días de julio. En el caso de querer causar baja una vez inscrito deberá
              enviar un mail a intensivos@netsportacademy.com
              con un plazo mínimo de 10 días anterior a la fecha contratada, en caso contrario
              no se garantiza la devolución del pago. </ElementoLista>

            <ElementoLista> Concepto de baja: Nombre y Apellidos del alumno/a y del titular de la cuenta +
              Fecha Contratada + Intensivo de Menores o Intensivo Adultos.
              NOTA IMPORTANTE: Todos los pagos que sean suplementarios o posteriores a las
              fechas contratadas se deberán hacer en efectivo y previa consulta a Lolo Pineda o
              Sergio Campos a través del teléfono 666 186 772 o personalmente.</ElementoLista>

            <ElementoLista>Por ejemplo: imaginemos que hemos inscrito una semana a nuestro hijo/a al Intensivo y
              cuando acaba queremos ampliar una segunda. En primer lugar, habrá que preguntar a
              Lolo Pineda o Sergio Campos si hay disponibilidad y, en segundo lugar, el pago se realizará en efectivo si la remesa ya hubiese sido emitida.
            </ElementoLista>

            <ElementoLista>
              Todos los NO SOCIOS que no se encuentren actualmente inscritos en la escuela de
              tenis, deberán realizar los trámites de control de acceso al Club Río Grande en la
              Recepción del Club, antes de la fecha inicial contratada del Intensivo de Tenis. Siendo el
              horario aconsejable de 08:30h a 18:30h, teniendo que acudir el alumno/a con un
              acompañante si es menor de edad para realizar dichos trámites, o bien el adulto/a de
              manera individual. </ElementoLista>
          </Lista>




          <Titulo>TENEMOS TRES TIPOS DE INTENSIVOS:
          </Titulo>



          <TituloChico><strong>   - INTENSIVO / 24/06 al 02/08 / (Máximo 6 alumnos/as por pista y entrenador)  </strong></TituloChico>


          <Lista>
            <ElementoLista> El programa de entrenamiento que seguirán nuestros alumnos/as con edades entre
              los 8 y 17 años estará compuesto por 2 horas de entrenamiento en pista y 1 hora
              diaria de preparación física u otra actividad deportiva.  </ElementoLista>
            <ElementoLista>
              El horario establecido será de lunes a viernes, de 9:00 a 12:00 horas de la mañana.</ElementoLista>
            <TituloChico><strong>    PRECIOS </strong></TituloChico>
            <ElementoLista>- SEMANA: 85€ </ElementoLista>
            <ElementoLista> - QUINCENA: 160€ </ElementoLista>
            <ElementoLista> - El NO SOCIO tendrá un suplemento de 6€/semana. </ElementoLista>

          </Lista>

          <TituloChico><strong>  - INTENSIVO COMPETICIÓN / 24/07 al 02/08 / (Máximo 4 alumnos/as por
            pista y entrenador)
          </strong></TituloChico>



          <Lista>
            <ElementoLista> El programa de entrenamiento que seguirán nuestros alumnos/as de competición
              está orientado a jugadores/as que compiten con asiduidad y compuesto por 2 horas
              de entrenamiento en pista y 1 hora de preparación física diaria y/o reglamentación,
              táctica de juego, trabajo mental deportivo …) en el turno de mañana, además dos
              días con turnos de tardes (lunes y miércoles), todo ello con una planificación
              específica orientada a la competición. De esta manera, el horario establecido será de
              lunes a viernes, de 09:00 a 12:00h ó 10:00 a 13:00 horas + lunes y miércoles de
              19:00 a 20:30h. </ElementoLista>

            <ElementoLista>- PRECIOS </ElementoLista>
            <ElementoLista>- SEMANA: 125€ </ElementoLista>
            <ElementoLista>- QUINCENA: 240€ </ElementoLista>

            <ElementoLista>- El NO SOCIO Club Rio Grande tendrá un suplemento de 6€/semana.
            </ElementoLista>

          </Lista>




          <TituloChico><strong>  - INTENSIVO ADULTOS / 01/07 al 25-07 / (Máximo 4 alumnos por pista y entrenador)
          </strong></TituloChico>



          <Lista>
            <ElementoLista>Los inscritos adultos entrenan una hora y media, de 20:30 a 22:00 horas, los
              lunes, miércoles y jueves. En su caso, el comienzo del intensivo tendrá lugar el 1 de
              julio y finalizará el 25 de este mismo mes.  </ElementoLista>

              <ElementoLista> PRECIOS </ElementoLista>
              <ElementoLista>- QUINCENA: 80€</ElementoLista>
              <ElementoLista>- 4 SEMANAS: 150€</ElementoLista>

              <ElementoLista> - El NO SOCIO Club Rio Grande tendrá un suplemento de 6€/semana.</ElementoLista>
           
          </Lista>




          <Titulo>PARTICULARES 
          </Titulo>



        
          <TituloChico><strong>   - ¡RESERVA TU CLASE!
          </strong></TituloChico>

          <Lista>
          <ElementoLista> - INDIVIDUAL de 1 hora: 25 € (Socio) / 35 € (No Socio) </ElementoLista>
          <ElementoLista>- 2 JUGADORES de 1 hora: 30 € (Socio) / 40 € (No Socio) </ElementoLista>
          <ElementoLista>- ENTRENADOR SPARRING de 1 hora: 30 € (Socio) / 40 € (No Socio) </ElementoLista>


 
          <TituloChico><strong>  - HORARIO: 
          </strong></TituloChico>
          <ElementoLista> De lunes a viernes de 08:00 a 09:00 horas o a partir de las 19:00
horas de lunes a jueves. Para reservar día/hora y consultar disponibilidad
llamar o enviar WhatsApp al teléfono de NSTA 666 186 772.</ElementoLista>

<ElementoLista>Para cualquier duda pueden contactar al siguiente teléfono de contacto NSTA & RG 666 186 772 para exponer la cuestión pertinente en horario de 10:00 a 14:00 horas.
Finalmente, queremos aprovechar este comunicado para agradeceros la confianza que
cada año demostráis tener en nuestro proyecto para que sigamos creciendo todos
juntos.
</ElementoLista>




</Lista>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default IntensivoDeNavidad
