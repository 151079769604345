import React from 'react'
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import Footer from '../../components/Footer/footer'
import RecuperacionDeContraseña from '../../components/SociosNSAPlus/RecuperacionDeContraseña/recuperaciondecontraseña'



const RecuperacionDeContraseñaComponents = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <RecuperacionDeContraseña/>
      <Footer />
    </div>
  )
}

export default RecuperacionDeContraseñaComponents
