import React, { useEffect, useState, memo } from 'react';
import 'firebase/auth';
import { NombreUsuario, LogoutButton, NombreyBoton, UserIcon, Divider, LoaderStyle} from './perfilnsaplusstyles.jsx';
import { auth, firestore } from '../../../firebase/firebase.config.js';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners'; // Importa el spinner

// import { ClipLoader  } from 'react-spinners'; 

const BannerAcademy = () => {
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate('/sociosnstalogin');
      } else {
        // Optimización: Comprueba si el nombre del usuario ya está almacenado localmente
        const localUserName = localStorage.getItem('userName');
        if (localUserName) {
          setUserName(localUserName);
        } else {
          const userDocRef = doc(firestore, 'users', user.uid);
          const docSnap = await getDoc(userDocRef);

          if (docSnap.exists()) {
            const displayName = docSnap.data().displayName || 'Usuario';
            setUserName(displayName);
            localStorage.setItem('userName', displayName); // Almacenar en localStorage
          } else {
            setUserName('Usuario');
          }
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserName(user.displayName || 'Usuario');
    }
  }, []);


  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userName'); // Limpia el nombre del usuario del almacenamiento local
      localStorage.removeItem('rolUsuario'); // Limpia el rol del usuario del almacenamiento local
      setUserName(''); // Opcional: Restablece el estado del nombre del usuario
      navigate('/sociosnstalogin');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  return (

    <NombreyBoton>
      <UserIcon icon={faUserCircle} />

      <NombreUsuario >    
        
        
      <LoaderStyle>
         <PuffLoader size={30} color="#a0f000" />  
         </LoaderStyle>
         
         
           {userName}



        <LogoutButton onClick={handleLogout}>

          <FontAwesomeIcon icon={faSignOutAlt} />

        </LogoutButton> 
        
        </NombreUsuario>


      <Divider />
    </NombreyBoton>


  );
};

export default memo(BannerAcademy);
