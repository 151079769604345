import styled, { keyframes } from 'styled-components';

// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const ContainerCalendario = styled.div`
margin-top: 120px;


  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`
