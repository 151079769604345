import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import styled from 'styled-components';
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Lista, ElementoLista, Titulo, TituloGrande,TituloSubrayado, TituloChico, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'
import { faFlag } from '@fortawesome/free-solid-svg-icons';



const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre imágenes */
`;

const Imagen = styled.img`
  width: 90%; /* Ajusta este valor según necesites para el ancho de la imagen */
  max-width: 600px; /* Controla el máximo ancho de la imagen */
  height: auto; /* Ajusta el alto automáticamente para mantener la proporción de la imagen */

  margin: 30px; /* Margen alrededor de la imagen */
`;



const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faFlag} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>



          <TituloGrande>EVENTOS TENIS BHC </TituloGrande>



          <TituloChico><strong> Nos apasiona realizar eventos en nuestra academia y el objetivo que perseguimos con los mismos, no es otro que el fomento del tenis en todas sus vertientes </strong></TituloChico>


          <Lista>
            <ElementoLista>- Forjamiento de nuevas amistades entre alumnos de diferentes clases y horarios </ElementoLista>

            <ElementoLista>- Aprender a jugar al tenis, nuestros alumnos/as dan sus primeros pasos en la competición, aprendiendo las reglas del tenis, mejorando el autoconocimiento personal y en la gestión y regulación de sus emociones frente a la resolución de problemas que plantea nuestro deporte. Todo ello, bajo la ayuda de nuestra plantilla de trabajadores para darles las herramientas oportunas en su crecimiento personal y deportivo.</ElementoLista>

            <ElementoLista>- Nos ayuda al programa de detección de talentos.</ElementoLista>

            <ElementoLista>- Fomenta el tejido social del club, nuevas amistades entre familias surgen en cada evento.</ElementoLista>

            <ElementoLista>- Sirve como preparación para las competiciones federadas.</ElementoLista>

          </Lista>


          <TituloSubrayado>1. TORNEOS FEDERADOS:</TituloSubrayado>
          
          <TituloChico><strong> En nuestras instalaciones realizamos un gran número de torneos federados  </strong></TituloChico> <br></br> <br></br>

          <TituloChico><strong>a) CIRCUITO NACIONAL SPARTAN TENIS TOUR: </strong> somos una de las sedes de este prestigioso torneo a nivel nacional, el cual otorga la máxima puntuación para el ranking RFET (coeficiente 2,5 puntos). Tiene lugar durante el mes de agosto.  </TituloChico> <br></br>


          <ContainerImagenes>
            <Imagen src={require('./circuitonacional.jpg')} alt="circuitonacional" />
          </ContainerImagenes>



          <Lista>
            <ElementoLista><strong>b) CAMPEONATO PROVINCIAL ALEVÍN Y CADETE: </strong> durante el mes de marzo </ElementoLista>


          </Lista>


          <Lista>
            <ElementoLista><strong>c) CIRCUITO TENNIS WAREHOUSE EUROPE: </strong>  Somos sede de uno de los circuitos más importantes y de mayor actividad en Andalucía, promovido por Aran Tenis. </ElementoLista>


          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./circuitotenis.jpg')} alt="circuitotenis" />
          </ContainerImagenes>






          <Lista>
            <ElementoLista><strong>d) Circuito Tenis Al Andalus: </strong>   Este es otro de los circuitos más prestigiosos de la actualidad en nuestra comunidad autónoma y somos afortunados de tener una edición de la misma en nuestras instalaciones.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista><strong>e) Torneos federados propios: </strong>  Además somos proactivos organizando nuestros propios torneos federados a lo largo de la temporada, entre los cuales podemos mencionar… Torneo Mundial Angon, Torneo de verano, Torneo de otoño, etc.

            </ElementoLista>
          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./torneosfederados.jpg')} alt="torneosfederados" />
          </ContainerImagenes>





          <TituloSubrayado>2. EVENTOS INTERNOS</TituloSubrayado>
          <TituloChico><strong> LIGA INTERNA: </strong>  En Bellavista Huelva Club organizamos ligas internas de diversos niveles <strong>(1º categoría, 2º categoría y 3º categoría) </strong>con la intención de fomentar el espíritu competitivo y social de nuestros tenistas. Ya llevamos 2 ediciones y cada año aumenta el número de participantes. Hay regalos y premios para todos los participantes.</TituloChico> <br></br> <br></br>






          <ContainerImagenes>
            <Imagen src={require('./ligainterna.jpg')} alt="ligainterna" />
          </ContainerImagenes>




          <Titulo>NSTA KIDS: </Titulo>
          <Lista>
            <ElementoLista>Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y, fundamentalmente, que se enamoren del tenis. Se disputan las siguientes categorías en formato mixto (masculinos y femeninos en conjunto):
            </ElementoLista>
          </Lista>




          <strong> - Sub-6; sub-8; sub-10 y sub-12  </strong>



          <ContainerImagenes>
            <Imagen src={require('./nstakids.jpg')} alt="nstakids" />
          </ContainerImagenes>




          <Titulo> NSTA KIDS BENÉFICO DE NAVIDAD: </Titulo>


          <Lista>
            <ElementoLista> Variante muy especial, que tiene lugar durante las navidades y donde fomentamos valores de beneficencia en nuestros alumn@s, a través de la donación de regalos a los niñ@s más necesitados.
            </ElementoLista>
          </Lista>






          <Titulo>  TORNEO MADRES, PADRES E HIJ@S:</Titulo>

          <Lista>
            <ElementoLista>      Este es uno de nuestros eventos más especiales. Se juega en formato dobles, donde un menor juega en pareja con un mayor de la familia (madre, padre, tío, abuelo, etc.). El objetivo es disfrutar del tenis en familia y fomentar el tejido social del club. </ElementoLista>
          </Lista>



          <ContainerImagenes>
            <Imagen src={require('./torneopadres.jpg')} alt="torneopadres.jpg" />
          </ContainerImagenes>



          <Titulo> DESCUENTOS</Titulo>
          <ContainerImagenes>
  <Imagen src={process.env.PUBLIC_URL + '/descuentos.jpg'} alt="Descuentos" />
</ContainerImagenes>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
