import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  margin: 0px auto;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
   
    border-radius: 5px;
  
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
 font-family: 'GarnetLight';

  font-size: 25px; 
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
`;

// Reutilizamos la función para detectar dispositivos iOS
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const Documentacion = () => {
  return (
    <div>
      <Container>
        <ContainerTexto>
          <NSAPlusTitulo>GUÍA DEL SPONSOR</NSAPlusTitulo>
        </ContainerTexto>
        {
          isIOS() ? (
            // Proporcionamos un enlace directo para descargar el PDF para usuarios de iOS
            <PdfLink href="/PDF/Sponsor.pdf" target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </PdfLink>
          ) : (
            // Para otros dispositivos, seguimos mostrando el PDF en un iframe
            <iframe
              style={{width: '100%', height: '800px', border: 'none'}}
              src="/PDF/Sponsor.pdf"
              title="Guía del Sponsor"
            ></iframe>
          )
        }
      </Container>
    </div>
  );
};

export default Documentacion;