import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import styled from 'styled-components';
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';


const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #a0f000; // Color azul de Instagram
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #007ac1;
    transition: background-color 0.3s;
  }
`;


const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faEllipsisH} /> {/* Usar el nuevo icono aquí */}







      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>ALQUILER DE PISTAS</Titulo>


          <Lista>
            <ElementoLista> 
¡Alquila tu pista ahora! Visita nuestro enlace de alquiler de pistas para asegurar tu espacio de juego. ¡Haz clic y reserva tu momento de diversión! </ElementoLista>
      
          </Lista>



          <Container>
            <StyledLink href="https://playtomic.io/tenant/4f2a8d4e-cd93-4460-916f-3dbf88a9e857
" target="alquiler de pistas">

              ALQUILER DE PISTAS
            </StyledLink>
          </Container>




        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default Actividades
