import styled, { keyframes } from 'styled-components';

// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ContainerGeneral = styled.div`
  margin: 120px auto; 
  max-width: 1000px; 
  padding: 20px;
  min-height: 100vh;



`;


export const ContainerTexto = styled.div`
  text-align: left;
  margin: 20px 0;
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;

export const Titulo = styled.h1`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin: 10px 0;
  margin-top: 50px ;
  text-align: left;
  color: #a0f000;
  font-size: 24px;
`;

export const TituloSubrayado = styled.h1`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin: 10px 0;
  margin-top: 50px ;
  text-align: left;
  color: #a0f000;
  font-size: 29px;
  border-bottom: thick solid;
`;


export const TituloGrande = styled.h1`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin: 10px 0;
  margin-top: 50px ;
  text-align: left;
  color: #a0f000;
  font-size: 35px;
`;


export const Descripcion = styled.div`
  font-family: 'RNSMiles';
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  color: #000000;

  margin-top: 10px;
  margin-bottom: 30px;

`;


export const TituloChico = styled.span`
  font-size: 16px; 

  margin-bottom: 20px;
 
`;


export const EtapaTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 17px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #555;
  text-align: left;
`

export const EtapaDescripcion = styled.p`
  font-family: 'RNSMiles';
  font-size: 18px;
  line-height: 1.5;
  color: #555;
`


//otrosIA


export const Lista = styled.ul`
  list-style-type: none; /* Elimina los puntos de la lista */
  padding-left: 0; /* Elimina el padding izquierdo */
  margin-top: 10px;
`;

export const ElementoLista = styled.li`
  margin-bottom: 5px; /* Espacio entre elementos de la lista */
  ::before {
    content: ''; /* Agrega un punto personalizado antes de cada elemento */
    color: #a0f000; /* Color del punto */
    font-weight: bold; /* Negrita para el punto */
    display: inline-block; /* Asegura que se muestre en línea */
    width: 1em; /* Espacio antes del texto */
    margin-left: -1em; /* Alinea el punto con el texto */
  }
`;

export const OpcionesPlus = styled.div`
  background-color: #f0f0f0; /* Fondo gris claro para destacar la sección */
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  margin-top: 20px; /* Espacio superior */
`;


// Imagenes centradas
export const ImagenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const ImagenResponsiva = styled.img`
  max-width: 100%;
  height: auto;
`;


//ListaNumerada

export const Subtitulo = styled.h3`
  font-family: 'GarnetLight';
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
  text-align: left;
`;

export const ListaNumerada = styled.ol`
  margin-top: 10px;
`;

export const ElementoListaNumerada = styled.li`
  margin-bottom: 5px;
`;


//Styled List

export const StyledList = styled.ol`
  color: #333; // Color del texto
  margin: 20px; // Margen alrededor de la lista
`;

export  const CategoryTitle = styled.strong`
  color: #004d40; // Color del título
  font-size: 1.2em; // Tamaño de la fuente
`;

export const ListItem = styled.li`
  font-size: 1em; // Tamaño de la fuente
  margin-left: 20px; // Sangría para elementos de lista
  &:before {
    content: '•'; // Viñeta personalizada
    color: #009688; // Color de la viñeta
    display: inline-block; 
    width: 1em; // Espaciado antes de la viñeta
    margin-left: -1em; // Alineación de la viñeta
  }
`;

export const SubList = styled.ul`
  margin-left: 20px; // Sangría adicional para sublistas
`;



// IMAGENES



export const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
`;

export const Imagen = styled.img`
  width: 90%; 
  max-width: 600px; 
  height: auto; 

  margin: 30px; 
`;
