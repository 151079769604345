import React from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import Bienvenido from '../../components/SociosNSAPlus/AuthBanner/bienvenido';
import Login from '../../components/SociosNSAPlus/auth/login';

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const SociosNSAPlus = () => {
  return (
    <PageLayout>
      <Content>
        <Menu />
        <Header />
        <Logo />
        <Bienvenido />
        <Login />
      </Content>
      <Footer />
    </PageLayout>
  );
}

export default SociosNSAPlus;
