import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import BannerAcademy from '../../components/BannerAcademy/index';
import Valores from '../../components/Valores/valores';
import Reseñas from '../../components/Reseñas/reseñas';
import Footer from '../../components/Footer/footer';
import NoticiaPrincipal from '../../components/-NoticiaPrincipal/noticias';

// Componente contenedor con estilos condicionales
const StyledContainer = styled.div`
background-color: #000000!Important;

`;

// Estilo para BannerAcademy
const StyledBannerAcademy = styled(BannerAcademy)`
  &&& {
    margin-top: -100px; // Ajusta la posición hacia arriba en 100px
  }
`;


// Hook personalizado para detectar el tamaño de la ventana
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };
    
    window.addEventListener("resize", handleResize);
    handleResize(); // Llama a handleResize inmediatamente para establecer el tamaño inicial

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const Academy = () => {
  const { width } = useWindowSize();

  return (
    <StyledContainer>  
        <NoticiaPrincipal />  
      <Menu />
      {width > 768 && <Header />}
      {width > 768 && <Logo />}
      
      
      

      <StyledBannerAcademy />    
   
      <Valores />  
     
      <Reseñas />
      <Footer />
    </StyledContainer>
  );
};

export default Academy;