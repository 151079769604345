import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const IconBackground = styled.div`
  position: fixed;
  left: 55%;
  top: 20%;
  transform: translateX(-50%);
  opacity: 0.07; // Ajusta la opacidad según necesites
  font-size: 19rem; // Ajusta el tamaño del icono según necesites
  z-index: -1; // Coloca el icono detrás del contenido
 color: #383838;

 @media (max-width: 768px) {
    
  left: 35%;
  top: 30%;
  }

`;

const FxImagenBackGround = ({ icon, src, alt, width }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

    // Decide qué renderizar: ícono o imagen
    const content = src ? (
      <img src={src} alt={alt} style={{ width: width }} />
    ) : (
      <FontAwesomeIcon icon={icon} />
    );

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div>
       <IconBackground style={{ transform: `translateX(${scrollPosition * 0.02}px)` }}>
      {content}
    </IconBackground>
    </div>
  );
};




export default FxImagenBackGround;