import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  margin: 20px auto !important;
  margin-top: 40px !important;
  max-width: 1200px; // Limitar el ancho máximo para escritorio
  padding: 30px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) { // Media query para tablets y móviles
    margin: 20px;
    padding: 15px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;



const NSAPlusContainer = styled.div`
  padding: 15px;
  border-radius: 8px; /* Bordes redondeados */
  margin-top: 30px;
`;

const NSAPlusTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 25px; 
  color: #a0f000; /* Color coherente con el branding de NSA Plus */
  text-align: left;
  margin-bottom: 15px;
`;

const NSAPlusDescripcion = styled.p`
  margin-bottom: 50px;
   font-family: 'RNSMiles';
   font-size: 19px;
  color: #ffffff;
 
`;




// BOTON

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #182329;
    color: #a0f000;
  }
  50% {
    background-color: #a0f0002d;
    color: #000000;
  }
`;

const Boton = styled.a`
  font-size: 13px;
  width: 40%;
  font-family: 'GarnetLight';
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000; 
  color: #000000; 
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 3s infinite; 

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;


const EscuelaDeFormacion = () => {
  return (
    <div>
      <Container>


        <ContainerTexto>

          <NSAPlusContainer>

          <NSAPlusTitulo>SOCIOS NSA PLUS</NSAPlusTitulo>
          <NSAPlusDescripcion>

            Para aquellos alumnos decididos a elevar su juego a nuevas alturas, presentamos Socios <strong>NSA Plus</strong>, la sección de élite de nuestra academia Net Sport Academy. Al sumarse a <strong>NSA Plus</strong>, los estudiantes se adentran en un mundo de conocimiento extenso, obteniendo acceso a una biblioteca completa. Estos recursos cubren desde las técnicas básicas hasta estrategias avanzadas, asegurando una mejora continua en todos los niveles de juego.
          </NSAPlusDescripcion>



            <NSAPlusTitulo>Contenido Exclusivo NSA PLUS</NSAPlusTitulo>
            <NSAPlusDescripcion>
              - <strong>Videos de Iniciación:</strong> Aprende los fundamentos del
              tenis con ejercicios y técnicas básicas.
              <br />
              - <strong>Videos Avanzados:</strong> Perfecciona tu juego con
              estrategias avanzadas y técnicas de alto nivel.
              <br />
              - <strong>Entrenamiento Personalizado:</strong> Videos adaptados
              para mejorar áreas específicas de tu juego.
              <br />
              - <strong>Acceso Ilimitado:</strong> Aprende a tu propio ritmo, en
              cualquier momento y lugar.
              <br />
              - Esta es tu oportunidad de avanzar en el tenis y convertirte en un
              deportista destacado con la ayuda de nuestros expertos.
            </NSAPlusDescripcion>


            <NSAPlusTitulo>Ventajas De NSTA PLUS: </NSAPlusTitulo>

            <NSAPlusDescripcion>
              -       Descuento del 5% en particulares de Tenis, Pádel y Pickleball en todos los Clubs Net Sport Academy.
              <br />
              -       Prioridad en periodo de inscripciones a Escuelas, Intensivos, Campus y Eventos realizados por NSA de todos los Clubs Net Sport Academy.
              <br />
              -       Descuento del 10% en Eventos NSTA

              <br />
              -       Importantes descuentos en todos los sponsors de Net Sport Academy


              <br />
              -       Descuento del 10% en Clínica Metropol salud (Fisioterapia, Podología y Nutrición)

              <br />
              -       Descuento del 10% en Entrenamiento personal en BeSport.
              <br />

              -       Descuento del 10% en el servicio de encordados de raquetas de tenis.

            </NSAPlusDescripcion>




            <ButtonContainer>
            <Boton  target="_blank" rel="noopener noreferrer">
            ¡Pronto estará disponible la suscripción! 
            </Boton>
          </ButtonContainer>



          </NSAPlusContainer>
        </ContainerTexto>



      </Container>
    </div>
  );
};

export default EscuelaDeFormacion;
