import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto} from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faDumbbell } from '@fortawesome/free-solid-svg-icons';   

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faDumbbell}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>PREPARACIÓN FÍSICA </Titulo>

   
        

          <Lista>
            <ElementoLista><strong>Net Sport Tennis Academy</strong> tiene profesionales altamente cualificados en las Ciencias de la Actividad Física y el Deporte. 
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>Aquellos interesados podéis consultar el área de preparación física y sus programas de entrenamiento, orientados a que cada  jugador/a de la academia pueda alcanzar su mejor rendimiento en el tenis.
            </ElementoLista>
          </Lista>




        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
