import React, { memo } from 'react'
import {
  Container,
  ContainerTexto,
  Titulo,
  Descripcion,
} from './bodypalomaresestyles'

const BannerAcademy = () => {
  return (
    <Container>




<ContainerTexto>
  
  <Titulo>Palomares padel club</Titulo>
  <Descripcion>
    <strong>Palomares padel club</strong>, tu punto de encuentro para vivir la pasión del pádel. Desde aprendices hasta expertos, nuestras canchas te esperan para desafíos y diversión. Únete a nuestra comunidad y disfruta de torneos y entrenamientos diseñados para todos los niveles. ¡Explora el pádel en Club Palomares y vive la emoción en cada juego!
  </Descripcion>

</ContainerTexto>







    </Container>
  )
}

export default memo(BannerAcademy)
