import styled, { keyframes } from 'styled-components';

// Crear una animación de fade-in


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0

  @media (max-width: 768px) {
  
    height: 798px; 
    transform: translateY(-120px); // Desplazar el contenedor 100px hacia arriba
  }

  @media (min-width: 2000px) {
    height: auto; // Ajustar la altura automáticamente para resoluciones mayores o
    min-height: 1080px; // Asegurar un mínimo de altura para contenidos más largos
   
  }

`;



export const ContainerLogo = styled.div`
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* opacity: 0.9; */
  transition: transform 0.5s ease; /* Aumentamos la duración de la transición */
  z-index: 2;
  font-family: 'Kobern';


  
  &:hover {
    transform: translate(-50%, -50%) scale(1.03);
  }

  &:not(:hover) {
    transform: translate(-50%, -50%) scale(1); /* Mantenemos el tamaño normal cuando no está en hover */
  }


  @media (max-width: 768px) {
    top: 34%;
    width: 100%;
    margin-top: 50px;
 
  
  }
`;





export const NSA = styled.img`
  width: 100%;
  top: 31%;
  animation: ${slideInFromRight} 3s cubic-bezier(0.7, -0.5, 0.3, 1) forwards;
  opacity: 0;
`;

export const LINEA = styled.img`
  width: 100%;
  top: 44%;
  animation: ${slideInFromLeft} 3.5s cubic-bezier(0.7, -0.5, 0.3, 1) forwards;

`;

export const Titulo = styled.h1`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  line-height: 0.4;
  z-index: 3;


  @media (max-width: 768px) {
    top: 62.2%;
  }
`;



export const Line3 = styled.span`
  animation: ${fadeIn} 3s ease-in-out;
  display: block;
  color: rgb(224, 224, 224);
  font-size: 2.2vw;
  line-height: 1;
  margin-top: -3px;

  @media (max-width: 768px) {
    font-size: 4.6vw;
    margin-top: 2px;
  }

  &:hover {
    transform:  scale(1.02);
   
  }
  transition: transform 0.5s ease; /* Aumentamos la duración de la transición */

`;

export const Line4 = styled.span`
  animation: ${fadeIn} 3s ease-in-out;
  display: block;
  color: rgb(224, 224, 224);
  font-size: 26px;
  font-family: 'RNSMiles';
  margin: auto;
  text-align: center !;
  left: 50%;
  line-height: 1.2;
  margin-top: 20px;
  max-width: 1200px;

  &:hover {
    transform:  scale(1.005);
  }
  transition: transform 0.5s ease; /* Aumentamos la duración de la transición */


  @media (max-width: 768px) {
   margin-top: 320px;
    font-size: 18px;
    max-width: 90%;

  
 
  }
`;


export const Fondo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%) contrast(105%);
`;
