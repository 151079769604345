import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto, TituloChico } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faBrain } from '@fortawesome/free-solid-svg-icons';

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faBrain} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>MENTAL COACH & NET SPORT ACADEMY    </Titulo>

          <Lista>
            <ElementoLista>Nos gustaría exponer sobre la importancia del programa <strong>Mental Coach & NSTA</strong>, específicamente en el contexto de la competición en jugadores. El tenis es un deporte conocido por su complejidad tanto a nivel técnico como estratégico, pero a menudo se pasa por alto el papel fundamental que juega la mente en el rendimiento de un jugador. A lo largo de esta exposición, exploraremos cómo la psicología puede marcar la diferencia en el desempeño de los jugadores y cómo pueden beneficiarse de su aplicación.
            </ElementoLista>
          </Lista>

          <TituloChico><strong> Desarrollo:  </strong></TituloChico> <br></br>

          <TituloChico><strong> 1. La importancia del mental coach:  </strong></TituloChico>



          <Lista>
            <ElementoLista>El tenis es un deporte altamente mental y emocionalmente desafiante. Los jugadores deben lidiar con la presión, el estrés, la ansiedad y la frustración, además de mantener la concentración y la motivación a lo largo de un partido. El programa de <strong>Mental Coach & NSTA</strong> se centra en fortalecer la mentalidad de los jugadores, brindándoles las herramientas necesarias para hacer frente a estos desafíos y maximizar su desempeño.

            </ElementoLista>
          </Lista>


          <TituloChico><strong> 2. Cómo el programa de Mental Coach & NSTA puede ayudar a los jugadores menores:  </strong></TituloChico>

          <Lista>
            <ElementoLista>Los jugadores menores son especialmente vulnerables a las presiones y desafíos mentales del tenis. Muchos están en pleno desarrollo emocional y a menudo se ven afectados por la falta de experiencia y confianza en sí mismos. Aquí es donde nuestro programa puede ser de gran ayuda. Algunas formas en que puede beneficiar a los jugadores menores son:


            </ElementoLista>
          </Lista>







          <Lista>
            <ul>
              <ElementoLista> Desarrollo de habilidades de manejo del estrés: A través de técnicas como la respiración profunda, la visualización y el establecimiento de rutinas, los jugadores pueden aprender a controlar su nivel de estrés y mantener la calma bajo presión.</ElementoLista>
              <ElementoLista> Construcción de confianza: El mental coach ayuda a los jugadores a desarrollar una mentalidad positiva y una actitud confiada hacia su juego. Esto les permite enfrentarse a desafíos con determinación y creer en su capacidad para tener éxito.
              </ElementoLista>
              <ElementoLista> Mejora de la concentración: La concentración es clave en el tenis, y el mental coach  puede ayudar a los jugadores a entrenar su mente para mantenerse enfocados durante un partido, bloqueando las distracciones y manteniendo la atención en el momento presente.
              </ElementoLista>
              <ElementoLista> Manejo de la derrota: El tenis es un deporte que implica muchas derrotas, y aprender a manejarlas de manera saludable y constructiva es fundamental para el crecimiento y desarrollo de los jugadores menores. El programa <strong>Mental Coach & NSTA</strong>  brinda herramientas para aceptar las derrotas, aprender de ellas y mantener una mentalidad positiva, todo ello para construir en futuras situaciones del juego similares las herramientas adecuadas para cosechar victorias.
              </ElementoLista>
            </ul>
          </Lista>









          <TituloChico><strong> Conclusión:  </strong></TituloChico> <br></br>









          <Lista>
            <ElementoLista>En resumen, el programa <strong>Mental Coach & NSTA</strong>  desempeña un papel fundamental en el rendimiento de los jugadores menores. Proporciona las herramientas necesarias para ayudar a los jugadores a desarrollar habilidades de manejo del estrés, construir confianza, mejorar la concentración, rutinas  y manejar la derrota de manera saludable para trabajar en futuras situaciones similares. Al integrar al mental coach en la preparación y entrenamiento de los jugadores menores, podemos ayudarlos a superar los desafíos mentales y emocionales del tenis, maximizando así su potencial y llevándolos a alcanzar los objetivos marcados.
            </ElementoLista>
          </Lista>

















        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
