import React from 'react';
import styled from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faBuilding } from '@fortawesome/free-solid-svg-icons';


const ContainerCirculos = styled.div`
max-width: 900px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const Circulo = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 4%;
  overflow: hidden;
  margin: 10px;


`;

const ImagenCirculo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faBuilding} /> 
      <ContainerGeneral>
<ContainerTexto>

<Titulo>Descubre Nuestras Instalaciones</Titulo>

<Lista>
  <ElementoLista>
    Nuestro club se distingue por sus completas instalaciones, diseñadas para brindar una experiencia única a nuestros socios y visitantes.
  </ElementoLista>
  <ElementoLista>
    Disfruta de nuestras modernas pistas de tenis, perfectas para practicar tu deporte favorito en un entorno excepcional.
  </ElementoLista>

</Lista>



          <ContainerCirculos>

            <Circulo>
              <ImagenCirculo src={require('./bar.jpg')} alt="Instalación 1" />
            </Circulo>
            <Circulo>
              <ImagenCirculo src={require('./instalaciones2.jpg')} alt="Instalación 2" />
            </Circulo>
            <Circulo>
              <ImagenCirculo src={require('./futbol.jpg')} alt="Instalación 3" />
            </Circulo>
        
            <Circulo>
              <ImagenCirculo src={require('./gym.jpg')} alt="Instalación 1" />
            </Circulo>
            <Circulo>
              <ImagenCirculo src={require('./tenis.jpg')} alt="Instalación 2" />
            </Circulo>

            <Circulo>
              <ImagenCirculo src={require('./pistaazul.jpg')} alt="Instalación 3" />
            </Circulo>

            <Circulo>
              <ImagenCirculo src={require('./padel.jpg')} alt="Instalación 4" />
            </Circulo>

            <Circulo>
              <ImagenCirculo src={require('./petanca.jpg')} alt="Instalación 4" />
            </Circulo>

          </ContainerCirculos>


        </ContainerTexto>
      </ContainerGeneral>



      <Footer />
    </div>
  )
}

export default Actividades;