const theme = {
  colors: {
    primary: '#a0f000',              /* Color primario */
    forestGreen1: '#003300',         /* Verde bosque 1 */
    forestGreen2: '#001a00',         /* Verde bosque 2 */
    darkGreen: '#689300',            /* Verde oscuro */
    darkerGreen: '#407500',          /* Verde más oscuro */
    mossGreen1: '#6e7421',           /* Verde musgo 1 */
    mossGreen2: '#4d5218',           /* Verde musgo 2 */
    oliveGreen1: '#6b6e00',          /* Verde oliva 1 */
    oliveGreen2: '#494d00',          /* Verde oliva 2 */
    lightGreen1: '#c0ff3e',          /* Verde claro 1 */
    lightGreen2: '#d4ff80',          /* Verde claro 2 */
    darkOliveGreen1: '#4b4d00',      /* Tonalidad más oscura de verde oliva */
    darkOliveGreen2: '#3d3f00',      /* Otra tonalidad más oscura de verde oliva */
    deepGreen1: '#355e00',           /* Verde profundo 1 */
    deepGreen2: '#2a4c00',           /* Verde profundo 2 */
    pineGreen1: '#214a00',           /* Verde pino 1 */
    pineGreen2: '#183800',           /* Verde pino 2 */
    hunterGreen1: '#1a3b00',         /* Verde cazador 1 */
    hunterGreen2: '#133000',         /* Verde cazador 2 */
    bottleGreen1: '#163300',         /* Verde botella 1 */
    bottleGreen2: '#0f2400',         /* Verde botella 2 */
    limeGreen1: '#bfff00',           /* Verde lima 1 */
    limeGreen2: '#a6cc00',           /* Verde lima 2 */
    grassGreen1: '#4dff4d',          /* Verde césped 1 */
    grassGreen2: '#33cc33',          /* Verde césped 2 */
    springGreen1: '#00ff80',         /* Verde primavera 1 */
    springGreen2: '#00cc66',         /* Verde primavera 2 */
    tealGreen1: '#00cc99',           /* Verde azulado 1 */
    tealGreen2: '#007f5e',           /* Verde azulado 2 */
    mintGreen1: '#98ff98',           /* Verde menta 1 */
    mintGreen2: '#74c365',           /* Verde menta 2 */

    // Primary Colors
    blue25: '#F0F7FC',
    blue75: '#C2E0FC',
    blue125: '#94CAFA',
    blue175: '#66B4F9',
    blue225: '#388FF7',
    blue275: '#0A79F5',
    blue50: '#E3F2FD',
    blue200: '#64B5F6',
    blue400: '#1E88E5',
    blue600: '#1976D2',
    blue800: '#1565C0',
    blue100: '#83b0e7',
    blue300: '#5393de',
    blue500: '#5393DE',
    blue700: '#2F73D8',
    blue900: '#2C3E50',
    orang: '#ff4500',
    red300: '#EF5350',
    green100: '#B8E986',
    green500: '#2CB532',
    yellow200: '#FFD54F',
    purple400: '#9C27B0',
    orange300: '#FB8C00',

    // Secondary Colors
    pink200: '#F06292',
    teal300: '#4DB6AC',
    indigo500: '#3F51B5',
    amber600: '#FFB300',

    // Neutral Colors
    white: '#FFFFFF',
    black: '#000000',
    grey100: '#FAFAFA',
    grey200: '#E1E1E5',
    grey300: '#BCC6CC',
    grey400: '#A3A9B5',
    grey500: '#819098',
    grey600: '#757575',
    grey700: '#9E9E9E',
    grey800: '#424242',
    grey900: '#6E7A76',

    // Transparent Colors (RGBA)
    transparentWhite: 'rgba(255, 255, 255, 0.9)',
    transparentBlack: 'rgba(0, 0, 0, 0.6)',
    transparentBlue: 'rgba(83, 147, 222, 0.8)',
    transparentGrey: 'rgba(129, 144, 152, 0.9)',
    transparentRed: 'rgba(239, 83, 80, 0.8)',
    transparentGreen: 'rgba(44, 181, 50, 0.8)',
    transparentYellow: 'rgba(255, 213, 79, 0.8)',
    transparentPurple: 'rgba(156, 39, 176, 0.8)',
    transparentOrange: 'rgba(251, 140, 0, 0.8)',
  },
}

export default theme
