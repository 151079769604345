import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;

  @media (max-width: 768px) {
    /* height: 300px; */
  }
`;


export const Titulo = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  line-height: 0.8;
  z-index: 3;

  @media (max-width: 768px) {
   top: 50%;
   /* margin-top: 20px; */
  }
`;


export const Line1 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #000000;
  font-size: 32px;

  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 21px;
  }
`;


export const Line2 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #323232; 
  font-family: 'RNSMiles';
  font-size: 1.50rem; 
  line-height: 1.5;
  /* margin: 1em 1%; */
  line-height: 1;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 1.25rem; 
    margin: 1em 3%;
 
   
  }
`;



export const Puntos = styled.h1`
  font-size: 1.50rem; 
  line-height: 1.4;
  background-color: #ececec;
  padding: 20px;

  @media (max-width: 768px) {
    font-size: 1.25rem; 
  }
`;






