import React, { memo } from 'react';
import { faPhone, faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'; // Corregido la importación del icono de correo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Asumiendo que este es el conjunto correcto para faWhatsapp
import styled, { keyframes } from 'styled-components';

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); // Aumenta el tamaño del icono en un 10%
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 30px;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -30px 190px 60px 190px;
  padding: 20px;
  background-color: #f0f0f0; /* Fondo sólido más suave */
  border: 2px solid #e0e0e0; /* Borde sutil */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 1.3em;
  @media (max-width: 768px) {
    font-size: 1.1em;
  }

  a {
    color: #1e1e1e;
    text-decoration: none;
    &:hover {
      color: #1b1b1b;
      text-decoration: underline;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const WhatsappIcon = styled(FontAwesomeIcon)`
  color: #25D366;
  font-size: 2em;
  animation: ${pulsate} 1.5s infinite ease-in-out;
`;

const MapContainer = styled.div`
  width: 80%;
  height: 30vh;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 90%;
    height: 50vh;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;



const FooterRioGrande = () => {


  return (
    <Container>
      <ContactContainer>
        
        <ContactInfo>
          <span><strong>Escuela Net Sport Academy Palomares padel club </strong><br /> <br></br>
          
          
          <Icon icon={faPhone} />Eva Pineda (+34) 613 334 157</span>   <br></br>
        </ContactInfo>


        <ContactInfo>
          <Icon icon={faEnvelope} />
          <a href="mailto:Palomarespadel@gmail.com">Palomarespadel@gmail.com</a>
        </ContactInfo>

        <ContactInfo>
          <span>
            <a href="https://www.instagram.com/palomarespadelclub/" target="_blank" rel="noopener noreferrer">

              <Icon icon={faExternalLinkAlt} />


              palomarespadelclub

            </a>
          </span>
        </ContactInfo>

  
        <ContactInfo>
          <a href="https://wa.me/34613334157" target="_blank" rel="noopener noreferrer">
            <WhatsappIcon icon={faWhatsapp} />
          </a>
        </ContactInfo>




      </ContactContainer>


      <MapContainer>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d892.4955244731382!2d-6.07402659404936!3d37.31865840058751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1ses!2ses!4v1708848204027!5m2!1ses!2ses"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
          height="100%"
          title="Ubicación de la Escuela Net Sport Academy Palomares en Google Maps" >
        </iframe>
      </MapContainer>


    </Container>
  );
};

export default memo(FooterRioGrande);
