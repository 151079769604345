import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Lista, Titulo, TituloChico, ContainerTexto, ElementoLista } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendar } from '@fortawesome/free-solid-svg-icons';


import Datos from '../../../components/-FireStoreBackend/CalendarioBallavista';



const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faCalendar} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>



          <Titulo> CALENDARIO DE COMPETICIÓN:</Titulo>


          <TituloChico><strong> Diseñamos calendarios de competiciones generales y específicas.  </strong></TituloChico>


        


          <Lista>
            <ElementoLista>Los calendarios generales hacen referencia a la serie de torneos federados de la provincia de Sevilla, como así también torneos relevantes a nivel autonómico. Por otro lado, también diseñamos un calendario de torneos de mayor relevancia a nivel nacional e internacional.
            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista>      Los calendarios específicos, son calendarios hechos a medida según las necesidades de un jugador/a en particular.
            </ElementoLista>
          </Lista>

    <Datos/>
      


        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
