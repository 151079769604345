import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { auth } from '../../../firebase/firebase.config';
import { confirmPasswordReset } from 'firebase/auth';

const RecuperacionDeContraseña = () => {
  const [newPassword, setNewPassword] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      setOobCode(code);
    }
  }, [searchParams]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

   // Validación de la contraseña
  if (!isValidPassword(newPassword)) {
    setErrorMessage('La contraseña debe tener mínimo 6 caracteres con letras y números.');
    setIsErrorModalVisible(true);
    return;
  }

  confirmPasswordReset(auth, oobCode, newPassword)
    .then(() => {
      navigate('/sociosnstalogin');
    })
    .catch((error) => {
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      const errorMessage = getFirebaseErrorMessage(error.code);
      setErrorMessage(errorMessage);
      setIsErrorModalVisible(true);
    });
};

// Función para validar la contraseña
const isValidPassword = (password) => {
  return password.length >= 6 && /[a-zA-Z]/.test(password) && /[0-9]/.test(password);
};

  const getFirebaseErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/weak-password':
        return 'La contraseña es demasiado débil. Necesita mínimo 6 caracteres con letras y números.';
      case 'auth/user-not-found':
        return 'No se encontró una cuenta con este correo electrónico.';
      case 'auth/invalid-action-code':
        return 'El enlace para restablecer la contraseña ha expirado o ya ha sido utilizado.';
      // Agrega más casos según sea necesario
      default:
        return 'Ocurrió un error desconocido. Por favor, intenta de nuevo.';
    }
  };


  const ErrorModal = ({ message, onClose }) => (
    <div style={{ position: 'absolute',width: '80%' , maxWidth:'480px'  , top: '40%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.5)' }}>
      <div style={{ marginBottom: '40px',  }}>{message}</div>
      <button onClick={onClose}>Cerrar</button>
    </div>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="cardlogin" style={{ maxWidth: '480px', width: '80%' }}>
        <div className="contentlogin">
          <h2 className="titlelogin">Recuperación de Contraseña</h2>
          <form onSubmit={handleSubmit} className="reset-password-form">
            <div className="field">
              <label htmlFor="newPassword" style={{ marginBottom: '5px' }}>La nueva contraseña (Necesita mínimo 6 caracteres con letras y números)</label>
              <input
                id="newPassword"
                type="password"
                placeholder="Nueva contraseña"
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
            </div>
            <div className="submitlogin">
              <button type="submit" className="buttonsubmitlogin">Cambiar Contraseña</button>
            </div>
          </form>
        </div>
      </div>
      {isErrorModalVisible && 
        <ErrorModal 
          message={errorMessage} 
          onClose={() => setIsErrorModalVisible(false)} 
        />
      }
    </div>
  );
};

export default RecuperacionDeContraseña;