import React, { memo } from 'react'
import { Container, Titulo, Line1, Line2 } from './bannerteamstyle'
// import fondo from './1080 red.jpg' 


const BannerTeam = () => {
  return (
    <Container>
      <Titulo>
        <Line1>NSTA nace de la unión de un equipo de profesionales con algo en común: el amor por el tenis</Line1>
        <Line2>
          Te invitamos a conocer a los entrenadores y al resto del personal de NSTA. Aunque cada uno de nosotros es diferente, todos compartimos la misma pasión por este deporte. Tenemos la suerte de contar con los mejores entrenadores, por lo que la excelencia en el servicio está garantizada. Sigue leyendo para saber más sobre ellos.
        </Line2>
      </Titulo>
    </Container>
  )
}

export default memo(BannerTeam)
