import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto,TituloChico} from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faDumbbell } from '@fortawesome/free-solid-svg-icons';   

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faDumbbell}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>PREPARACIÓN FÍSICA </Titulo>

   
        

          <Lista>
            <ElementoLista><strong>Net Sport Tennis Academy</strong> tiene contratada directamente a BePlus Sport en el área de preparación física, así se asegurá que cada jugador de la academia pueda alcanzar su mejor rendimiento en el tenis.
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista><strong>BePlus Sport</strong>, como empresa especializada en preparación física para tenistas, proporcionará a los jugadores de Net Sport Tennis Academy un enfoque personalizado y adaptado a las necesidades individuales y grupales de cada jugador. Su evaluación física exhaustiva permitirá identificar las áreas de mejora y crear un plan de entrenamiento específico para cada jugador y grupo.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>Con la experiencia y conocimientos de BePlus Sport en entrenamientos de tenis, los jugadores de Net Sport Tennis Academy podrán desarrollar su fuerza, resistencia, velocidad, agilidad,  flexibilidad entre otras de manera efectiva. Además, el asesoramiento nutricional brindado por BePlus Sport garantizará que los jugadores estén siguiendo una alimentación adecuada para maximizar su rendimiento en el tenis.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista>En resumen, la colaboración entre Net Sport Tennis Academy y BePlus Sport en el área de preparación física asegurará que los jugadores tengan acceso a un programa de entrenamiento personalizado y adaptado a sus necesidades individuales, lo que les permitirá mejorar su rendimiento deportivo.

            </ElementoLista>
          </Lista>



          <TituloChico><strong> BePlus Sport:   </strong></TituloChico>
          <Lista>
            <ElementoLista>Es una empresa dedicada a brindar servicios de preparación física especializados para deportistas, incluyendo tenistas. Su objetivo es ayudar a los tenistas a mejorar su rendimiento en las pistas a través de un entrenamiento físico adaptado a las necesidades específicas de este deporte.

            </ElementoLista>
          </Lista>



          <TituloChico><strong> Sus servicios de preparación física incluyen:  </strong></TituloChico> <br></br>

          <TituloChico><strong> 1. Evaluación de estado físico: </strong></TituloChico>

          <Lista>
            <ElementoLista>Antes de comenzar cualquier programa de entrenamiento, BePlus Sport realiza una evaluación exhaustiva del estado físico del tenista. Esto les permite diseñar un programa de entrenamiento personalizado que se ajuste a las necesidades y metas específicas de cada individuo, siempre coordinado con el entrenador principal del jugador/a. 


            </ElementoLista>
          </Lista>

          <TituloChico><strong> 2. Plan de entrenamiento personalizado: </strong></TituloChico>

          <Lista>
            <ElementoLista>BePlus Sport desarrolla planes de entrenamiento personalizados basados en los resultados de la evaluación física y los objetivos del tenista. Estos planes pueden incluir ejercicios de fuerza, resistencia, velocidad, agilidad y flexibilidad, entre otros.



            </ElementoLista>
          </Lista>









        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
