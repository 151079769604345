import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar';
import Header from '../../components/Header/header';
import Logo from '../../components/Logo/logo';
import Footer from '../../components/Footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ContainerGeneral = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lumier', sans-serif;
  min-height: 100vh; // Asegura que el contenedor tenga al menos la altura de la ventana
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
`;

const TituloEvento = styled.h2`
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-top: 50px;
  text-align: center;
  font-size: 29px;
`;

const FechaEvento = styled.div`
  color: #4b4b4b;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
`;

const IconoFecha = styled(FontAwesomeIcon)`
  margin-right: 8px; 
`;

const ImagenEvento = styled.img`
  width: 80%; // Ajusta el tamaño de la imagen
  max-width: 700px; // Hace la imagen más pequeña y adecuada para móviles
  border-radius: 10px;
  margin-top: 30px;
  align-self: center; // Centra la imagen
  @media (max-width: 768px) {
  width: 95%;
  }


`;


const ContainerTexto = styled.div`
  max-width: 60%;
  text-align: left;
  margin: 20px auto; /* Centrado horizontalmente */
  margin-bottom: 100px;
  padding: 20px; /* Espacio interior para no pegar el texto a los bordes */
  background-color: #f9f9f9; /* Color de fondo suave */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave para dar profundidad */
  border: 1px solid #ddd; /* Borde sutil */
  animation-fill-mode: forwards;

  @media (max-width: 768px) {
    max-width: 94%;
  }
`;

const Descripcion = styled.div`
  text-align: left;
  font-family: 'RNSMiles';
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  margin-top: 20px;
`;


const Eventos = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerGeneral>

        <TituloEvento>Campeonato de Andalucía Alevín y Cadete Fase Provincial Huelva</TituloEvento>

        <FechaEvento>
          <IconoFecha icon={faCalendarAlt} /> 07/03/2024 - 10/03/2024
        </FechaEvento>

        <ImagenEvento src='/Events/CAMPEONATO DE ANDALUCIA ALEVIN Y CADETE.jpg' />





        < ContainerTexto>

          <Descripcion>
          La fase provincial del Campeonato de Andalucía, en las categorías Alevín y Cadete, se celebrará en el Bellavista Huelva Club. Este evento deportivo reunirá a los jóvenes talentos del tenis, quienes competirán por avanzar a la siguiente etapa del campeonato regional. Las instalaciones del Bellavista Huelva Club ofrecen un entorno ideal para la competición, prometiendo ser un escenario emocionante tanto para los participantes como para los espectadores.
          </Descripcion>

   
        </ContainerTexto>




      </ContainerGeneral>
      <Footer />
    </div>
  );
};

export default Eventos;
