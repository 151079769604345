import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${fadeIn} 2s ease-in-out;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 500px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const Video = () => {
  const vimeoLink = "https://player.vimeo.com/video/980934052?title=0&byline=0&portrait=0&badge=0&autoplay=0&controls=1";

  return (
    <Container>
      <VideoFrame
        src={vimeoLink}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </Container>
  );
}

export default memo(Video);
