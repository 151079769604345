import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import imagen1 from './1.png';
import imagen2 from './2.png';
import imagen3 from './3.png';
import imagen4 from './4.png';
import imagen5 from './5.png';
import imagen6 from './6.png';
import imagen7 from './7.png';
import imagen8 from './8.png';
import imagen9 from './9.png';

const Container = styled.div`
  margin: 20px auto;
  max-width: 49%;

  @media (max-width: 767px) {
    max-width: 80%;
  }
`;

const SliderContainer = styled.div`

  align-items: center; // Centra verticalmente
  justify-content: center; // Centra horizontalmente
  max-width: 100%;
  height: 150px;
`;


const Image = styled.img`
  max-height: 100px; // Altura máxima
  max-width: 60%; // Ancho máximo del 100% para evitar que sobrepasen su contenedor
  object-fit: contain; // Asegura que la imagen completa sea visible
  margin: 0 auto; // Centra la imagen
  align-items: center !important;

`;

const ImageContainer = styled.div`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100px !important; // Ajusta según la altura de tus imágenes
    
`;

const TituloCabeza = styled.h3`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  margin-top: 40px;
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 10%,
    rgb(242, 242, 242) 45%,
    transparent 95%,
    transparent 100%
  );
`

const InfoText = styled.p`
  text-align: center;
  color: #000000;
  font-family: 'RNSMiles';
  font-size: 19px;
`;

const StyledLink = styled(Link)`
  font-size: 13px;
  font-family: 'GarnetLight';
  display: inline-block;
  margin-bottom: 16px;
  padding: 10px 20px;
  background-color: #a0f000;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;


const Patrocinadores = () => {
  const images = [imagen1, imagen2, imagen3, imagen4, imagen5,imagen6,imagen7,imagen8,imagen9];

  const settings = {
    dots: true,
    infinite: true,
    speed: 800, // Velocidad de la transición
    slidesToShow: 4, // Mostrar 4 logos a la vez
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Intervalo de tiempo para cambiar de slide
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <Container>
      <TituloCabeza>PATROCINADORES Y COLABORADORES</TituloCabeza>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((image, index) => (
            <ImageContainer key={index}>
              <Image src={image} alt={`Imagen ${index + 1}`} />
            </ImageContainer>
          ))}
        </Slider>
      </SliderContainer>
      <InfoText>Si quieres saber más sobre nuestros patrocinadores, pulsa aquí:</InfoText>
      <ButtonContainer>
        <StyledLink to="/clubs/patrocinadores">Conoce a los patrocinadores</StyledLink>
      </ButtonContainer>
    </Container>
  );
};

export default Patrocinadores;