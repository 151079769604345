import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import styled from 'styled-components';
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Lista, ElementoLista, Titulo, TituloChico, ContainerTexto } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faFlag } from '@fortawesome/free-solid-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre imágenes */
`;

const Imagen = styled.img`

  width: 90%; /* Ajusta este valor según necesites para el ancho de la imagen */
  max-width: 600px; /* Controla el máximo ancho de la imagen */
  height: auto; /* Ajusta el alto automáticamente para mantener la proporción de la imagen */
  margin: 30px; /* Margen alrededor de la imagen */
`;






const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #a0f000; // Color azul de Instagram
  color: #000000;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #007ac1;
    transition: background-color 0.3s;
  }
`;



// Define el componente StyledIcon con margen interno
const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 5px; /* Ajusta el margen interno como desees */
`;


const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faFlag} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>



          <Titulo>EVENTOS INTERNOS NSTA EN CLUB RÍO GRANDE </Titulo>



          <TituloChico><strong> Nos apasiona realizar eventos en nuestra academia y el objetivo que perseguimos con los mismos, no es otro que el fomento del tenis en todas sus vertientes </strong></TituloChico>


          <Lista>
            <ElementoLista>- Forjamiento de nuevas amistades entre alumnos de diferentes clases y horarios </ElementoLista>

            <ElementoLista>- Aprender a jugar al tenis, nuestros alumnos/as dan sus primeros pasos en la competición, aprendiendo las reglas del tenis, mejorando el autoconocimiento personal y en la gestión y regulación de sus emociones frente a la resolución de problemas que plantea nuestro deporte. Todo ello, bajo la ayuda de nuestra plantilla de trabajadores para darles las herramientas oportunas en su crecimiento personal y deportivo.</ElementoLista>

            <ElementoLista>- Nos ayuda al programa de detección de talentos.</ElementoLista>

            <ElementoLista>- Fomenta el tejido social del club, nuevas amistades entre familias surgen en cada evento.</ElementoLista>

            <ElementoLista>- Sirve como preparación para las competiciones federadas.</ElementoLista>

          </Lista>



          <TituloChico><strong>  INFO EVENTOS PROMOVIDOS Y ORGANIZADOS POR NET SPORT TENNIS ACADEMY   </strong></TituloChico> <br></br> <br></br>


          <Titulo>NSTA KIDS: </Titulo>
          <Lista>
            <ElementoLista>Este evento de carácter recreativo, esta dedicado a las edades comprendidas entre los 4 y los 12 años. El objetivo es adentrar a los más pequeños en la competición, que den sus primeros pasos en la misma, que aprendan las reglas de juego básicas, hagan nuevas amistades y, fundamentalmente, que se enamoren del tenis. Se disputan las siguientes categorías en formato mixto (masculinos y femeninos en conjunto):
            </ElementoLista>
          </Lista>




          <strong> - Sub-6; sub-8; sub-10 y sub-12  </strong>



          <ContainerImagenes>
            <Imagen src={require('./nstakids.jpg')} alt="nstakids" />
          </ContainerImagenes>




          <Titulo> NSTA KIDS BENÉFICO DE NAVIDAD: </Titulo>


          <Lista>
            <ElementoLista>  Variante muy especial, que tiene lugar durante las navidades y donde fomentamos valores de beneficencia en nuestros alumn@s, a través de la donación de regalos a los niñ@s más necesitados.
            </ElementoLista>
          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./benefico.jpg')} alt="benefico" />
          </ContainerImagenes>


          <Container>
            <StyledLink href="https://www.instagram.com/reel/CmZY7HpjELa/?igsh=MTc4MmM1YmI2Ng==" target="_blank">
              <StyledIcon icon={faInstagram} />
              Ver video en Instagram
            </StyledLink>
          </Container>



          <Titulo> CIRCUITO INTERNO NSTA-RG: </Titulo>


          <Lista>
            <ElementoLista>           Está comprendido por los torneos de otoño, primavera, verano y master. Está dirigido a socios y alumnos de la escuela Net Sport Tennis Academy. Aquí la premisa es la diversión, ser el punto de unión entre los diferentes grupos de la escuela y sumar experiencia dentro de la competición, sirviendo a la vez como preparación, para las competiciones federadas individuales y por equipos. Se disputan las siguientes categorías:  </ElementoLista>
            <strong>- Masculinas: sub-10; sub-12; sub-14 y absoluto.</strong>
            <strong> - Femeninas: sub-10; sub-12; sub-14 y absoluto.</strong>

          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./circuitointerno.jpg')} alt="circuitointerno" />
          </ContainerImagenes>


          <Titulo>  TORNEO MADRES, PADRES E HIJ@S:</Titulo>

          <Lista>
            <ElementoLista>      Este es uno de nuestros eventos más especiales. Se juega en formato dobles, donde un menor juega en pareja con un mayor de la familia (madre, padre, tío, abuelo, etc.). El objetivo es disfrutar del tenis en familia y fomentar el tejido social del club. </ElementoLista>
          </Lista>


          <Container>
            <StyledLink href="https://www.instagram.com/reel/CyBd-s9sg_5/?igsh=MTc4MmM1YmI2Ng==" target="_blank">
              <StyledIcon icon={faInstagram} />
              Ver video en Instagram
            </StyledLink>
          </Container>


          <Titulo> TORNEO SOCIAL:</Titulo>



          <Lista>
            <ElementoLista>    El torneo social de Río Grande es uno de los más apasionantes, prestigiosos y competitivos dentro de los eventos promovidos por Net Sport Tennis Academy, tiene lugar durante la última semana de junio. En dicho certamen podemos disfrutar de emocionantes encuentros entre diferentes generaciones de los mejores jugadores de nuestro querido club. Se disputan las siguientes categorías:
            </ElementoLista>

            <strong>           - Individual absoluto masculino federado</strong>  <br></br>

            <strong>       - Individual absoluto femenino federado</strong><br></br>

            <strong>       -  Individual absoluto masculino no federado</strong><br></br>

            <strong>        - Individual absoluto femenino no federado</strong><br></br>


          </Lista>



      

          <ContainerImagenes>
            <Imagen src={require('./3.jpg')} alt="3" />
          </ContainerImagenes>

          <Titulo> RUEDA DE PREMIOS NSTA:</Titulo>




          <Lista>
            <ElementoLista>
              Esta dinámica innovadora por parte de Net Sport Tennis Academy, está presente en cada uno de nuestros eventos y todos pueden participar. Consiste en una rueda donde, a través de filas organizadas, cada participante dirige sus golpes a dianas con premios (clases particulares, material deportivo, cordajes, chuches, etc.). ¿Aún no la has probado? ¡Te encantará!

            </ElementoLista>

          </Lista>


          <ContainerImagenes>
            <Imagen src={require('./ruedadepremios.jpg')} alt="ruedadepremios" />
          </ContainerImagenes>
          <ContainerImagenes>
            <Imagen src={require('./5.jpg')} alt="5" />
          </ContainerImagenes>






          <Titulo> NSTA FAMILY:</Titulo>




          <Lista>
            <ElementoLista>
            NSTA FAMILY es uno de los últimos eventos diseñados por NSTA con el objetivo de disfrutar del tenis en familia. Consiste en una jornada de clases de tenis dirigidas a todo el núcleo familiar, donde comparten la misma clase mama, papa, hij@s, abuelos, tíos, primos, etc. Siempre el grupo familiar debe ser mayor de 3 y menos de 6 participantes.


            </ElementoLista>

          </Lista>


    




        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
