import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  margin: 0px auto;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
   
    border-radius: 5px;
  
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
 font-family: 'GarnetLight';

  font-size: 25px; 
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
`;

const Imagen = styled.img`
  width: 100%; // Asegura que la imagen sea responsive
  max-width: 60%;
  margin: 50px auto; // Centra la imagen horizontalmente
  display: block; // Evita que la imagen sea inline
  border: 2px solid #e1e1e1; // Un borde sólido y sutil
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Sombra suave para crear profundidad
  border-radius: 5px; // Opcional: bordes redondeados para suavizar los bordes

  @media (max-width: 768px) {
   
    max-width: 90%;
 
 }
  
`;


const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;

const Boton = styled.a`
  font-size: 12px;
  width: 80%;
  font-family: 'GarnetLight';
  display: inline-block;
  margin-bottom: 26px;
  padding: 15px 20px;
  background-color: #a0f000; // Color inicial
  color: #000000; // Color del texto inicial
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite; // Aplicar la animación aquí

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;





// Incluimos la definición de la función isIOS dentro del componente
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const Documentacion = () => {
  return (
    <div>
      <Container>
        <ContainerTexto> 
          
           <NSAPlusTitulo>CAMPUS DE VERANO</NSAPlusTitulo>
        <Imagen src="/AIRBUS Campus de verano 2024 .jpg" alt="AIRBUS Campus de verano 2024" />
        

        <ButtonContainer>
            <Boton href="https://docs.google.com/forms/d/e/1FAIpQLSc3JTxxZfuUDybyiU7QN8kd-ull0h1n68K0pTknkB7f4qS_iQ/viewform" target="_blank" rel="noopener noreferrer">
             inscripción
            </Boton>
          </ButtonContainer> 


        </ContainerTexto>
        {
          isIOS() ? (
            <PdfLink href="/PDF/CAMPUS DE VERANO G.E AIRBUS.pdf" target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </PdfLink>
          ) : (
            <iframe
              style={{width: '100%', height: '800px', border: 'none'}}
              src="/PDF/CAMPUS DE VERANO G.E AIRBUS.pdf"
              title="Campus de Verano"
            ></iframe>
          )
        }
      </Container>
    </div>
  );
};

export default Documentacion;