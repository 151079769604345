import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Descripcion, Titulo, ContainerImagenes, Imagen } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendar } from '@fortawesome/free-solid-svg-icons';   

const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faCalendar}/> {/* Usar el nuevo icono aquí */}

      <ContainerGeneral>
     


      <Titulo>EVENTOS DE PADEL Y PICKLEBALL  </Titulo>

   
        
     

   



        <Descripcion>

        <li><strong>1 TORNEOS FEDERADOS Y SOCIALES</strong></li>
<ContainerImagenes>
    <Imagen src={require('./eventos1.jpg')} alt="kiosko bar" />
</ContainerImagenes>
<li><strong>2 QUEDADAS EN GRUPO</strong></li>
<ContainerImagenes>
    <Imagen src={require('./eventos2.jpg')} alt="kiosko bar" />
</ContainerImagenes>
<li><strong>3 CUMPLEAÑOS DEPORTIVOS (MENORES Y ADULTOS)</strong></li>  <br></br>

<li><strong>4 ORGANIZACIÓN DE "EVENTO EMPRESA"</strong></li>  <br></br>
<li><strong>5 ALQUILER DEL ESPACIO CLUB DEPORTIVO PARA EVENTOS PRIVADOS</strong></li>

 
</Descripcion>

        



<Titulo> DESCUENTOS</Titulo>
          <ContainerImagenes>
  <Imagen src={process.env.PUBLIC_URL + '/descuentos.jpg'} alt="Descuentos" />
</ContainerImagenes>

  






      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDeFormacion
