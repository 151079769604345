import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import './stylesheader.css';



const HeaderContainer = styled.header`
  box-shadow: 0 8px 15px rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.$scrolled ? '100px' : '120px'};
  z-index: 30;
  transition: box-shadow 0.5s, height 0.5s;
  will-change: height, box-shadow; // Agregar will-change aquí

  
  .solid-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(13, 20, 22, 0.887);
    opacity: ${props => props.$scrolled ? 1 : 0};
    transition: opacity 0.5s;
    will-change: opacity; // Agregar will-change aquí
  }

  .gradient-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(145deg, #0f1a20, #2E5868);

    opacity: ${props => props.$scrolled ? 0 : 1};
    transition: opacity 0.5s;
    will-change: opacity; // Agregar will-change aquí
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0000007d);
    z-index: 3;
    pointer-events: none; /* Ignora los eventos del mouse */
  }
  
`;


const SubMenuContainer = styled.header`
  font-family: 'Lumier';
position: absolute;
  top: 30px!Important;
  left: 200px!Important;
  list-style: none;
  margin: 0;
  padding-bottom: 25px!Important;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row; 
  z-index: 34!important;
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  font-weight: bold!important;
 
`;

const SubMenuContainerCalendario = styled.header`
  font-family: 'Lumier';
position: absolute;
  top: 30px!Important;
  left: 362px!Important;
  list-style: none;
  margin: 0;
  padding-bottom: 25px!Important;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row; 
  z-index: 34!important;
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  font-weight: bold!important;
 
`;


const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [showClubsSubMenu, setShowClubsSubMenu] = useState(false);
  const [showCalendarioSubMenu, setShowCalendarioSubMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleClubsSubMenu = () => {
    setShowClubsSubMenu(!showClubsSubMenu);
    setShowCalendarioSubMenu(false); // Cerrar el submenú de calendario si está abierto
  };

  const toggleCalendarioSubMenu = () => {
    setShowCalendarioSubMenu(!showCalendarioSubMenu);
    setShowClubsSubMenu(false); // Cerrar el submenú de clubs si está abierto
  };

  const isClubs = location.pathname.startsWith('/clubs');
  const isCalendario = location.pathname.startsWith('/calendario');

  return (
    <HeaderContainer className="header" $scrolled={scrolled}>
      <div className="solid-bg" />
      <div className="gradient-bg" />

      <div className="header-content">
        <nav className="header-menu">
          <ul>
            <li className={location.pathname === '/' ? 'active' : ''}>
              <NavLink exact="true" to="/">
                NET SPORT ACADEMY 
              </NavLink>
            </li>

            <li className={isClubs ? 'active' : ''} onClick={toggleClubsSubMenu}>
              <NavLink to="#">
                CLUBS
              </NavLink>
              <SubMenuContainer className="submenu" $scrolled={scrolled} style={{ opacity: showClubsSubMenu ? 1 : 0, pointerEvents: showClubsSubMenu ? 'auto' : 'none' }}>
                <li>
                  <NavLink to="/clubs/riogrande" activeclassname="active">
                    CLUB RIO GRANDE
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/clubs/bellavista" activeclassname="active">
                    BELLAVISTA HUELVA CLUB
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/clubs/palomares" activeclassname="active">
                    PALOMARES PÁDEL CLUB
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/clubs/airbus" activeclassname="active">
                    G.E. AIRBUS PÁDEL Y PICKLEBALL CLUB
                  </NavLink>
                </li>
              </SubMenuContainer>
            </li>

            <li className={location.pathname === '/eventos' ? 'active' : ''}>
              <NavLink to="/eventos">
                EVENTOS
              </NavLink>
            </li>

            <li className={isCalendario ? 'active' : ''} onClick={toggleCalendarioSubMenu}>
              <NavLink to="#">
                CALENDARIO
              </NavLink>
              <SubMenuContainerCalendario className="submenu" $scrolled={scrolled} style={{ opacity: showCalendarioSubMenu ? 1 : 0, pointerEvents: showCalendarioSubMenu ? 'auto' : 'none' }}>
                <li>
                  <NavLink to="/calendario" activeclassname="active">
                    CALENDARIO EVENTOS 
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/clubs/riogrande/calendarioriogrande" activeclassname="active">
                    CALENDARIO RIO GRANDE 
                  </NavLink>
                </li>
              </SubMenuContainerCalendario>
            </li>

            <li className={location.pathname === '/team' ? 'active' : ''}>
              <NavLink to="/team">
                TEAM
              </NavLink>
            </li>

            <li className={location.pathname === '/noticias' ? 'active' : ''}>
              <NavLink to="/noticias">
                NOTICIAS
              </NavLink>
            </li>

            <li className={location.pathname === '/sociosnstalogin' ? 'active' : ''}>
              <NavLink to="/sociosnstalogin">
                SOCIOS NSA PLUS
              </NavLink>
            </li>

            <li className={location.pathname === '/videoedicion' ? 'active' : ''}>
              <NavLink to="/videoedicion">
                VIDEO EDICIÓN
              </NavLink>
            </li>

            <li className={location.pathname === '/contacto' ? 'active' : ''}>
              <NavLink to="/contacto">
                CONTACTO
              </NavLink>
            </li>

            <li className={location.pathname === '/enlacesdeinteres' ? 'active' : ''}>
              <NavLink to="/enlacesdeinteres">
              ENLACES DE INTERÉS
              </NavLink>
            </li>

          </ul>
        </nav>
      </div>
    </HeaderContainer>
  );
};

export default Header;