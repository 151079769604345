import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Titulo, ContainerTexto, ImagenContainer } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faChartBar } from '@fortawesome/free-solid-svg-icons';




const MensajeExclusivo = styled.div`
 margin: 40px auto;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;
  top: 320px;
position: absolute;
  text-align: center;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    padding: 15px;
    top: 330px;
    max-width: 300px;
  }
`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000 !important; /* Color de fondo del botón */
  color: #000000 !important; 
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;

  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  transition: all ease 0.125s;
  margin-top: 8px;
  text-align: center;
  display: block; /* Para que ocupe su propio espacio */
  color: inherit; /* Color del texto */
  text-decoration: none; /* Sin subrayado */

  &:hover {
    background-color: #000000 !important; /* Cambiar el color de fondo al pasar el mouse */
    color: #ffffff !important; /* Cambiar el color del texto al pasar el mouse */
  }
`;


const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faChartBar} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>



  


          <Titulo>ESTADÍSTICAS Y VÍDEO ANÁLISIS </Titulo>

          <ImagenContainer>
        
           
            <MensajeExclusivo>
            
              <p>¿Interesado en estadísticas y vídeo análisis? Únete a nuestra comunidad de socios en NET SPORT ACADEMY. ¡Contacta con el equipo para resolver cualquier duda y descubre todo lo que podemos ofrecerte en tu camino hacia la excelencia deportiva!</p>
              <BotonContacto to="/contacto">Contacto</BotonContacto>
            </MensajeExclusivo>
          </ImagenContainer>



{/* 
          <TituloChico><strong> Estadísticas NSA: </strong></TituloChico>
          <br></br>


          <Lista>
            <ElementoLista>Net Sport Academy presenta nuestro nuevo programa de estadísticas en el juego, diseñado tanto para ayudarles a mejorar su propio rendimiento a través de un mayor conocimiento en nuestro patrón como jugador, en toma de decisiones en el juego, y además en estudios de rivales para obtener información valiosa sobre ellos. Sabemos que el análisis de datos es una herramienta fundamental en el tenis y pádel moderno, y nuestro programa está diseñado para brindarles una ventaja competitiva en el crecimiento del jugador/a.

            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>Tal y como os hemos adelantado, NSA ofrece una serie de características que les permitirán obtener una visión profunda de su propio juego y de los rivales. Algunas de las principales características incluyen:

            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista><strong>1. Registro de datos en entrenamientos y competiciones:</strong> Podrán registrar datos durante sus sesiones de entrenamiento y en cada encuentro de competición. Esto nos permitirá realizar un seguimiento exhaustivo del rendimiento y evaluar el progreso a lo largo del tiempo.

            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista><strong>2. Análisis de juego propio (encontrar nuestro patrón ideal de jugador): </strong>El programa nos proporcionará información detallada sobre el rendimiento en cada aspecto del juego, como el porcentaje de aciertos, construcción de jugadas, errores no forzados, como y cuando, % de efectividad en cada zona, control de tiempos... Esto nos ayudará a identificar nuestras fortalezas y debilidades, y a enfocarse en áreas específicas para mejorar.

            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista><strong>3. Análisis de rivales:</strong> Nuestro programa recopila datos sobre nuestros rivales, permitiéndonos conocer mejor su estilo de juego, puntos fuertes, patrones de juego… Esta información nos ayudará a desarrollar estrategias más efectivas para enfrentarnos a cada rival y también a tomar las decisiones correctas con dichas informaciones durante los partidos.


            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista><strong>4. Comparación con jugadores profesionales:</strong> Además de analizar su propio juego y el de sus rivales, el programa también nos permitirá comparar estadísticas con las de jugadores profesionales de alto nivel. Esto nos brindará una referencia adicional.


            </ElementoLista>
          </Lista>



          <Lista>
            <ElementoLista><strong>5. Informes detallados:</strong>  El programa generará informes detallados con gráficas y estadísticas, que nos permitirán entender mejor y tomar decisiones en función de estos datos.


            </ElementoLista>
          </Lista>








          <Lista>
            <ElementoLista>

              En resumen, nuestro programa NSA Estadísticas nos brindará una herramienta invaluable para mejorar nuestro propio rendimiento y entender mejor a nuestros rivales. Estamos seguros de que, con un uso regular y el análisis de los datos, podrán tomar decisiones más informadas y aumentar la competitividad en cada partido.



            </ElementoLista>
          </Lista>





          <Lista>
            <ElementoLista>

              Los invitamos a probar nuestro programa y aprovechar al máximo esta herramienta. Estamos seguros de que les ayudará a alcanzar sus metas y a destacar en su juego.



            </ElementoLista>
          </Lista>




          <Lista>
            <ElementoLista>  <strong>Atentamente,<br></br>

              Lolo Pineda<br></br>
              Director Net Sport Academy
            </strong>





            </ElementoLista>
          </Lista> */}






        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
