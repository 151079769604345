import React, { memo } from 'react'


import { Container, Titulo, Line1 } from './bienvenidostyle'

const NuevoUsuario = () => {
  return (
    <Container>
      <Titulo>
        <Line1>¡Bienvenido al área exclusiva para Socios NSA Plus!</Line1>

      </Titulo>
    </Container>
  )
}

export default memo(NuevoUsuario)
