import React from 'react';
import Router from './containers/Router';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { AuthProvider } from "./firebase/auth";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <div>
          <Router />
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
