import styled, { keyframes } from 'styled-components';
import React from 'react'
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import Footer from '../../components/Footer/footer'

import BannerBellavista from '../../components/Bellavista/BannerBellavista/bannerriogrande'
import TextoBellavista from '../../components/Bellavista/TextoBellavista/textobellavista'
import Carrusel from '../../components/Bellavista/Carrusel/carruselbellavista'
import TenisOPadel from '../../components/Bellavista/TenisOPadel/tenisopadel'

import PatrocinadoresBellavista from '../../components/Bellavista/PatrocinadoresBellavista/patrocinadores'

import FooterBellavista from '../../components/Bellavista/FooterBellavista/footerbellavista'


// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const ContainerGeneral = styled.div`

  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;





const Bellavista = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />

      <ContainerGeneral>

      <BannerBellavista />
      <TextoBellavista />
      <Carrusel />
      <TenisOPadel />

      <FooterBellavista />
     <PatrocinadoresBellavista/>


      </ContainerGeneral>
      <Footer />
      <></>
    </div>
  )
}

export default Bellavista
