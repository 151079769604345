import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`

  margin: 40px auto;
  max-width: 600px;
  padding: 40px;
  text-align: center;
  background-color: #182329; /* Fondo oscuro para resaltar */
  color: #fff; /* Texto en color claro para contrastar */
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555; /* Borde sutil en color más claro */
`;

const Icon = styled(FontAwesomeIcon)`
  color: #ff4500;
  margin-bottom: 20px;
  font-size: 50px;
`;


const Title = styled.h2`
  font-family: 'GarnetLight';
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  color: #ddd;
  font-size: 18px;
  line-height: 1.6;
`;

const NoDocumento = () => {
  return (
    <Container>
        <Icon icon={faLock} />
      <Title>Acceso Restringido</Title>
      <Message>
      Estamos en el proceso de compilar tu documentación especializada de nivel NSA. 
        Esta información exclusiva y de alta importancia estará disponible aquí en breve. 
        Te invitamos a estar en constante vigilancia para acceder a ella tan pronto como 
        se habilite. Tu atención y discreción son altamente valoradas.
      </Message>
    </Container>
  );
};

export default NoDocumento;