import React, { useState, useEffect, useRef } from 'react';
import styled  from 'styled-components';
import { Link } from 'react-router-dom'
import RedesSociales from '../../components/RedesSociales/redessociales'

const Li = styled.li`
  font-family: 'Lumier';
  padding: 7px 1px;
  font-size: 17px;
  cursor: pointer;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  margin: 0px 0px 0px 0px;
  flex-flow: column nowrap;
  font-weight: bold;
  color: #ffffff;
  z-index: 1001;

  &.main-menu-item:hover {
    color: #a0f000;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }
`

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  z-index: 400!Important;
  background: linear-gradient(145deg, #0f1a20, #16303a);
  position: fixed;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  top: 0;
  right: 0;
  height: ${({ viewportHeight }) => viewportHeight}px; // Ajusta esta línea
  width: 100%; 
  padding-top: 5rem;
  transition: transform 0.4s ease-in-out;


  @media (min-width: 768px) { 
    width: 400px; 
  }
`;

const SubMenu = styled.ul`
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  height: 305px;
  color: #ffffff;
  margin: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: -149px;
  margin-left: -21px;

  &.submenu-item:hover {
    color: #a0f000;
  }

  li {
    position: relative;
    padding: 6px;
  

    &:hover {
      transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
      color: #a0f000;
    }

    &::before {
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: #a0f000;
      border-radius: 50%;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`
const SubMenuCalendario = styled.ul`
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  height: 157px;
  color: #ffffff;
  margin: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: -75px;
  margin-left: -21px;

  &.submenu-item:hover {
    color: #a0f000;
  }

  li {
    position: relative;
    padding: 6px;
  

    &:hover {
      transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
      color: #a0f000;
    }

    &::before {
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: #a0f000;
      border-radius: 50%;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`


const Icon = styled.span`
  margin-left: 13px;
  font-size: 20px;
`

const RightNav = ({ open, closeMenu }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const navRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (open && window.innerWidth < 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalOverflow;
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        if (typeof closeMenu === 'function') {
          closeMenu();
        }
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, closeMenu]);

  const toggleSubMenu = (menuName) => {
    setActiveSubMenu((prev) => (prev === menuName ? null : menuName));
  };

  const handleLinkClick = () => {
    setActiveSubMenu(null);
    if (typeof closeMenu === 'function') {
      closeMenu();
    }
  };

  const handleSubMenuClick = (event) => {
    event.stopPropagation(); // Esto previene que el click en el submenú cierre el menú
  };

  return (
    <Ul open={open} style={{ height: `${viewportHeight}px` }} ref={navRef}>
      <Li className="main-menu-item">
        <Link to="/" onClick={handleLinkClick}>
          NET SPORT ACADEMY
        </Link>
      </Li>

      <Li className="main-menu-item" onClick={handleSubMenuClick}>
        <Link to="#" onClick={() => toggleSubMenu('clubs')}>
          CLUBS
          <Icon>{activeSubMenu === 'clubs' ? '-' : '+'}</Icon>
        </Link>
        <SubMenu open={activeSubMenu === 'clubs'}>
          <li className="submenu-item">
            <Link to="/clubs/riogrande" onClick={handleLinkClick}>
              CLUB RIO GRANDE
            </Link>
          </li>
          <li className="submenu-item">
            <Link to="/clubs/bellavista" onClick={handleLinkClick}>
              BELLAVISTA HUELVA CLUB
            </Link>
          </li>
          <li className="submenu-item">
            <Link to="/clubs/palomares" onClick={handleLinkClick}>
              PALOMARES PÁDEL CLUB
            </Link>
          </li>
          <li className="submenu-item">
            <Link to="/clubs/airbus" onClick={handleLinkClick}>
              G.E. AIRBUS PÁDEL Y PICKLEBALL CLUB
            </Link>
          </li>
        </SubMenu>
      </Li>

      <Li className="main-menu-item" onClick={handleSubMenuClick}>
        <Link to="#" onClick={() => toggleSubMenu('calendario')}>
          CALENDARIO
          <Icon>{activeSubMenu === 'calendario' ? '-' : '+'}</Icon>
        </Link>
        <SubMenuCalendario open={activeSubMenu === 'calendario'}>
          <li className="submenu-item">
            <Link to="/calendario" onClick={handleLinkClick}>
              CALENDARIO EVENTOS
            </Link>
          </li>
          <li className="submenu-item">
            <Link to="/clubs/riogrande/calendarioriogrande" onClick={handleLinkClick}>
              CALENDARIO RIO GRANDE
            </Link>
          </li>
        </SubMenuCalendario>
      </Li>

      <Li className="main-menu-item">
        <Link to="/eventos" onClick={handleLinkClick}>
          EVENTOS
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/team" onClick={handleLinkClick}>
          TEAM
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/noticias" onClick={handleLinkClick}>
          NOTICIAS
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/sociosnstalogin" onClick={handleLinkClick}>
          SOCIOS NSA PLUS
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/videoedicion" onClick={handleLinkClick}>
          VIDEO EDICIÓN
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/contacto" onClick={handleLinkClick}>
          CONTACTO
        </Link>
      </Li>

      <Li className="main-menu-item">
        <Link to="/enlacesdeinteres" onClick={handleLinkClick}>
          ENLACES DE INTERÉS
        </Link>
      </Li>

      <RedesSociales />
    </Ul>
  );
};

export default RightNav;