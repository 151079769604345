import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faUsers,
  faChalkboardTeacher,
  faTrophy,
  faFileAlt,
  faSun,
  faCampground,
  faFlag,
  faUserFriends,
  faCalendar,
  faList,
  faUser,
  faDumbbell,
  faCalendarAlt,
  faWrench,
  faMedal,
  faChalkboard,
  faChartBar,
  faPlayCircle,
  faCoffee,
  faBirthdayCake,
  faEllipsisH,
  faBolt,
  faBuilding


} from '@fortawesome/free-solid-svg-icons'

const ContenedorPrincipal = styled.div`
  margin-bottom: 50px;
`
const TituloCabeza = styled.h3`


  font-family: 'GarnetLight';
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 26px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 10%,
    rgb(242, 242, 242) 45%,
    transparent 95%,
    transparent 100%
  );
`



const ContenedorApartado = styled.div`
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px;
`
const Titulo = styled.h3`
  color: #000000;
  font-weight: 400;
  font-family: 'GarnetLight';
  margin-left: 4px;
  margin-right: 4px;

  font-size: 20px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
const Cards = styled.figure`
  background-color: ${(props) => props.color};
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 325px;
  box-sizing: border-box;
  margin: 5px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 10px 10px rgb(173, 173, 173);
  background-color: #a0f000cf;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 45%;
    max-height: 200px;
  }
`
const CardsSelecion = styled.figure.attrs(({ isActive, ...rest }) => rest)`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 800px;
  box-sizing: border-box;
  margin: 5px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 10px 10px rgb(173, 173, 173);
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
  
  /* Aquí usamos las props directamente para aplicar estilos condicionales */
  ${({ isActive }) => !isActive && css`
    filter: grayscale(100%);
  `}
  
  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    max-width: 45%;
    max-height: 200px;
  }
`;
const ContenedorBoton = styled.img`
  margin: 0;
  width: 100%;
  height: 200px;
  will-change: transform;

  @media (max-width: 768px) {
    height: 110px;
  }
`
const TituloImagenes = styled.h3`
  color: #ffffff;
  font-weight: 400;
  font-family: 'GarnetLight';
  margin-left: 4px;
  margin-right: 4px;

  font-size: 60px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`
const ImagenBoton = styled.img`
  margin: 0;
  width: 100%;
  height: 400px;
  will-change: transform;

  @media (max-width: 768px) {
    height: 200px;
  }
`
const Capa = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;


  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > svg {
    margin-bottom: 10px;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`


const LogoImage = styled.img`
  cursor: pointer;

  width: 70px; 
  height: auto;
`;



const TituloYBoton = styled.div`
  display: flex;
  flex-direction: column; /* Los elementos se alinean en columna por defecto */
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
`;


const BotonInscripcion = styled.button`
  font-family: 'GarnetLight';
  margin: 5px; /* Ajusta los márgenes para que se vean bien cuando se apilen */
  background-color: #a0f000cf;
  color: #000000;
  border: black solid 1px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;

  &:hover {
    color: white;
    background-color: #000000; /* Un tono más oscuro para el efecto hover */
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 12px;
    margin: 5px ;
  }
`;

const BotonesContenedor = styled.div`
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */
  flex-wrap: wrap; /* Permite que los botones se envuelvan si no hay suficiente espacio */


  @media (max-width: 768px) {
    flex-direction: row; /* Asegura que los botones estén en horizontal en pantallas pequeñas */
  }
`;



const Card = () => {
  // Intenta recuperar el estado inicial de 'desplegableAbierto' desde localStorage
  const [desplegableAbierto, setDesplegableAbierto] = useState(() => {
    return localStorage.getItem('desplegableAbierto') || null;
  });

  // Efecto para actualizar localStorage cuando desplegableAbierto cambie
  useEffect(() => {
    localStorage.setItem('desplegableAbierto', desplegableAbierto);
  }, [desplegableAbierto]);


  const toggleDesplegable = (deporte) => {
    const nuevoEstado = desplegableAbierto === deporte ? null : deporte;
    setDesplegableAbierto(nuevoEstado);
    // No es necesario actualizar el localStorage aquí ya que useEffect se encargará
  };


  return (
    <>
      <ContenedorPrincipal>


        <ContenedorApartado>


          <CardsSelecion
            style={{
              backgroundImage: "url('/tenis1.jpg')",
              ...(desplegableAbierto === 'tenis' ? { filter: 'grayscale(0%)', border: '3px solid #a0f000cf' } : { filter: 'grayscale(100%)' })
            }}
            onClick={() => toggleDesplegable('tenis')}
          // No pasamos isActive directamente al DOM aquí
          >


            <ImagenBoton />
            <Capa>
              <TituloImagenes>TENIS</TituloImagenes>
            </Capa>
          </CardsSelecion>


          <CardsSelecion
            style={{
              backgroundImage: "url('/padel1.jpg')",
              ...(desplegableAbierto === 'padel' ? { filter: 'grayscale(0%)', border: '3px solid #a0f000cf' } : { filter: 'grayscale(100%)' })
            }}
            onClick={() => toggleDesplegable('padel')}
          // Asegúrate de no pasar isActive como una prop aquí
          >

            <ImagenBoton />
            <Capa>
              <TituloImagenes>PADEL</TituloImagenes>
            </Capa>
          </CardsSelecion>

        </ContenedorApartado>













        {/* DESPLEGABLE TENIS!!!!!!!!!!!!!!!!!!!!!!!!*/}
        {desplegableAbierto === 'tenis' && (
          <div>



<TituloYBoton>
  <TituloCabeza>ESCUELA DE TENIS</TituloCabeza>
  <BotonesContenedor>
  <BotonInscripcion onClick={() => window.location.href='/clubs/bellavista/informacioneinscripcionbhc'}>Información e inscripción Temporada 24 - 25</BotonInscripcion>

  </BotonesContenedor>
</TituloYBoton>

            <ContenedorApartado>

              <Cards >
                <Link to="/clubs/bellavista/escueladeformación">
                  <ContenedorBoton />
                  <Capa>
                    <img src={`${process.env.PUBLIC_URL}/pista.png`} alt="Logo" width="70" />
                    <Titulo>escuela de formación</Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/escueladeadultos">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faUsers}
                      size="2x"
                      color="white"
                    />
                    <Titulo>escuela de adultos</Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/clasesparticulares">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      size="2x"
                      color="white"
                    />
                    <Titulo>clases particulares</Titulo>
                  </Capa>
                </Link>
              </Cards>

              <Cards >
                <Link to="/clubs/bellavista/planificaciondeentrenamiento">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faChalkboard}
                      size="2x"
                      color="white"
                    />
                    <Titulo>planifación de entrenamientos NSTA escuela de formación</Titulo>
                  </Capa>
                </Link>
              </Cards>



            </ContenedorApartado>








            <TituloCabeza>ACADEMIA COMPETICIÓN</TituloCabeza>

            <ContenedorApartado>

              <Cards >
                <Link to="/clubs/bellavista/academianstadecompeticion">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faMedal}
                      size="2x"
                      color="white"
                    />
                    <Titulo>academia nsta competición</Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/planificaciondeentrenamientocompeticion">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faTrophy}
                      size="2x"
                      color="white"
                    />
                    <Titulo>PLANIFICACION DE ENTRENAMIENTOS NSTA COMPETICIÓN</Titulo>
                  </Capa>
                </Link>
              </Cards>




              <Cards >
                <Link to="/clubs/bellavista/programanstatour">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      size="2x"
                      color="white"
                    />
                    <Titulo>programa nsta tour (dosier)</Titulo>
                  </Capa>
                </Link>
              </Cards>




              <Cards >
                <Link to="/clubs/bellavista/serviciosdecoachatorneos">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faUserFriends}
                      size="2x"
                      color="white"
                    />
                    <Titulo>Servicios de coach a torneos</Titulo>
                  </Capa>
                </Link>
              </Cards>


            </ContenedorApartado>




            <TituloCabeza>INTENSIVOS DE TENIS</TituloCabeza>


            <ContenedorApartado>

              <Cards >
                <Link to="/clubs/bellavista/intensivosdeverano">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faSun}
                      size="2x"
                      color="white"
                    />
                    <Titulo>intensivo de verano</Titulo>
                  </Capa>
                </Link>
              </Cards>



            </ContenedorApartado>



            <TituloCabeza>EVENTOS</TituloCabeza>


            <ContenedorApartado>

              <Cards >
                <Link to="/clubs/bellavista/eventotenisbhc">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faFlag}
                      size="2x"
                      color="white"
                    />
                    <Titulo>eventos tenis bhc</Titulo>
                  </Capa>
                </Link>
              </Cards>



            </ContenedorApartado>



            <TituloCabeza>OTROS SERVICIOS</TituloCabeza>


            <ContenedorApartado>


              <Cards >
                <Link to="/clubs/bellavista/calendariodecompeticion">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      size="2x"
                      color="white"
                    />
                    <Titulo>CALENDARIO DE COMPETICIÓN</Titulo>
                  </Capa>
                </Link>
              </Cards>

              <Cards >
                <Link to="/clubs/bellavista/planificaciondetodoslosniveles">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faList}
                      size="2x"
                      color="white"
                    />
                    <Titulo>PALINIFACION DE TODOS LOS NIVELES DE ESCUELA DE FORMACIÓN </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/planificacionindividualizada">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faUser}
                      size="2x"
                      color="white"
                    />
                    <Titulo>PALINIFACIÓN INDIVIDUALIZADA </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/estadisticasyvideoanalisis">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faChartBar}
                      size="2x"
                      color="white"
                    />
                    <Titulo>ESTADISTICAS Y VÍDEO ANÁLISIS </Titulo>
                  </Capa>
                </Link>
              </Cards>



              <Cards >
                <Link to="/clubs/bellavista/videotutoriales">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      size="2x"
                      color="white"
                    />
                    <Titulo>VIDEO TUTORIALES NSTA </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/preparacionfisica">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faDumbbell}
                      size="2x"
                      color="white"
                    />
                    <Titulo>PREPARACIÓN FISICA </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/serviciosdeencordados">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faWrench}
                      size="2x"
                      color="white"
                    />
                    <Titulo>SERVICIOS DE ENCORDADOS DE RAQUETAS </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards >
                <Link to="/clubs/bellavista/cumnpleañosdeportivos">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faBirthdayCake}
                      size="2x"
                      color="white"
                    />
                    <Titulo>CUMPLEAÑOS DEPORTIVOS </Titulo>
                  </Capa>
                </Link>
              </Cards>


              <Cards color="#9b59b6">
                <Link to="/clubs/globalcollage">
                  <ContenedorBoton />
                  <Capa>
                    <LogoImage src={`${process.env.PUBLIC_URL}/globalcollage.png`} alt="Global Collage Logo" />
                    <Titulo>BECAS USA</Titulo>
                  </Capa>
                </Link>
              </Cards>



            </ContenedorApartado>







          </div>
        )}













        {/* DESPLEGABLE PADEL!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
        {desplegableAbierto === 'padel' && (
          <div>



            <TituloCabeza>ESCUELA DE PADEL</TituloCabeza>



            <ContenedorApartado>

              <Cards >
                <Link to="/clubs/bellavista/escueladepadelbhc">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      size="2x"
                      color="white"
                    />
                    <Titulo>INFORMACIÓN ESCUELA PADEL BHC</Titulo>
                  </Capa>
                </Link>
              </Cards>



              <Cards >
                <Link to="/clubs/bellavista/intensivospadel">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faBolt}
                      size="2x"
                      color="white"
                    />
                    <Titulo>INTENSIVOS PADEL</Titulo>
                  </Capa>
                </Link>
              </Cards>



              <Cards >
                <Link to="/clubs/bellavista/eventospadelbhcs">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      size="2x"
                      color="white"
                    />
                    <Titulo>EVENTOS PADEL BHC</Titulo>
                  </Capa>
                </Link>
              </Cards>



              <Cards >
                <Link to="/clubs/bellavista/otrosservicios">
                  <ContenedorBoton />
                  <Capa>
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      size="2x"
                      color="white"
                    />
                    <Titulo>OTROS SERVICIOS</Titulo>
                  </Capa>
                </Link>
              </Cards>





            </ContenedorApartado>



          </div>
        )}












        <TituloCabeza>+ SERVICIOS CLUB</TituloCabeza>


        <ContenedorApartado>

          <Cards >
            <Link to="/clubs/bellavista/campusdeverano">
              <ContenedorBoton />
              <Capa>
                <FontAwesomeIcon
                  icon={faCampground}
                  size="2x"
                  color="white"
                />
                <Titulo>campus de verano</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards >
            <Link to="/clubs/bellavista/alquilerdepistas">
              <ContenedorBoton />
              <Capa>
                <FontAwesomeIcon icon={faCalendarAlt} size="2x" color="white" />
                <Titulo>alquiler de pistas</Titulo>
              </Capa>
            </Link>
          </Cards>

          <Cards >
            <Link to="/clubs/bellavista/kioskobar">
              <ContenedorBoton />
              <Capa>
                <FontAwesomeIcon
                  icon={faCoffee}
                  size="2x"
                  color="white"
                />
                <Titulo>kiosko bar</Titulo>
              </Capa>
            </Link>
          </Cards>

        </ContenedorApartado>









        <TituloCabeza>CONTACTO E INSTALACIONES CLUB </TituloCabeza>
        <ContenedorApartado>

          <Cards >
            <Link to="/clubs/bellavista/instalaciones">
              <ContenedorBoton />
              <Capa>
                <FontAwesomeIcon icon={faBuilding} size="2x" color="white" />
                <Titulo>
                  INSTALACIONES CLUB
                </Titulo>
              </Capa>
            </Link>
          </Cards>



        </ContenedorApartado>















      </ContenedorPrincipal>
    </>
  )
}

export default Card
