import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto} from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faWrench } from '@fortawesome/free-solid-svg-icons';   

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faWrench}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>SERVICIOS DE ENCORDADOS DE RAQUETAS  </Titulo>

         
          <Lista>
            <ElementoLista>Ofrecemos un servicio especializado de encordado de raquetas, garantizando una entrega rápida en un plazo de 24 a 48 horas.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista><strong>Con tu propio cordaje:</strong> Solo pagarás 8€ por la mano de obra.
            </ElementoLista>
          </Lista>

          <Lista>
            <ElementoLista><strong>Sin cordaje propio:</strong> Te ofrecemos nuestro servicio completo de encordado, con precios que comienzan desde los 13€.
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista><strong>No dudes en contactarnos para más información o para solicitar nuestros servicios. Estamos aquí para ayudarte a que tu raqueta esté en las mejores condiciones.</strong>
            </ElementoLista>
          </Lista>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
