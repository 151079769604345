import React from 'react'
import styled, { keyframes } from 'styled-components'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, TituloGrande, ContainerTexto } from '../styletexto'
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import NormativaGeneral from '../../../components/RioGrande/NormativaGeneralNSTA&RG/normativageneral'
import InformacionGeneral from '../../../components/RioGrande/InformacionGeneralNSTA&RG/informaciongeneral'

import { faSun } from '@fortawesome/free-solid-svg-icons'

const ContainerImagenes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
`

const Imagen = styled.img`
  width: 90%;
  max-width: 900px;
  height: auto;
  margin: 10px;
`

const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`

const Boton = styled.a`
  font-size: 15px;
  width: 90%;
  font-family: 'Kobern';
  display: inline-block;
  margin-bottom: 16px;
  padding: 15px 20px;
  background-color: #a0f000;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const IntensivoDeNavidad = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faSun} />

      <ContainerGeneral>
        <ContainerTexto>



          <TituloGrande>
            Información e inscripción Temporada 24 - 25{' '}
          </TituloGrande>

          <ContainerImagenes>
            <Imagen
              src={require('./Cartel NSTA&RG - Temporada 2024 - 2025.jpg')}
              alt="Cartel NSTA&RG - Temporada 2024 - 2025"
            />
          </ContainerImagenes>

          <ButtonContainer>
            <Boton
              href="https://docs.google.com/forms/d/1EXpfwTELVT2C5_4nl-MV0PMlyesuKXFvZtp96MEbAuM"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSCRIPCIÓN
            </Boton>
          </ButtonContainer>

          <InformacionGeneral />
          <NormativaGeneral />


          <ContainerImagenes>
            <Imagen
              src={require('./Tarifa de precios Rio Grande.jpg')}
              alt="Tarifa de precios Rio Grande Temporada 2024 - 2025"
            />
          </ContainerImagenes>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default IntensivoDeNavidad
