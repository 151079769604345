import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ImagenResponsiva, ImagenContainer, ContainerGeneral, Descripcion, Titulo, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faChalkboard } from '@fortawesome/free-solid-svg-icons';


const MensajeExclusivo = styled.div`
 margin: 40px auto;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;
  top: 820px;
position: absolute;
  text-align: center;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    padding: 15px;
    top: 830px;
    max-width: 300px;
  }
`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000 !important; /* Color de fondo del botón */
  color: #000000 !important; 
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;

  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  transition: all ease 0.125s;
  margin-top: 8px;
  text-align: center;
  display: block; /* Para que ocupe su propio espacio */
  color: inherit; /* Color del texto */
  text-decoration: none; /* Sin subrayado */

  &:hover {
    background-color: #000000 !important; /* Cambiar el color de fondo al pasar el mouse */
    color: #ffffff !important; /* Cambiar el color del texto al pasar el mouse */
  }
`;


const PlanificacionDeEntrenamiento = () => {



  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faChalkboard} /> 

      <ContainerGeneral>
        <ContainerTexto>
          <Titulo>PLANIFICACIÓN DE ENTRENAMIENTOS NSTA ESCUELA DE FORMACIÓN</Titulo>
          <Descripcion>
            En <strong>NET SPORT ACADEMY</strong>, se trabaja mediante una planificación meticulosamente diseñada, de carácter anual específica y general asociada a cada una de las etapas formativas (Mini Tenis, iniciación, intermedios-perfeccionamiento, avanzados, pre-competición, competición, alta-competición y adultos).

          </Descripcion>



          <Descripcion>
           Esta planificación se basa en objetivos anuales, mensuales, semanales y del día. Además, tenemos unas fichas con todos los fundamentos técnico-tácticos sobre los que trabajar en las diversas categorías a la hora de formar a nuestros jugadores/as.


      
          </Descripcion>

          <ImagenContainer>
            <ImagenResponsiva src="/PDF/planificaciondeentrenamiento.jpg" alt="Planificación de Entrenamientos" />
           
            <MensajeExclusivo>
              <p>Acceso Exclusivo para Socios de la Academia</p>
              <p>¿Interesado en nuestra planificación de entrenamientos? Únete a nuestra comunidad de socios en NET SPORT ACADEMY. ¡Contacta con el equipo para resolver cualquier duda y descubre todo lo que podemos ofrecerte en tu camino hacia la excelencia deportiva!</p>
              <BotonContacto to="/contacto">Contacto</BotonContacto>
            </MensajeExclusivo>
          </ImagenContainer>

        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  )
}

export default PlanificacionDeEntrenamiento;