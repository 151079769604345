import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;

  height: 380px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 360px;
  
  }
`;

export const Titulo = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  z-index: 3;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const Line1 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #000000;
  font-size: 35px;
  margin: 1em 3%;
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 27px;
    margin: 1em 3%;
  }
`;

export const Line2 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #323232;
  font-size: 28px;
  padding: 10px;
  font-family: 'RNSMiles';
  text-align: center;
  line-height: 1;
  margin-top: 5px;
  @media (max-width: 768px) {
    font-size: 19px;
  }
`;

export const EmailIconContainer = styled.div`
 
  background-color: #a0f000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  animation: ${fadeIn} 2s ease-in-out;
  font-size: 24px;
`;

const BannerTeam = () => {
  return (
    <Container>
      <Titulo>
        <Line1>Realización de Vídeos Profesionales</Line1>
        <Line2>
          Se realizan vídeos para jugadores y jugadoras en procesos de becas deportivas, análisis de jugador, vídeos personales, recuerdos, y más.
        </Line2>
        <EmailIconContainer>
          <FontAwesomeIcon icon={faEnvelope} color="#000" />
        </EmailIconContainer>
        <Line2> <strong>Información al siguiente e-mail: netsport@netsportacademy.com</strong></Line2>
      </Titulo>
    </Container>
  );
}

export default memo(BannerTeam);
