import { useEffect, useState, memo } from 'react';
import { auth, firestore } from '../../../firebase/firebase.config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import {
  ContenedorPrincipal,
  ContenedorBotones,
  Boton1, Boton2, Boton3,
  BarraIluminadaNaranja, BarraIluminadaVerde, BarraIluminadaAzul,
  ContenidoVista,
} from './contenidostyle';

import { StyleSheetManager } from 'styled-components';


import VistaNSAPlus from '../VistaNSA/vistansa'
import Documentacion from '../VistaDocumentos/documentacion'
import NSAPLUS from '../VistaNSAPLUS/nsaplus'

import NoVideoIniciacion from '../NoDocumentos/nodocumentos'
import NoNSAPLUS from '../NoNSAPLUS/nonsaplus'


const Contenido = () => {
  const [vistaActual, setVistaActual] = useState(1);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Usuario está autenticado, obtener su UID
        obtenerRolUsuario(user.uid);
      } else {
        // Usuario no está autenticado
      }
    });

    return () => unsubscribe(); // Limpiar el listener cuando el componente se desmonte
  }, []);


  const obtenerRolUsuario = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
    
        localStorage.setItem('rolUsuario', userData.role || 'no socio');
      } else {
        // Manejar el caso en que el documento no exista
      }
    } catch (error) {
      console.error("Error al obtener el rol del usuario:", error);
      // Implementar más manejo de errores según sea necesario
    }
  };

  const cambiarVista = (vista) => {
    setVistaActual(vista);
  };


  const tieneAcceso = (vista) => {
    // Obtiene el rol del usuario desde el localStorage
    const rolUsuario = localStorage.getItem('rolUsuario');
  
    // Si el usuario es admin, tiene acceso a todas las vistas
    if (rolUsuario === 'admin') {
      return true;
    }
  
    // Condiciones específicas para acceso a las vistas 2 y 3
    if (vista === 2) {
      return rolUsuario === 'socio' || rolUsuario === 'plus';
    } else if (vista === 3) {
      return rolUsuario === 'plus';
    }
  
    // Por defecto, todos tienen acceso a la vista 1
    return true;
  };


  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'activa' && prop !== 'ancho'}>
      <ContenedorPrincipal>
        <ContenedorBotones>
          <Boton1 activa={vistaActual === 1 ? 'true' : undefined} onClick={() => cambiarVista(1)}>
            NSA
          </Boton1>
          <Boton2 activa={vistaActual === 2 ? 'true' : undefined} onClick={() => cambiarVista(2)}>
            DOCUMENTOS
          </Boton2>
          <Boton3 activa={vistaActual === 3 ? 'true' : undefined} onClick={() => cambiarVista(3)}>
            SOCIOS NSA PLUS
          </Boton3>

          {vistaActual === 1 && <BarraIluminadaAzul ancho={vistaActual} />}
          {vistaActual === 2 && <BarraIluminadaNaranja ancho={vistaActual} />}
          {vistaActual === 3 && <BarraIluminadaVerde ancho={vistaActual} />}
        </ContenedorBotones>
        <ContenidoVista>
          {vistaActual === 1 && <VistaNSAPlus />}

          {vistaActual === 2 && (tieneAcceso(2) ? <Documentacion /> : <NoVideoIniciacion />)}

          {vistaActual === 3 && (tieneAcceso(3) ? <NSAPLUS /> : <NoNSAPLUS />)}
        </ContenidoVista>
      </ContenedorPrincipal>
    </StyleSheetManager>
  );

};


export default memo(Contenido);