import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import FxView from '../../../components/FxView/fxview'



import PDFCampusDeVerano from '../../../components/Airbus/PDFCampusDeVerano/campusdeverano'




const Padel = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />


      <PDFCampusDeVerano />



      <Footer />
      <></>
    </div>
  )
}

export default Padel
