import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from './stylescontactoinfo.module.css'; // Importa los estilos como un objeto

const ContactoInfo = () => {
  return (
    <div className={styles.containercontactoinfo}> {/* Usa la clase del módulo para el contenedor principal */}
      <div className={styles.compartment}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <div>
          <strong>Dirección</strong>
        </div>
        <div className={styles.letrafina}>Club Río Grande
          Ctra. San Juan Palomares, 9, 41927 Mairena del Aljarafe, Sevilla</div>
      </div>

      <div className={styles.compartment}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faPhone} />
        </div>
        <div>
          <strong>Teléfono</strong>
        </div>
        <div className={styles.letrafina}>(+34) 617397151</div>
      </div>

      <div className={styles.compartment}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <div>
          <strong>Email</strong>
        </div>
        <div className={styles.letrafina}>netsport@netsportacademy.com</div>
      </div>

      <div className={styles.compartment}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faClock} />
        </div>
        <div>
          <strong>Horario</strong>
        </div>
        <div className={styles.letrafina}>9:00h a 22:00h 365 días al año</div>
      </div>
    </div>
  );
};

export default ContactoInfo;