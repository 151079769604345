import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0px auto;
  margin-top: 10px;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 25px;
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
  display: block;

  @media (min-width: 769px) {
    display: none;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 800px;
  border: none;


  @media (max-width: 768px) {
    height: 200px;
  }
`;



const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const ServiciosAcompañamientos = () => {
  return (
    <div>
      <Container>
        <ContainerTexto>
          <NSAPlusTitulo>NORMAS GENERALES
            NSTA
            24 - 25
          </NSAPlusTitulo>
        </ContainerTexto>
        {
          isIOS() ? (
            <PdfLink href="/PDF/Normativa general NSTA&RG.pdf" target="_blank" rel="noopener noreferrer">
              DESCARGAR PDF
            </PdfLink>
          ) : (
            <IframeContainer>
            <iframe
              style={{ width: '100%', height: '100%'}}
              src="/PDF/Normativa general NSTA&RG.pdf"
              title="SERVICIOS DE COACH A TORNEOS"
            ></iframe>
            </IframeContainer>
          )
        }




      </Container>
    </div>
  );
};

export default ServiciosAcompañamientos;