import React from 'react'
import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'

import BannerNSAEvents from '../../components/Events/BannerNSAEvents/bannerraquet'
import FichaEvents from '../../components/Events/FichaEvents/fichaevents'


import Footer from '../../components/Footer/footer'


// Crear una animación de fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const ContainerGeneral = styled.div`
  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;





const VistaEventos = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerGeneral>





      <BannerNSAEvents />
      <FichaEvents />






      </ContainerGeneral>
      <Footer />
      <></>
    </div>
  )
}

export default VistaEventos
