import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Importa getStorage

const firebaseConfig = {
  apiKey: "AIzaSyBIqnpdQQUogcs7nQBUMqn5u2CxzrYwVl8",
  authDomain: "nsta-c8e40.firebaseapp.com",
  projectId: "nsta-c8e40",
  storageBucket: "nsta-c8e40.appspot.com",
  messagingSenderId: "418093628572",
  appId: "1:418093628572:web:8f9386708f85b0f2b04159",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app); // Inicializa Firebase Storage

// Habilitar persistencia de sesión
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log("Persistencia de sesión habilitada con éxito");
  })
  .catch((error) => {
    console.error("Error al habilitar persistencia de sesión:", error);
  });

  export { auth, firestore, storage }; // Exporta storage