import React from 'react'
import './styles.css'

const RedesSociales = () => {
  return (



    
    <footer className="general">
              <ul className="icons">
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/NetSportAcademy/?locale=es_ES"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="instagram"
                    href="https://www.instagram.com/netsport_academy/?hl=es"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/NetSportAcademy"
                  >
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCun8dansLqCp54R59oXjY3Q"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="whatsapp"
                    href="https://api.whatsapp.com/send?phone=34617397151"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
    </footer>


  )
}

export default RedesSociales
