import React, { memo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'; 

import styled, { keyframes } from 'styled-components'

import lolopineda from './lolo.jpg';
import EscritoLolo from './EscritoLolo.jpg';
import Logo from '../../components/LogoLolo/logololo';

import pablo from './pablo.jpg';
import eva from './eva.jpg';
import sarai from './sarai.jpg';
import sergio from './sergio.jpg';
import estani from './estani.jpg';
import sara from './sara.jpg';
import ita from './ita.jpg';
import juan from './juan.jpg';
import david from './david.jpg';
import javier from './javier.jpg';

import team from './team.jpg';


const opacidad = keyframes`
  0% {
    opacity: 0;
 
  }
  100% {
    opacity: 1;
  
  }
`

const opacidadYMovimiento = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1600px; 
  margin: 0 auto; 
  padding-bottom: 100px;
`;

const ContentIzquierda = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const ContentDerecha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;

  @media (max-width: 1200px) {
    flex-direction: column-reverse; 
  }
`

const Image = styled.img`
  animation: ${opacidad} 2s ease-in-out;
  max-width: 30%;
  height: auto;
  border-radius: 3%;
  margin: 30px;
  box-shadow: 0px 4px 10px rgba(126, 126, 126, 0.4); /* Sombra verde suave */
  
  @media (max-width: 768px) {
    max-width: 80%;
    margin: 15px;
  }
`;

const TextContainer = styled.div`
  animation: ${opacidadYMovimiento} 2s ease-in-out;
  text-align: left;
  max-width: 700px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 97%;
  }
`

const LinePuesto = styled.span`
  display: block;
  color: #a0f000;
  font-size: 25px;
  margin-bottom: 10px;
  font-family: 'Kobern';
  line-height: 0.8;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Nombre = styled.span`
  display: block;
  color: #000000;
  font-size: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: 'Kobern';

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Line2 = styled.span`
  display: block;
  color: #323232;
  font-size: 28px;
  font-size: 1.50rem; 
  font-family: 'RNSMiles';
  line-height: 1;
  margin: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`



// IMAGEN CENTRO TEAM
const ContentCentro = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  flex-direction: column; /* Cambiado de column-reverse a column */

  @media (max-width: 1200px) {
    flex-direction: column; /* Asegura que la disposición sea en columna en todos los tamaños */
  }
`
const ImageCentro = styled.img`
  animation: ${opacidad} 2s ease-in-out;
  max-width: 60%; 
  height: auto;
  border-radius: 3%;
  margin: 30px;

  @media (max-width: 768px) {
    max-width: 80%; 
    margin: 15px;
  }
`;


// Estilos adicionales
const DesplegableContainer = styled.div`
  background-color: #ffffff; /* Color de fondo del desplegable */
  border-radius: 8px; /* Bordes redondeados */
  margin-top: 20px; /* Espacio sobre el desplegable */
  padding: 20px; /* Espaciado interno */
  display: none; /* Inicialmente no se muestra */
  transition: all 0.3s ease-in-out; /* Transición suave */


  &.active {
    display: block; /* Se muestra cuando está activo */
  }
`;

const DesplegableTrigger = styled.button`
 animation: ${opacidad} 2s ease-in-out;
  display: block;
  margin: 1px auto;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #8ecf00;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8em;
  font-family: 'Lumier';

`;

const DesplegableContent = styled.div`
  margin-top: 20px; /* Espacio sobre el contenido */
`;

const Datos = styled.img`
  animation: ${opacidad} 2s ease-in-out;
  max-width: 50%; /* Asegura que la imagen sea completamente receptiva */
  height: auto;
  border-radius: 3%;
  display: block; /* Hace que la imagen se comporte como un bloque */
  margin: 30px auto; /* Centra la imagen horizontalmente */
  margin-top:-23px ;

  @media (max-width: 768px) {
    margin-top:-40px ;
    max-width: 95%; /* Puedes ajustar este valor según tus preferencias */
  
  }
`;

const InstagramLinkContainer = styled.div`
  display: flex;
  justify-content: center; // Centra horizontalmente
  align-items: center; // Centra verticalmente

  margin: 0; // Remueve cualquier margen que pueda interferir con el centrado
`;

// Estilo para el enlace de Instagram
const InstagramLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E1306C; // Color de fondo de Instagram
  border-radius: 50%; // Hace el fondo completamente redondo
  width: 40px; // Tamaño del círculo
  height: 40px; // Tamaño del círculo
  color: white; // Color del icono
  text-decoration: none; // Elimina el subrayado del enlace

  // Aumenta el tamaño del icono de Font Awesome dentro del enlace
  .fa-instagram {
    font-size: 30px;
  }

  &:hover {
    background-color: #C13584; // Oscurece el fondo al pasar el mouse
  }
`;


const TeamImagenes = () => {
  const [isDesplegableOpen, setIsDesplegableOpen] = useState(false);

  const toggleDesplegable = () => {
    setIsDesplegableOpen(!isDesplegableOpen);
  };

  return (
    <Container>





      <ContentIzquierda>
        <Image src={lolopineda} alt="Entrenador Lolo Pineda" />
        <TextContainer>
          <LinePuesto>DIRECTOR GENERAL</LinePuesto>
          <LinePuesto>NET SPORT ACADEMY</LinePuesto>

          <Nombre>Lolo Pineda</Nombre>
          <Logo></Logo>
          <Line2>
          Su pasión por la enseñanza, la formación de futuros tenistas y la competición hacen de él un pilar fundamental en la Academia. Lolo combina la excelencia en la dirección y la gestión, con su dilatada experiencia para transmitir, formar y guiar a los jugadores, nadie conoce el camino desde la formación a la alta competición como él.

          </Line2>
        </TextContainer>
      </ContentIzquierda>

      <DesplegableTrigger onClick={toggleDesplegable}>
        Más Información {isDesplegableOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </DesplegableTrigger>
      <DesplegableContainer className={isDesplegableOpen ? 'active' : ''}>
        <DesplegableContent>
          <Datos src={EscritoLolo} alt="EscritoLolo" />
        </DesplegableContent>
      </DesplegableContainer>







      <ContentDerecha>
        <TextContainer>
          <LinePuesto>SUBDIRECTOR
            TÉCNICO</LinePuesto>
          <LinePuesto>DIRECTOR DE
            EVENTOS</LinePuesto>
          <LinePuesto>NET SPORT ACADEMY</LinePuesto>
          <Nombre>Pablo Colantonio</Nombre>
          <Line2>
            Vocación, experiencia, compromiso y mejora constante podrían
            ser sus cartas de presentación. Pablo se especializa en el área de
            competición y el alto rendimiento, la organización de eventos y la
            planificación metodológica de la academia en todas sus áreas.

          </Line2>
        </TextContainer>
        <Image src={pablo} alt="Entrenador Pablo Colantonio" />
      </ContentDerecha>



      <DesplegableTrigger onClick={toggleDesplegable}>
        Más Información {isDesplegableOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </DesplegableTrigger>
      <DesplegableContainer className={isDesplegableOpen ? 'active' : ''}>
        <DesplegableContent>
        <InstagramLinkContainer> 
        <Line2>Instagram </Line2>
    <InstagramLink href="https://www.instagram.com/pablo.colantonio/" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon  icon={faInstagram} />
    </InstagramLink>
  </InstagramLinkContainer>



        </DesplegableContent>
      </DesplegableContainer>






      <ContentIzquierda>
        <Image src={sergio} alt="Monitor Sergio Campos" />
        <TextContainer>

          <LinePuesto>COORDINADOR</LinePuesto>
          <LinePuesto>ESCUELA NET SPORT
            ACADEMY</LinePuesto>
          <Nombre>Sergio Campos</Nombre>
          <Line2>
            Sergio destaca por su versatilidad en métodos de enseñanza, con
            un enfoque en el desarrollo integral, promueve la excelencia tanto
            dentro como fuera de la pista, procurando sacar el máximo
            potencial de los jugadores. Se desenvuelve bien en todas las áreas
            y se especializa en la coordinación de grupos.
          </Line2>
        </TextContainer>
      </ContentIzquierda>





      <ContentDerecha>
        <TextContainer>
          <LinePuesto>COORDINADORA MINITENIS</LinePuesto>
          <LinePuesto>MONITORA DE TENIS
          </LinePuesto>

          <Nombre>Eva Pineda</Nombre>
          <Line2>
            Especialista en el área de Mini Tenis, Eva destaca por sus
            habilidades organizativas y proactividad generando sesiones
            divertidas y motivadoras. Su pasión es sacar sonrisas a los más
            pequeños, promover el espíritu deportivo y el trabajo en equipo
            en cada sesión de entrenamiento.


          </Line2>
        </TextContainer>
        <Image src={eva} alt="Monitora Eva Pineda" />
      </ContentDerecha>





      <ContentIzquierda>
        <Image src={juan} alt="Monitor Juan" />
        <TextContainer>
          <LinePuesto>ENTRENADOR DE TENIS</LinePuesto>
          <Nombre>Juan Diaz</Nombre>
          <Line2>
            Juan se destaca por su enfoque en el perfeccionamiento Técnico-Táctico, sus dilatados conocimientos y métodos, hacen que cada
            sesión sea un desafío para sus jugadores.          </Line2>
        </TextContainer>
      </ContentIzquierda>




      <ContentDerecha>
        <TextContainer>
          <LinePuesto>ENTRENADORA DE
            TENIS
          </LinePuesto>


          <Nombre>Sara Cabeza</Nombre>
          <Line2>
          Entrenadora de tenis apasionada, Sara guía con éxito y carisma a sus
            alumnos a través de un aprendizaje integral y divertido. Su notable
            bagaje de conocimientos y sus diversos métodos de enseñanza le
            permiten desenvolverse en todos los niveles y edades.



          </Line2>
        </TextContainer>
        <Image src={sara} alt="Monitora Sara" />
      </ContentDerecha>







      <ContentIzquierda>
        <Image src={estani} alt="Monitor Tenis" />
        <TextContainer>
          <LinePuesto>ENTRENADOR DE TENIS</LinePuesto>
          <Nombre>Estani Perez</Nombre>
          <Line2>
          Entrenador de tenis dedicado a mejorar las habilidades
globales de sus jugadores. Con un enfoque práctico y
personalizado, Estani se asegura de que cada alumno
evolucione positivamente en todas las áreas de su juego.        </Line2>
        </TextContainer>
      </ContentIzquierda>




      <ContentDerecha>
        <TextContainer>
          <LinePuesto>ENTRENADORA DE TENIS</LinePuesto>
          <Nombre>Ita Gomez </Nombre>
          <Line2>
            Su notable experiencia en el ámbito competitivo y su amor
            por la enseñanza, hacen de Ita el combo perfecto para
            inspirar a sus alumnos a alcanzar su máximo potencial.   </Line2>
        </TextContainer>
        <Image src={ita} alt="Monitora de Tenis" />
      </ContentDerecha>













      <ContentIzquierda>
        <Image src={sarai} alt="Monitora Sarai" />
        <TextContainer>
          <LinePuesto>MONITORA DE
            TENIS
          </LinePuesto>
          <Nombre>Sarai Quiros</Nombre>
          <Line2>
            Especializada en Mini Tenis, Sarai combina técnicas
            divertidas y educativas para introducir a los niños al tenis.
            Se enfoca en el desarrollo de habilidades motrices básicas,
            promoviendo el juego en equipo y el amor por el deporte
            desde pequeños.       </Line2>
        </TextContainer>
      </ContentIzquierda>







      <ContentDerecha>
    
        <TextContainer>
          <LinePuesto>ENTRENADOR DE
            TENIS</LinePuesto>
          <LinePuesto>SPARRING
            PROFESIONAL
          </LinePuesto>
          <Nombre>Javier Ladron </Nombre>
          <Line2>
            Javier es un jugador profesional de alto nivel por lo que el alto
            rendimiento es su punto fuerte. Su disciplina, carácter y el
            ritmo y la exigencia que le imprime a cada sesión, hace que
            cada uno de sus jugadores saque el 100% en cada
            entrenamiento.   </Line2>
        </TextContainer>
        <Image src={javier} alt="Javier Ladron" />
      </ContentDerecha>


    









      <ContentIzquierda>
      <Image src={david} alt="David Morgaz" />
        <TextContainer>
          <LinePuesto>ENTRENADOR DE
TENIS
          </LinePuesto>
          <Nombre>David Morgaz</Nombre>
          <Line2>
          Profesionalidad, seriedad e innovación es lo que transmite David
en cada sesión de entrenamiento. Su pasión por el tenis y el gran
trato personal que le caracteriza, logran dejar huella en todos sus
jugadores.    </Line2>
        </TextContainer>
      </ContentIzquierda>










      <ContentCentro>
        <ImageCentro src={team} alt="Team" />
        <TextContainer>
          <LinePuesto>STAFF TECNICO NET SPORT TENNIS ACADEMY <br></br>CLUB RIO GRANDE</LinePuesto>
        </TextContainer>
      </ContentCentro>


    </Container>
  );
}

export default memo(TeamImagenes);
