import React, { memo, useState, useEffect } from 'react'
import { Container, Titulo, Line1, Line2, AnimatedText } from './valoresstyle'

const BannerTeam = () => {
  const [words, ] = useState([
    'EDUCACIÓN',
    'ESFUERZO',
    'SOLIDARIDAD',
    'DISCIPLINA',
  ])
  const [currentWord, setCurrentWord] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prevWord) => (prevWord + 1) % words.length)
    }, 3500)

    return () => clearInterval(interval)
  }, [words])

  return (
    <Container>
      <Titulo>
        <Line1>Profesionalidad, compromiso y especialización</Line1>
        <Line2>
          Net Sport Academy es usted, son ellos y también nosotros. Porque para
          este grupo humano, dedicada a la gestión y dirección deportiva, la
          satisfacción de nuestros jugadores conforma el pilar fundamental de la filosofía
          bajo la que trabaja. Sobre una base multidisciplinar que permite
          trabajar desde los diferentes ámbitos deportivos, nuestro equipo está
          especializado en escuelas de tenis y pádel en cualquier tipo de
          instalaciones. La profesionalidad, la confianza y la pasión por lo que
          hacemos convertidas en un servicio completo y de calidad, con una
          metodología simple y clara, es la base del éxito de Net Sport Academy.
        </Line2>
        <AnimatedText>{words[currentWord]}</AnimatedText>
      </Titulo>
    </Container>
  )
}

export default memo(BannerTeam)
