import React, { memo } from 'react';
import { Container, Image, Titulo, Line1, LogoContainer } from './bannerairbisstyles'; // Asegúrate de importar 'LogoContainer' si decides agregarlo
import fondo from './bannerairbus.jpg';
import logo from './AIRBUS.png';

const BannerAcademy = () => {
  return (
    <Container>
      <Image src={fondo} alt="imagen fondo" />
      <Titulo>
        <Line1>G.E AIRBUS PADEL & PICKLEBALL CLUB</Line1>
        <LogoContainer>
          <img src={logo} alt="Logo" style={{  height: '140px', margin: '20px' }} />
        </LogoContainer>
      </Titulo>
    </Container>
  );
};

export default memo(BannerAcademy);