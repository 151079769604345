import React from 'react';
import styled, { keyframes } from 'styled-components';
import Menu from '../../../components/Menu/Navbar';
import Header from '../../../components/Header/header';
import Logo from '../../../components/Logo/logo';
import Footer from '../../../components/Footer/footer';
import FxView from '../../../components/FxView/fxview';
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground';
import { faCampground } from '@fortawesome/free-solid-svg-icons';

// Estilos
const Container = styled.div`
  margin: 0px auto;
  margin-top: 50px;
  max-width: 1610px;
  padding: 1px;
  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

const NSAPlusTitulo = styled.h2`
  font-family: 'GarnetLight';
  font-size: 25px;
  color: #a0f000;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px !important;
`;

const PdfLink = styled.a`
  color: #a0f000;
  font-size: 20px;
  text-decoration: underline;
`;

const PdfIframe = styled.iframe`
  width: 100%;
  height: 800px; // Altura predeterminada para dispositivos de escritorio

  @media (max-width: 768px) {
    height: 600px; // Altura más adecuada para dispositivos móviles
  }
`;


const blinkAnimation = keyframes`
  0%, 100% {
    background-color: #000000;
    color: #a0f000;
  }
  50% {
    background-color: #a0f000;
    color: #000000;
  }
`;


const Boton = styled.a`
  font-size: 12px;
  width: 80%;
  font-family: 'GarnetLight';
  display: inline-block;
  margin-bottom: 26px;
  padding: 15px 20px;
  background-color: #a0f000; // Color inicial
  color: #000000; // Color del texto inicial
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  animation: ${blinkAnimation} 2s infinite; // Aplicar la animación aquí

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;


const Imagen = styled.img`
  width: 100%; // Asegura que la imagen sea responsive
  max-width: 60%;
  margin: 50px auto; // Centra la imagen horizontalmente
  display: block; // Evita que la imagen sea inline
  border: 2px solid #e1e1e1; // Un borde sólido y sutil
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Sombra suave para crear profundidad
  border-radius: 5px; // Opcional: bordes redondeados para suavizar los bordes

  @media (max-width: 768px) {
   
    max-width: 90%;
 
 }
  
`;



const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCampground} /> {/* Usar el nuevo icono aquí */}

      <Container>
       
        <ContainerTexto>

       
          <NSAPlusTitulo>CAMPUS DE VERANO</NSAPlusTitulo>

          <Imagen src="/CampusdeVeranoBHC.jpg" alt="AIRBUS Campus de verano 2024" />

          <ButtonContainer>
            <Boton href="https://docs.google.com/forms/d/e/1FAIpQLSeFFdAr_8-H0puR4Q_L_xC9Hc9ISPEf9jorM7Xsd2TgUrQESA/viewform" target="_blank" rel="noopener noreferrer">
             inscripción
            </Boton>
          </ButtonContainer> 


        </ContainerTexto>
        {isIOS() ? (
          // Ofrecemos un enlace de descarga para dispositivos iOS
          <PdfLink href="/PDF/CAMPUS DE VERANO BHC.pdf" target="_blank" rel="noopener noreferrer">
            Descargar PDF
          </PdfLink>
        ) : (
          // Para otros dispositivos, mostramos el PDF en un iframe
          <PdfIframe src="/PDF/CAMPUS DE VERANO BHC.pdf" title="Campus de Verano" />
        )}
      </Container>

      <Footer />
    </div>
  );
};

export default Actividades;
