import React, { memo } from 'react'
import {
  Container,
  ContainerTexto,
  Titulo,
  Descripcion,
} from './bodyriograndestyles'

const BannerAcademy = () => {
  return (
    <Container>
      <ContainerTexto>
        <Titulo>CLUB RIO GRANDE</Titulo>
        <Descripcion>
          Bienvenido a 
          <strong> Club Rio Grande</strong>, el lugar donde la pasión por el tenis encuentra su
          hogar. Sumérgete en nuestra comunidad vibrante y disfruta de una
          Escuela de Tenis que abarca desde principiantes hasta competidores,
          con programas adaptados para todos. Vive la emoción de eventos
          inolvidables, participa en emocionantes torneos y experimenta la
          intensidad de la competición. Más allá de las pistas, te ofrecemos
          servicios personalizados y una experiencia integral. Únete a nosotros
          en Club Rio Grande, donde el deporte y la diversión se encuentran. ¡La
          experiencia Rio Grande te espera!
        </Descripcion>
      </ContainerTexto>
    </Container>
  )
}

export default memo(BannerAcademy)
