import React from 'react';
import styled from 'styled-components';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Descripcion, Titulo, ContainerTexto, TituloSubrayado } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview';
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground';
import { faMedal } from '@fortawesome/free-solid-svg-icons';



const StyledImage = styled.img`
  width: 100%; // Asegura que la imagen sea responsive
  max-width: 900px; // Establece un máximo para evitar que la imagen sea demasiado grande
  margin: 0 auto; // Centra la imagen horizontalmente
  display: block; // Evita que la imagen sea inline
`;

const AcademiaNSTADeCompeticion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faMedal} />

      <ContainerGeneral>
        <ContainerTexto>



          <TituloSubrayado>ACADEMIA NSTA COMPETICIÓN </TituloSubrayado>



          <StyledImage src={`${process.env.PUBLIC_URL}/PIRAMIDE.png`} alt="Pirámide" />
         
         
        
          <Descripcion>
          La Academia Competición de Net Sport Tennis Academy (NSTA Competición) tiene como objetivo introducir a los jugadores/as en el mundo profesional del tenis a través de entrenamientos específicos, entrenando en grupos de máximo 4 jugadores por pista y entrenador/a o la opción de coach privado. El programa detallado de cada entrenamiento está definido, expuesto y presentado a cada jugador/a, así como el objetivo marcado para el calendario de competición definido y consensuado en todo momento con familia y jugador/a. 
          </Descripcion>



        
          <Descripcion>
          Asimismo, esta etapa se subdivide en competición y alta competición. A lo largo de esta fase se busca la consolidación técnico-táctica del jugador con un aumento significativo de la intensidad y la potencia de ejecución. Asimismo, se profundiza en la preparación física y el trabajo psicológico con el objetivo de obtener el máximo rendimiento en las competiciones federadas.          </Descripcion>



          <Titulo>
          COMPETICIÓN

          </Titulo>





          <Descripcion>

            <ul>
              <li>Clases de 2 horas (4 o 5 días a la semana)</li>
              <li>Ampliación del trabajo táctico en 5 zonas de la pista (defensa, control, presión, ataque y definición)</li>
              <li>Desarrollo biomecánico avanzado.</li>
              <li>Desarrollo de juego de pies avanzado de competición.</li>
              <li>Introducción al análisis estadístico del jugador y sus patrones.</li>
              <li>Desarrollo de un patrón de juego adaptado a las necesidades del jugador/a.</li>
              <li>Desarrollo de recursos para contrarrestar patrones de juego del oponent.e</li>
              <li>Simulación de partidos y situaciones de partidos.</li>
              <li>Dominio del reglamento de competición.</li>
              <li>Desarrollo de la capacidad anaerobica.</li>
              <li>Valores del deportista de competición.</li>
              <li>Entrenamiento mental con énfasis en la creación de una atmosfera motivacional enfocada a la tarea y no al resultado.</li>
              <li>Competición federada a nivel nacional e internacional.</li>
              <li>Evaluación continua del progreso de cada jugador/a.</li>
              <li>Orientación sobre una dieta equilibrada y adecuada para maximizar el rendimiento.</li>
              <li>Entrenamiento en pista, sala y gimnasio.</li>
            </ul>

          </Descripcion>

   

       





          <Titulo>
          ALTA COMPETICIÓN

          </Titulo>





          <Descripcion>

            <ul>
              <li>Clases de 2 horas (5 días a la semana) con posibilidad de doblar turno.</li>
              <li>Consolidación técnica general y capacidad de aplicarla bajo presión.</li>
              <li>Dominio del juego de pies avanzado de competición.</li>
              <li>Dominio de recursos para contrarrestar patrones de juego del oponente.</li>
              <li>Consolidación de un patrón de juego adaptado a las necesidades del jugador/a.</li>
              <li>Dominio biomecánico avanzado.</li>
              <li>Rutinas avanzadas de competición</li>
              <li>Dominio del trabajo táctico en 5 zonas de la pista (defensa, control, presión, ataque y definición).</li>
              <li>Desarrollo de la capacidad de autoanálisis técnico y táctico.</li>
              <li>Desarrollo de la capacidad anaeróbica.</li>
              <li>Simulación de partidos y situaciones de partidos.</li>
              <li>Análisis estadístico avanzado.</li>
              <li>Valores del deportista de competición.</li>
              <li>Asimilación y dominio de reglamentación avanzada de competición</li>
              <li>Entrenamiento mental enfocado a competiciones con énfasis en el afrontamiento de la presión y la superación de la adversidad.</li>
              <li>Competición federada a nivel nacional e internacional.</li>
              <li>Evaluación continua del progreso de cada jugador/a.</li>
              <li>Orientación sobre una dieta equilibrada y adecuada para maximizar el rendimiento.</li>
              <li>Entrenamiento en pista, sala y gimnasio.</li>


            </ul>

          </Descripcion>



          <Descripcion>
            <strong>Es importante destacar que este programa de clases es solo un ejemplo global. No obstante, el mismo pueda variar de acuerdo a las especificidades, necesidades y preferencias ya sea a nivel particular o grupal de los jugadores/as así como del cuerpo técnico NSTA.  </strong>
          
          </Descripcion>




        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  );
}

export default AcademiaNSTADeCompeticion;