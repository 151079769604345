import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  margin: 40px auto;
  max-width: 1210px;

  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;
  margin: 20px 0;
`;

// const NSAPlusTitulo = styled.h2`
//  font-family: 'GarnetLight';

//   font-size: 25px; 
//   color: #a0f000;
//   text-align: center;
//   padding-bottom: 10px;
//   padding-top: 20px !important;
// `;



const Documentacion = () => {




  
  return (

      <Container>
        <ContainerTexto>
          {/* <NSAPlusTitulo>SOCIOS DOCUMENTOS</NSAPlusTitulo> */}
        </ContainerTexto>

   
      </Container>

  );
};

export default Documentacion;