import React from 'react'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {
  ContainerGeneral,
  Descripcion,
  TituloGrande,
  TituloSubrayado,
  ContainerImagenes,
  Imagen,
  Titulo
} from '../../RioGrande/styletexto'
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendar } from '@fortawesome/free-solid-svg-icons'

const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCalendar} /> {/* Usar el nuevo icono aquí */}
      <ContainerGeneral>
        <TituloGrande>EVENTOS DE PADEL Y PICKLEBALL </TituloGrande>


        <Descripcion>
          <TituloSubrayado>1 TORNEOS FEDERADOS Y SOCIALES</TituloSubrayado>
          <ContainerImagenes>
            <Imagen src={require('./eventos1.jpg')} alt="kiosko bar" />
          </ContainerImagenes>

          <TituloSubrayado>2 QUEDADAS EN GRUPO</TituloSubrayado>
          <ContainerImagenes>
            <Imagen src={require('./eventos2.jpg')} alt="kiosko bar" />
          </ContainerImagenes>

          <TituloSubrayado>
            3 CUMPLEAÑOS DEPORTIVOS (MENORES Y ADULTOS)
          </TituloSubrayado>
          <ContainerImagenes>
            <Imagen src={require('./eventos3.jpg')} alt="kiosko bar" />
          </ContainerImagenes>
          <TituloSubrayado>4 ORGANIZACIÓN DE "EVENTO EMPRESA"</TituloSubrayado>

          <TituloSubrayado>
            5 ALQUILER DEL ESPACIO CLUB DEPORTIVO PARA EVENTOS PRIVADOS
          </TituloSubrayado>
        </Descripcion>


        <Titulo>DESCUENTOS</Titulo>
        <ContainerImagenes>
          <Imagen
            src={require('./descuento airbus.jpg')}
            alt="descuento airbus"
          />
        </ContainerImagenes>



      </ContainerGeneral>
      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDeFormacion
