import React, { memo } from 'react'
import {
  Container,
  ContainerTexto,
  Titulo,
  Descripcion,
} from './bodyairbusestyles'

const BannerAcademy = () => {
  return (
    <Container>





<ContainerTexto>
  <Titulo>G.E AIRBUS PADEL CLUB</Titulo>
  <Descripcion>
    ¡Bienvenido a G.E Airbus Padel Club, tu destino exclusivo para el pádel! Experimenta la emoción del juego en nuestras instalaciones dedicadas a jugadores de todos los niveles, desde novatos hasta profesionales. Participa en torneos emocionantes, mejora tus habilidades con nuestros programas de entrenamiento y sé parte de nuestra comunidad apasionada. En Airbus Padel Club, vivirás la pasión del pádel en cada golpe. ¡Te esperamos para compartir momentos inolvidables en la pista!
  </Descripcion>
</ContainerTexto>







    </Container>
  )
}

export default memo(BannerAcademy)
