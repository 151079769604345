import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0px auto !important;
  margin-top: 40px !important;
  max-width: 1200px; // Limitar el ancho máximo para escritorio

  text-align: center;
  background-color: #182329;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) { // Media query para tablets y móviles
    margin: 20px;
    padding: 15px;
  }
`;

const ContainerTexto = styled.div`
  text-align: left;
  color: #f5f5f5;

`;


const Descripcion = styled.p`
   font-family: 'RNSMiles';
   font-size: 19px;
  line-height: 1.5;

  color: #e5e5e5;
  margin-bottom: 20px;
`;


// const NSAPlusTitulo = styled.h2`
//   font-family: 'GarnetLight';
//   font-size: 25px; 
//   color: #a0f000; /* Color coherente con el branding de NSA Plus */
//   text-align: center;
//   margin-bottom: 15px;
// `;



const EscuelaDeFormacion = () => {
  return (
    <div>
      <Container>
        <ContainerTexto>
          {/* <NSAPlusTitulo>SOCIOS NSA</NSAPlusTitulo> */}

          <Descripcion>
            
           
          </Descripcion>



         
        </ContainerTexto>
      </Container>
    </div>
  );
};

export default EscuelaDeFormacion;
