import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto, ContainerImagenes, Imagen } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faCoffee} /> {/* Usar el nuevo icono aquí */}



      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>KIOSKO BAR </Titulo>


          <Lista>
            <ElementoLista>
              Descubre nuestro kiosko bar en el club: el punto de encuentro perfecto para refrescarte y relajarte. Con una selección exclusiva de bebidas, nuestro servicio está pensado para complementar tus momentos. ¡Acércate y brinda por los buenos tiempos!
            </ElementoLista>
          </Lista>



          <ContainerImagenes>
            <Imagen src={require('./kiosko bar 1.jpg')} alt="kiosko bar" />
          </ContainerImagenes>


          <ContainerImagenes>
            <Imagen src={require('./kiosko bar 2.jpg')} alt="kiosko bar" />
          </ContainerImagenes>


          <ContainerImagenes>
            <Imagen src={require('./kiosko bar 3.jpg')} alt="kiosko bar" />
          </ContainerImagenes>








        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default Actividades
