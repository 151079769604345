import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, Lista, Titulo, ElementoLista, ContainerTexto, TituloChico } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faUser } from '@fortawesome/free-solid-svg-icons';

const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faUser} /> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
        <ContainerTexto>


          <Titulo>PLANIFICACIÓN INDIVIDUALIZADA</Titulo>

          <Lista>
            <ElementoLista>NSTA presenta el servicio a propuesta de planificación individualizada para mejorar el rendimiento a un jugador/a de tenis de competición. A través de esta propuesta, buscamos optimizar las habilidades técnicas, tácticas y físicas, con la finalidad de alcanzar los objetivos y mejorar los resultados en las competiciones.
            </ElementoLista>
          </Lista>



          <TituloChico><strong> A continuación, presentamos plan de trabajo NSTA:  </strong></TituloChico>
          <br></br>     <br></br>





          <TituloChico><strong> 1. Evaluación inicial:  </strong></TituloChico>
          <Lista>
            <ElementoLista>Realizaremos una evaluación exhaustiva de las habilidades técnicas y físicas actuales. Esto nos permitirá identificar la fortalezas y áreas de mejora, y trazar un plan de entrenamiento acorde a las necesidades específicas del jugador/a.

            </ElementoLista>
          </Lista>





          <TituloChico><strong> 2. Plan de entrenamiento físico:  </strong></TituloChico>
          <Lista>
            <ElementoLista>Trabajaremos en mejorar la resistencia cardiovascular, fuerza, agilidad, velocidad y flexibilidad entre otras. Diseñaremos un programa personalizado que te ayude a desarrollar todas estas cualidades físicas fundamentales para el tenis, todo ello bajo la dirección del preparador físico y en plena coordinación con el coach principal.


            </ElementoLista>
          </Lista>

          <TituloChico><strong> 3. Entrenamiento técnico:  </strong></TituloChico>
          <Lista>
            <ElementoLista>Analizaremos la  técnica de golpeo en cada uno de los golpes del tenis. Trabajaremos en corregir o limpiar cualquier aspecto técnico deficiente y perfeccionar tus movimientos para lograr mayor precisión y potencia en cada uno de ellos.

            </ElementoLista>
          </Lista>




          <TituloChico><strong> 4. Desarrollo de tácticas de juego:  </strong></TituloChico>
          <Lista>
            <ElementoLista> Analizaremos tus partidos a través de nuestros servicios de estadísticas y video análisis y los de tus rivales para identificar patrones de juego y debilidades. Basándonos en esta información, desarrollaremos tácticas que te permitan maximizar tus fortalezas y aprovechar las debilidades de tus oponentes.

            </ElementoLista>
          </Lista>





          <TituloChico><strong> 5. Competición y seguimiento:  </strong></TituloChico>
          <Lista>
            <ElementoLista> Durante el período de implementación del plan de entrenamiento, participarás en diferentes torneos consensuados entre coach principal y jugador/a. Estaremos presentes para evaluar tu desempeño, analizar tus resultados y realizar ajustes en tu planificación general si es necesario.


            </ElementoLista>
          </Lista>





          <TituloChico><strong> 6. Revisión y ajustes periódicos: </strong></TituloChico>
          <Lista>
            <ElementoLista> Mantendremos reuniones regulares para revisar el progreso y ajustar el plan de entrenamiento de acuerdo a las necesidades y metas a corto y largo plazo. Esto nos permitirá asegurar que se está avanzando de manera adecuada y realizar los cambios necesarios para obtener los objetivos marcados.   </ElementoLista>
            <ElementoLista> Estamos comprometidos a proporcionar un servicio de alta calidad y a apoyar en cada fase de desarrollo del jugador/a de tenis de competición. Nuestra meta es ayudarte a alcanzar tu máximo potencial y ser el mejor jugador/a que puedas ser.   </ElementoLista>




          </Lista>




          <Lista>
            <ElementoLista> Al aceptar nuestra propuesta, nos ponemos a trabajar de inmediato en la planificación individualizada del jugador/a. Estamos seguro de que con dedicación y esfuerzo conjunto, se logran grandes resultados.  </ElementoLista>
            <ElementoLista><strong>NSTA Competición</strong>  </ElementoLista>




          </Lista>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
