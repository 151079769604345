import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto, ContainerImagenes, Imagen } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faBuilding } from '@fortawesome/free-solid-svg-icons';

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />

      <FxImagenBackGround icon={faBuilding} /> {/* Usar el nuevo icono aquí */}



      <ContainerGeneral>


      <Titulo>Descubre Nuestras Instalaciones</Titulo>

      <Lista>
  <ElementoLista>
    Nuestro club ofrece instalaciones completas para una experiencia única.
  </ElementoLista>
  <ElementoLista>
    Disfruta de modernas pistas de pádel.
  </ElementoLista>
  <ElementoLista>
    Visita nuestro acogedor kiosko para reponer energías.
  </ElementoLista>
</Lista>


        <ContainerTexto>


     


          <ContainerImagenes>
            <Imagen src={require('./AlquilerPistasDePadel.jpg')} alt="Alquiler Pistas De Padel" />
          </ContainerImagenes>



        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default Actividades
