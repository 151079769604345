import React from 'react'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {
  ContainerGeneral,
  ElementoLista,
  Titulo,
  TituloGrande,
  Lista,
  ContainerTexto,
  ContainerImagenes,
  Imagen,
} from '../../RioGrande/styletexto'
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCalendarAlt} />{' '}
      {/* Usar el nuevo icono aquí */}
      <ContainerGeneral>
        <ContainerTexto>
          <TituloGrande>INTENSIVOS DE VERANO</TituloGrande>
          <ContainerImagenes>
            <Imagen
              src={require('./intensivospadel.jpg')}
              alt="intensivos padel"
            />
          </ContainerImagenes>

          <TituloGrande>Planificación</TituloGrande>

          <Titulo>Iniciación</Titulo>
          <Lista>
            <ElementoLista>
              Incluye vídeos sobre reglas del pádel: servicio y resto, puntuación, campo de juego.
            </ElementoLista>
            <ElementoLista><strong>Día 1</strong></ElementoLista>
            <Lista>
              <ElementoLista>Calentamiento con ejercicios de coordinación y control de pelota.</ElementoLista>
              <ElementoLista>Servicio.</ElementoLista>
              <ElementoLista>Golpes de fondo derecha con direcciones.</ElementoLista>
              <ElementoLista>Salida de pared (derecha)</ElementoLista>
              <ElementoLista>Recurso: contrapared de fondo.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 2</strong></ElementoLista>
            <Lista>
              <ElementoLista>Globo de derecha</ElementoLista>
              <ElementoLista>Golpes de revés de fondo con direcciones</ElementoLista>
              <ElementoLista>Volea derecha con direcciones desde ambos lados</ElementoLista>
              <ElementoLista>Mini partido (peloteo)</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 3</strong></ElementoLista>
            <Lista>
              <ElementoLista>Salida de pared de revés</ElementoLista>
              <ElementoLista>Globo de revés</ElementoLista>
              <ElementoLista>Paredes laterales</ElementoLista>
              <ElementoLista>Bandeja</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 4</strong></ElementoLista>
            <Lista>
              <ElementoLista>Calentamiento fondo de pista.</ElementoLista>
              <ElementoLista>Volea de revés desde ambos lados con direcciones.</ElementoLista>
              <ElementoLista>Bandeja.</ElementoLista>
              <ElementoLista>Remate.</ElementoLista>
              <ElementoLista>Mini partido.</ElementoLista>
            </Lista>
          </Lista>

          <Titulo>Intermedio</Titulo>
          <Lista>
            <ElementoLista>Incluye vídeos explicativos técnico tácticos y sobre materiales de pádel.</ElementoLista>
            <ElementoLista><strong>Día 1</strong></ElementoLista>
            <Lista>
              <ElementoLista>Calentamiento golpes de fondo con direcciones (derecha y revés incluido globo).</ElementoLista>
              <ElementoLista>Defensa pared lateral derecha.</ElementoLista>
              <ElementoLista>Volea de derecha con direcciones desde ambos lados.</ElementoLista>
              <ElementoLista>Bandeja diagonal ambos lados.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 2</strong></ElementoLista>
            <Lista>
              <ElementoLista>Peloteo con objetivos.</ElementoLista>
              <ElementoLista>Defensa pared lateral revés.</ElementoLista>
              <ElementoLista>Recurso: contraparedes laterales.</ElementoLista>
              <ElementoLista>Volea de revés con direcciones desde ambos lados.</ElementoLista>
              <ElementoLista>Bandeja paralela ambos lados.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 3</strong></ElementoLista>
            <Lista>
              <ElementoLista>Fondo - volea, volea - fondo.</ElementoLista>
              <ElementoLista>Bajada de pared (derecha y revés)</ElementoLista>
              <ElementoLista>Bandeja alternando velocidades y direcciones.</ElementoLista>
              <ElementoLista>Partido contando errores no forzados y con punto de oro (explicación).</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 4</strong></ElementoLista>
            <Lista>
              <ElementoLista>Voleas de control</ElementoLista>
              <ElementoLista>Bandeja - volea</ElementoLista>
              <ElementoLista>La Víbora</ElementoLista>
              <ElementoLista>Remates</ElementoLista>
            </Lista>
          </Lista>

          <Titulo>Avanzado</Titulo>
          <Lista>
          <ElementoLista>Incluye vídeos explicativos jugadas y posicionamiento en pista.</ElementoLista>
            <ElementoLista><strong>Día 1</strong></ElementoLista>
            <Lista>
              <ElementoLista>Calentamiento fondo de pista tirando chiquitas a la reja.</ElementoLista>
              <ElementoLista>Voleas en movimiento.</ElementoLista>
              <ElementoLista>Bandeja ambos lados con cambio de velocidad y direcciones.</ElementoLista>
              <ElementoLista>Mini partido con condiciones.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 2</strong></ElementoLista>
            <Lista>
              <ElementoLista>Defensa (lateral fondo y fondo lateral)</ElementoLista>
              <ElementoLista>Defensa bote pronto y contraataque.</ElementoLista>
              <ElementoLista>Globos en paralelo con objetivo.</ElementoLista>
              <ElementoLista>Mini partido con condiciones.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 3</strong></ElementoLista>
            <Lista>
              <ElementoLista>Globos al rincón diagonal.</ElementoLista>
              <ElementoLista>Bajadas de pared.</ElementoLista>
              <ElementoLista>Volea-bandeja.</ElementoLista>
              <ElementoLista>Recurso: la dejada.</ElementoLista>
              <ElementoLista>Víbora.</ElementoLista>
            </Lista>
            <ElementoLista><strong>Día 4</strong></ElementoLista>
            <Lista>
              <ElementoLista>Salida de pared con chiquita y transición a volea.</ElementoLista>
              <ElementoLista>El rulo.</ElementoLista>
              <ElementoLista>Remates.</ElementoLista>
              <ElementoLista>Ejercicio defensa vs ataque por equipos.</ElementoLista>
            </Lista>
          </Lista>
        </ContainerTexto>
      </ContainerGeneral>
      <Footer />
    </div>
  )
}

export default Actividades
