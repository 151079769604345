import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import { ImagenResponsiva, ImagenContainer, ContainerGeneral, Lista, Titulo, ContainerTexto, ElementoLista } from '../styletexto';
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faList } from '@fortawesome/free-solid-svg-icons';   


const MensajeExclusivo = styled.div`
 margin: 0px auto;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;
  top: 490px;
position: absolute;
  text-align: center;
  font-family: 'RNSMiles';
  background-color: #182329dc;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;

  @media (max-width: 768px) {
    padding: 15px;
    top: 620px;
    max-width: 300px;
  }
`;

const BotonContacto = styled(Link)`
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000 !important; /* Color de fondo del botón */
  color: #000000 !important; 
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;

  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  transition: all ease 0.125s;
  margin-top: 8px;
  text-align: center;
  display: block; /* Para que ocupe su propio espacio */
  color: inherit; /* Color del texto */
  text-decoration: none; /* Sin subrayado */

  &:hover {
    background-color: #000000 !important; /* Cambiar el color de fondo al pasar el mouse */
    color: #ffffff !important; /* Cambiar el color del texto al pasar el mouse */
  }
`;




const CircuitoInterno = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView/>

      <FxImagenBackGround icon={faList}/> {/* Usar el nuevo icono aquí */}






      <ContainerGeneral>
      <ContainerTexto>

        
          <Titulo>PLANIFICACIÓN DE TODOS LOS NIVELES DE ESCUELA DE FORMACIÓN </Titulo>

     

          <Lista>
            <ElementoLista>En <strong>NET SPORT ACADEMY</strong> se trabaja mediante una planificación meticulosamente diseñada, de carácter anual específica y general asociada a cada una de las etapas formativas (mini-tenis, iniciación, intermedios-perfeccionamiento, avanzados, pre-competición, competición, alta-competición y adultos).
            </ElementoLista>
          </Lista>


          <Lista>
            <ElementoLista>Esta planificación se basa en objetivos anuales, mensuales, semanales y del día. Además, tenemos unas fichas con todos los fundamentos técnico-tácticos sobre los que trabajar en las diversas categorías a la hora de formar un jugador/a. 
            </ElementoLista>
          </Lista>



          <ImagenContainer>
            <ImagenResponsiva src="/PDF/planificaciondetodoslosnieveles.jpg" alt="Planificación de todos los niveles" />
           
            <MensajeExclusivo>
              <p>Acceso Exclusivo para Socios de la Academia</p>
              <p>¿Interesado en nuestra planificación de todos los niveles de escuela de formación? Únete a nuestra familia NET SPORT ACADEMY. ¡Contacta con el equipo para resolver cualquier duda!</p>
              <BotonContacto to="/contacto">Contacto</BotonContacto>
            </MensajeExclusivo>
          </ImagenContainer>







        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
      <></>
    </div>
  )
}

export default CircuitoInterno
