import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  @media (max-width: 768px) {
  }
`;

// Estilo para el contenedor del widget que añade un margen blanco en la parte inferior
const WidgetContainer = styled.div`
  position: relative;
  height: 360px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 340px;
  }
`;

const Reseñas = () => {
  const [currentWidget, setCurrentWidget] = useState(0);

  useEffect(() => {
    // Función para calcular el widget actual en función del tiempo
    const getWidgetIndex = () => {
      const startDate = new Date('2024-01-01'); // Cambia esta fecha de inicio si es necesario
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - startDate.getTime();
      const daysElapsed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      // Cambia cada 10 días
      return Math.floor(daysElapsed / 10) % 3; // Solo hay 3 widgets
    };

    // Configura el widget actual
    setCurrentWidget(getWidgetIndex());

    // Recalcula el widget cada 10 días (cada 86400000 ms es un día)
    const intervalId = setInterval(() => {
      setCurrentWidget(getWidgetIndex());
    }, 86400000); // Se recalcula cada 24 horas

    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Crea el script de Elfsight
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.setAttribute('data-use-service-core', '');
    script.defer = true;

    // Añade el script al body del documento
    document.body.appendChild(script);

    // Limpieza al desmontar el componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const renderWidget = () => {
    if (currentWidget === 0) {
      return <div className="elfsight-app-f5389df8-a2a8-4546-8045-a2b75af6cccb" data-elfsight-app-lazy></div>;
    } else if (currentWidget === 1) {
      return <div className="elfsight-app-7e044b28-814d-4d7f-b749-bb027dbd8d62" data-elfsight-app-lazy></div>;
    } else {
      return <div className="elfsight-app-1b2bc190-19ed-497b-8282-aade676a2fe0" data-elfsight-app-lazy></div>;
    }
  };

  return (
    <Container>
      <WidgetContainer>
        {renderWidget()}
      </WidgetContainer>
    </Container>
  );
};

export default Reseñas;
