import React from 'react'
import './stylesfooter.css'

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <p className="text-justify">
              {' '}
              ¡Bienvenido a Net Sport Academy! Sumérgete en el fascinante mundo del tenis y pádel con nosotros. Organizamos emocionantes torneos y ofrecemos clases con entrenadores experimentados. Con una destacada trayectoria, estamos comprometidos a inspirar y mejorar tu juego. Únete a Net Sport Academy, donde cada raqueta cuenta en tu camino hacia el éxito en la pista. ¡Juntos, llevamos el deporte a nuevas alturas!
            </p>
          </div>

          <div className="col-xs-12 col-md-3">
            <h6>
              <i className="fas fa-phone"></i> (+34) 617 39 71 51
            </h6>
            <ul className="footer-links">
              <li>
                <i className="fas fa-envelope"></i> netsport@netsportacademy.com
              </li>
            </ul>

            <div>
              <ul className="social-icons">
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/NetSportAcademy/?locale=es_ES"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="instagram"
                    href="https://www.instagram.com/netsport_academy/?hl=es"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/NetSportAcademy"
                  >
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCun8dansLqCp54R59oXjY3Q"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="whatsapp"
                    href="https://api.whatsapp.com/send?phone=34617397151"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <div className="container">
        <div className="row">
          <div>
            <p className="copyright-text">
              COPYRIGHT &copy; 2024 All Rights Reserved by Net Sport Tennis
              Academy
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
