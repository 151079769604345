import React from 'react';
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'

import FxView from '../../../components/FxView/fxview'
import  Patrocinadores from '../../../components/RioGrande/PatrocinadoresPDF/patrocinadorespdf'




const EscuelaDeFormacion = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <Patrocinadores />




      <Footer />
      <></>
    </div>
  )
}

export default EscuelaDeFormacion
