import React, { useState, useEffect } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../firebase/auth';
import { auth } from '../../../firebase/firebase.config';
import { onAuthStateChanged } from 'firebase/auth';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import './estilologin.scss'


const Modal = ({ message, isError, onClose }) => {
  const icon = isError ? faExclamationCircle : faCheckCircle
  const iconColor = isError ? '#ff8c00' : '#a0f000'

  useEffect(() => {
    if (onClose) {
      const timer = setTimeout(() => {
        onClose()
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [onClose])

  return (
    <div className={`modallogin ${message ? 'visible' : ''}`}>
      <div className="modal-content">
        <div className="icon" style={{ backgroundColor: iconColor }}>
          <FontAwesomeIcon icon={icon} size="3x" color="#ffffff" />
        </div>
        <p>{message}</p>
      </div>
    </div>
  )
}

const FormularioAuth = () => {
  const { register, login, isEmailRegistered, user } = useAuth()
  const navigate = useNavigate();

  // estado en tu componente para manejar la visibilidad de la contraseña.
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);



  // Estado para la Altura del Contenedor: Primero, debes agregar un estado en tu componente FormularioAuth que maneje la altura del contenedor.
  const [containerHeight, setContainerHeight] = useState(335); // Reemplaza 'tamañoInicial' por el valor inicial que deseas para la altura.

  const handleForgotPasswordClick = () => {
    setShowResetPassword(true);
    setContainerHeight(420);
  };


  // Estado para manejar el correo electrónico para la recuperación de contraseña
  const [resetEmail, setResetEmail] = useState('');
  // estado para manejar la visibilidad del formulario de recuperación de contraseña:
  const [showResetPassword, setShowResetPassword] = useState(false);


  // estado para el mensaje del modal de recuperación de contraseña:
  const [modalRecoveryMessage] = useState('');






  // Función para enviar correo de recuperación de contraseña
  const handleSendPasswordResetEmail = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setModalMessage("Correo de recuperación enviado.");
      setModalVisible(true); // Muestra el modal con el mensaje
      setShowResetPassword(false);
      setContainerHeight(320);

    } catch (error) {
      console.error("Error al enviar el correo de recuperación:", error);
      setModalMessage("Correo no valido");

    }
  };






  // Nuevo estado para controlar la redirección automática
  const [shouldRedirect, setShouldRedirect] = useState(true);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && shouldRedirect) {
        // El usuario está autenticado, redirigir a la vista deseada
        navigate('/sociosnsa');
      }
    });

    // Limpieza al desmontar el componente
    return () => unsubscribe();
  }, [user, navigate, shouldRedirect]);


  // Estado para rastrear campos faltantes
  const [missingFields, setMissingFields] = useState('')

  // Estado para mostrar el mensaje de correo electrónico ya registrado
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)

  // Estados para el formulario de registro
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [registerName, setRegisterName] = useState('')

  // Estados para el formulario de inicio de sesión
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')


  // MODAL VERIFICANDO QUE SE A CREADO LA CUENTA
  const [isModalVisible, setModalVisible] = useState(false)
  const [modalMessage, setModalMessage] = useState('')


  //Estado para Mensaje de Error de Contraseña Débil
  const [weakPasswordError, setWeakPasswordError] = useState('');

  const validatePassword = (password) => {


    // Esta regex verifica que la contraseña tenga al menos 6 caracteres y contenga letras y números
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    if (!passwordRegex.test(password)) {
      setWeakPasswordError('La contraseña debe tener al menos 6 caracteres e incluir letras y números.');
      return false;
    }
    setWeakPasswordError('');
    return true;
  };


  // Estado para Mensaje de Error de Correo Electrónico Inválido
  const [invalidEmailError, setInvalidEmailError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setInvalidEmailError('Correo electrónico no es válido');
      return false;
    }

    setInvalidEmailError('');
    return true;
  };


  const resetForm = () => {
    // setRegisterName('')
    // setRegisterEmail('')
    setRegisterPassword('')
    setMissingFields('')
    setEmailAlreadyRegistered(false)
    setLoginEmail('')
    setLoginPassword('')
  }

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    await handleRegister();
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    await handleLogin();
  };


  const handleRegister = async () => {
    try {
      // Validación de campos
      if (!registerName || !registerEmail || !registerPassword) {
        setMissingFields('Faltan campos por rellenar');
        setModalVisible(true);
        return;
      }

      // Validación de longitud del nombre
      if (registerName.length > 30) {
        setMissingFields('Nombre máximo de 30 caracteres');
        setModalVisible(true);
        return;
      }

      // Agregar la validación de correo electrónico aquí
      if (!validateEmail(registerEmail)) {
        setModalVisible(true);
        return;
      }


      if (!validatePassword(registerPassword)) {
        setModalVisible(true);
        return;
      }

      // Validación de longitud de contraseña
      if (registerPassword.length < 6) {
        setMissingFields('Min. 6 caracteres para la contraseña');
        setModalVisible(true);
        return;
      }

      // Verificar si el correo electrónico ya está registrado
      const emailRegistered = await isEmailRegistered(registerEmail)
      if (emailRegistered) {
        setEmailAlreadyRegistered(true)
        setModalVisible(true)
        return
      }


      // Desactiva la redirección automática antes de registrar
      setShouldRedirect(false);

      // Registro exitoso
      await register(registerEmail, registerPassword, registerName);
      setModalMessage('Cuenta creada con éxito')
      setModalVisible(true)



      // Retrasa la redirección hasta después de mostrar el modal
      setTimeout(() => {
        setShouldRedirect(true); // Reactiva la redirección automática
        navigate('/sociosnsa');
      }, 2000);


      // Reinicia los campos de registro después de un registro exitoso

      resetForm(); // Esta función ya debería reiniciar los campos del formulario
      setMissingFields('');
      setEmailAlreadyRegistered(false);
      setWeakPasswordError('');
      setInvalidEmailError('');

    } catch (error) {
      console.error('Error registering:', error.code, error.message);
      setShouldRedirect(true);

      // Reinicia los estados de error antes de establecer un nuevo error
      setWeakPasswordError('');
      setInvalidEmailError('');
      setMissingFields('');


      // Captura específicamente el error de correo electrónico ya registrado
      if (error.code === 'auth/email-already-in-use') {
        setEmailAlreadyRegistered(true)
        setModalVisible(true)
        return
      }
    }
  };


  const handleLogin = async () => {
    try {
      // Validación de campos
      if (!loginEmail || !loginPassword) {
        setMissingFields('Faltan campos por rellenar');
        setModalVisible(true);
        return;
      }

      // Autenticar al usuario
      await login(loginEmail, loginPassword);



      // Resetear el estado y realizar cualquier otra acción necesaria después de iniciar sesión con éxito
      setMissingFields('');

      // Redirige al usuario a la vista deseada después del inicio de sesión exitoso
      navigate('/sociosnsa');

    } catch (error) {
      console.error('Error logging in:', error.code, error.message);
      // Reinicia los estados de error aquí
      setMissingFields('');

      // Check for the specific error related to invalid credentials (user not registered)
      if (error.code === 'auth/invalid-credential') {
        setMissingFields('Correo o contraseña no válidos');
        setModalVisible(true);
      }
    }
  };


  const closeModal = () => {
    setModalVisible(false);
    // Reinicia los estados de error también al cerrar el modal
    setMissingFields('');
    setEmailAlreadyRegistered(false);
    setWeakPasswordError('');
    setInvalidEmailError('');
    resetForm(); // Si también quieres reiniciar el formulario al cerrar el modal
  };


  return (
    <div className="containerlogin">
      <input id="input" className="input" type="checkbox" defaultChecked />

      <label htmlFor="input" className="toggle">
        <span className="textlogin">Registrarse</span>
        <span className="iconlogin">
          <svg
            className="arrow"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
            ></path>
          </svg>
        </span>
        <span className="textlogin">Iniciar sesión</span>
      </label>









      {/* CARDDDDDDDDD!!!!!*/}
      <div className="cardlogin" style={{ height: `${containerHeight}px` }}>







        {/* CONTENEDOR REGISTRARSE!!!!!!!!*/}
        <form onSubmit={handleRegisterSubmit} className="contentlogin sign">
          <h2 className="titlelogin">Registrarse</h2>
          <div className="fields">
            <label className="field">
              <div className="iconlogin">
                <svg
                  stroke="currentColor"
                  color="black"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path>
                  </g>
                </svg>
              </div>
              <input
                type="text"
                id="registerName"
                name="registerName"
                className="name"
                placeholder="Tu Nombre"
                value={registerName}
                onChange={(e) => setRegisterName(e.target.value)}
                autoComplete="name" // Añadido aquí
              />
            </label>


            <label className="field">
              <div className="iconlogin">
                <svg
                  stroke="currentColor"
                  color="black"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="4"></circle>
                  <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                </svg>
              </div>
              <input
                type="email"
                id="registerEmail"
                name="registerEmail"
                className="email"
                placeholder="Tu Correo Electrónico"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)} // Usar setRegisterEmail
                autoComplete="username"
              />
            </label>

            <label className="field">
              <div className="iconlogin">
                {/* Icono del Candado */}
                <svg
                  stroke="currentColor"
                  color="black"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"></path>
                </svg>
              </div>
              <div className="input-container">
                <input
                  type={showRegisterPassword ? "text" : "password"}
                  id="registerPassword"
                  name="registerPassword"
                  className="password"
                  placeholder="Tu Contraseña"
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                  autoComplete="new-password"
                />
                {/* Icono del Ojo */}
                <FontAwesomeIcon
                  icon={showRegisterPassword ? faEyeSlash : faEye}
                  onClick={() => setShowRegisterPassword(!showRegisterPassword)}
                  className="eye-icon" // Clase para estilizar la posición del icono
                />
              </div>
            </label>

          </div>

          <div className="submitlogin">
            <button
            type="submit" className="buttonsubmitlogin2"
             
            >
              Registrarse
            </button>
          </div>
        </form>






        {/* CONTENEDOR INICIAR SESION!!!!!!!!*/}
        <form onSubmit={handleLoginSubmit} className="contentlogin log">
          <h2 className="titlelogin">Iniciar sesión</h2>
          <div className="fields">
            <label className="field">
              <div className="iconlogin">
                <svg
                  stroke="currentColor"
                  color="black"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="4"></circle>
                  <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                </svg>
              </div>
              <input
                type="email"
                id="loginEmail"
                name="loginEmail"
                className="email"
                placeholder="Tu Correo Electrónico"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                autoComplete="username"
              />
            </label>


            <label className="field">
              <div className="iconlogin">
                {/* Icono del Candado */}
                <svg
                  stroke="currentColor"
                  color="black"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="2em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"></path>
                </svg>
              </div>
              <div className="input-container">
                <input
                  type={showLoginPassword ? "text" : "password"}
                  id="loginPassword"
                  name="loginPassword"
                  className="password"
                  placeholder="Tu Contraseña"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  autoComplete="current-password"
                />
                {/* Icono del Ojo */}
                <FontAwesomeIcon
                  icon={showLoginPassword ? faEyeSlash : faEye}
                  onClick={() => setShowLoginPassword(!showLoginPassword)}
                  className="eye-icon"
                />
              </div>
            </label>


            <div className="submitlogin">
              <button
              type="submit"
                className="buttonsubmitlogin"
                onClick={handleLogin}
              >
                Iniciar Sesión
              </button>
            </div>



            {/* Enlace para recuperar contraseña */}
            <div className="forgot-password">
              <button
                type="button"
                className="forgot-password-button"
                onClick={handleForgotPasswordClick}

              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>



            {/* Formulario de recuperación de contraseña */}
            {showResetPassword && (
              <div className="reset-password-form">
                <label className="field">
                  <div className="iconlogin">
                    {/* Icono de Email */}
                    <svg
                      stroke="currentColor"
                      color="black"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                    </svg>
                  </div>
                  <input
                    type="email"
                    placeholder="Tu Correo Electrónico"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    className="email"
                  />
                </label>
                <button
                  type="button"
                  className="forgot-password-button"
                  onClick={() => {
                    setShowResetPassword(true);
                    handleSendPasswordResetEmail(resetEmail);
                  }}
                >
                  Enviar Recuperación al Correo
                </button>
              </div>
            )}




          </div>
        </form>




      </div>

      {isModalVisible && (
        <Modal
          message={modalRecoveryMessage || (emailAlreadyRegistered
            ? 'Correo electrónico ya registrado.'
            : weakPasswordError || invalidEmailError || missingFields || modalMessage)}
          isError={emailAlreadyRegistered || !!weakPasswordError || !!invalidEmailError || !!missingFields}
          onClose={closeModal}
        />



      )}
    </div>
  )
}

export default FormularioAuth
